import {tryCatch} from "@/components/shared/util";
import {isAuthorizedTo} from "@/services/utils/user";
import miscellaneousService from "@/services/miscellaneous.service";
import {common} from "@/settings";

export async function getPing(user, ip) {
  if (isAuthorizedTo(user, "ping")) {
    let result = await tryCatch(() => miscellaneousService.ping(ip));
    if (result.isSuccess) {
      return result.response;
    }
  }
  return null;
}

export async function getNmap(user, ip) {
  if (isAuthorizedTo(user, "nmap")) {
    let result = await tryCatch(() => miscellaneousService.nmap(ip));
    if (result.isSuccess) {
      return result.response;
    }
  }
  return null;
}

export async function getScanAll(user, ip) {
  if (isAuthorizedTo(user, "nmap")) {
    let result = await tryCatch(() => miscellaneousService.scanAll(ip));
    if (result.isSuccess) {
      return result.response;
    }
  }
  return null;
}

export async function getTraceroute(user, ip) {
  if (isAuthorizedTo(user, "nmap")) {
    let result = await tryCatch(() => miscellaneousService.traceroute(ip));
    if (result.isSuccess) {
      return result.response;
    }
  }
  return null;
}

export async function getAbuse(user, ip) {
  if (isAuthorizedTo(user, "nmap")) {
    let result = await tryCatch(() => miscellaneousService.abuse(ip));
    if (result.isSuccess) {
      return result.response;
    }
  }
  return null;
}

export async function delay(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function isObjectID(str) {
  // Regular expression to validate a MongoDB ObjectID
  const objectIdRegex = /^[0-9a-fA-F]{24}$/;

  // Check if the string matches the regular expression
  return objectIdRegex.test(str);
}

export function getDefaultDateRange(numberOfMonthAgo = 2, endingDate = new Date()) {
  return [
    new Date(new Date().getFullYear(), new Date().getMonth() - numberOfMonthAgo, 1),
    endingDate,
  ];
}

// DRP
export function drpInitialization(user) {
  if (user.drp_url) {
    localStorage.setItem("DrpUrl", user.drp_url);
    common.api.site_specific_url = user.drp_url;
    location.reload();
  }
}

export function drpReset() {
  localStorage.removeItem("DrpUrl");
  location.reload();
}

export function isDrpUrlInLocalStorage() {
  let inLocalStorage = false;
  const drpUrl = localStorage.getItem("DrpUrl");
  if (drpUrl) {
    inLocalStorage = true;
  }
  return inLocalStorage;
}

export function truncateThirdDecimal(number) {
  return Math.floor(number / 10) * 10;
}

//
