<template>
  <vue3-simple-typeahead
    id="company-selector"
    ref="typeAhead"
    v-model.trim="search"
    :class="company === null || company === ''
      ? withoutDanger ? `border-primary shadow-sm ${customClass}` : `border-danger shadow-sm ${customClass}`
      : `border-primary shadow-sm ${customClass}`
    "
    :item-projection="(c) => c.name"
    :items="companies"
    :min-input-length="1"
    :title="getTooltipText()"
    :placeholder="$t('company_search.placeholder')"
    spellcheck="false"
    style="position: relative; z-index: 10"
    type="search"
    @onInput="searching($event)"
    @selectItem="selectedCompany"
  />
</template>

<script>
import companyService from "../../services/company.service";
import { tryCatch } from "./util";
import { getSearchParams } from "@/services/utils/searchBuilder";

export default {
  name: "CompanySearchingFilter",
  components: {},
  props: {
    companyOid: {
      type: String,
      default: "",
    },
    companyProps: {
      type: Object,
      default: null,
    },
    customClass: {
      type: String,
      default: "",
    },
    withoutDanger: {
      type: Boolean,
      default: false,
    },
    companyOidToExclude: {
      type: String,
      default: null,
    },
  },
  emits: ["selected"],
  data() {
    return {
      companySearchFocus: false,
      companies: [],
      search: "",
      company: null,
      filters: {
        search_fields: ["name", "oid"],
        sort_field: "name",
        sort: 1,
        search: "",
        limit: 5,
        page: 1,
      },
    };
  },
  async mounted() {
    setTimeout(async () => {
      if (this.companyProps) {
        this.search = this.companyProps.name;
        this.company = this.companyProps;
        this.$refs.typeAhead.input = this.company.name;
      } else if (this.companyOid && this.companyOid !== "") {
        let result = await tryCatch(() =>
          companyService.detail(this.companyOid)
        );
        this.company = result.response;
        this.$refs.typeAhead.input = this.company.name;
      }
      this.$forceUpdate();
    }, 500);

    await this.load();
  },
  methods: {
    searching(e) {
      this.filters.search = e.input;
      if (e.input === "") {
        this.$refs.typeAhead.clearInput();
        this.company = null;
        this.search = "";
        this.$emit("selected", this.company);
      } else {
        this.load();
      }
    },
    async load() {
      let result = await tryCatch(() =>
        companyService.search(getSearchParams(this.filters))
      );
      this.companies = result.response.filter(company => company.oid !== this.companyOidToExclude);
    },
    getTooltipText() {
      if (this.company)
        return this.$tc('company_search.selected', {value: this.company.name});
      else return this.$t('company_search.empty');
    },
    focus() {
      this.$refs.typeAhead.focusInput();
    },
    selectedCompany(event) {
      this.loadDetail(event.oid);
    },
    async loadDetail(oid) {
      if (this.company && this.company.oid === oid) {
        // this.company = null;
        this.$emit("selected", this.company);
        return;
      }
      let result = await tryCatch(() => companyService.detail(oid));
      this.message_error = result.errorMsg;
      this.company = result.response;
      this.search = this.company.name;

      this.$emit("selected", this.company);
    },
  },
};
</script>

<style>
.company-searching-sm {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  padding-left: 8px !important;
}

div#company-selector_wrapper.simple-typeahead {
  z-index:100;
}
</style>
