<template>
  <div>
    <div
      id="goto-ticket"
      @click="gotoTicket"
    />
    <div
      v-if="needUpgrade && ignoreUpgrade !== needUpgrade"
      class="alert alert-dismissible fade show alert-danger topbar text-center"
      role="alert"
    >
      <a style="font-size: larger">{{ $t("menu.upgrade.text") }} <b>{{ needUpgrade }}</b></a>
      <button
        class="ms-2 btn btn-validate-sm btn-danger"
        style="margin-bottom: 2px"
        @click="reload"
      >
        {{ $t("menu.upgrade.button") }}
      </button>
<!--      <button-->
<!--        class="ms-2 btn btn-cancel-sm"-->
<!--        @click="ignoreUpgrade = needUpgrade"-->
<!--      >-->
<!--        {{ $t("menu.upgrade.later") }}-->
<!--      </button>-->
    </div>
    <div
      :class="{ 'bg-danger': (impersonating = isImpersonate()) }"
      class="header"
    >
      <!-- Dark mode -->
      <div class="header-left">
        <button @click="onToggleSidebar">
          <img
            alt="menu"
            src="../assets/img/burger.svg"
          >
        </button>
        <img
          v-if="colorModeBool || impersonating"
          alt="logo"
          class="header-logo-header"
          src="../assets/img/logo-small-cloud-dark-mode.svg"
        >
        <img
          v-if="!colorModeBool && !impersonating"
          alt="logo"
          class="header-logo-header"
          src="../assets/img/logo-small-cloud-light-mode.svg"
        >
      </div>

      <div
        v-if="isTitanSuperAdmin(user) && user.drp_url"
        class="d-flex align-items-center header-right header-ticket"
      >
        <a
          v-if="isDrpUrlInLocalStorage()"
          id="header-drp"
          :class="{ 'btn-white': !isImpersonate(), 'btn-impersonate': isImpersonate() }"
          style="background: none"
          @click.prevent="drpReset()"
        >
          {{ $t('actions.reset-drp') }}
          <i class="ms-2 far fa-clone"/>
        </a>
        <a
          v-else
          id="header-drp"
          :class="{ 'btn-white': !isImpersonate(), 'btn-impersonate': isImpersonate() }"
          style="background: none"
          @click.prevent="drpInitialization(user)"
        >
          {{ $t('actions.init-drp') }}
          <i class="ms-2 fas fa-clone"/>
        </a>
      </div>

      <div
        id="header-dark-mode"
        class="header-right d-none d-md-flex"
      >
        <div
          :class="{ 'theme-dark': colorModeBool }"
          class="d-flex"
        >
          <div class="header-mode">
            {{ $t("menu.dark-mode-header") }}
            <input
              id="switch-dark-mode-header"
              v-model="colorModeBool"
              class="switch"
              type="checkbox"
            >
          </div>
        </div>
      </div>
      <a
        v-if="isDefaultCompanyAdmin(user)"
        id="header-intervention"
        class="btn header-right header-ticket"
        :class="{ 'btn-white': !isImpersonate(), 'btn-impersonate': isImpersonate() }"
        @click="openInterventionModal()"
      >
        {{ $t("menu.intervention") }}
        <i class="ms-2 fas fa-cart-plus"/>
      </a>
      <div
        v-if="(impersonating = isImpersonate())"
        :class="{ impersonating: impersonating }"
        class="header-right header-ticket"
      >
        <div class="header-mode">
          <button
            class="text-white"
            @click="stopImpersonate"
          >
            {{ $t("menu.stop_impersonate") }}
            <i class="fas fa-sign-out-alt"/>
          </button>
        </div>
      </div>

      <a
        id="header-ticket-link"
        class="btn header-right header-ticket d-none d-md-flex"
        :class="{ 'btn-white': !isImpersonate(), 'btn-impersonate': isImpersonate() }"
        @click="openTicketCreation"
      >
        {{ $t("pages.tickets.add-ticket") }}
        <i class="ms-2 fas fa-comments"/>
      </a>

      <!--      {{ orders }}-->
      <router-link
        v-if="isDefaultCompanyAdmin(user)"
        id="order-cart"
        :to="{ name: 'orders-cart' }"
        class="header-right header-cart"
      >
        <div id="order-cart-icon">
          <i class="fas fa-cart-arrow-down fa-2x header-cart-icon"/>
          <div
            v-if="orders"
            class="badge bg-primary badge-header"
          >
            {{ orders.number_of_items }}
          </div>
        </div>
      </router-link>
      <b-popover
        placement="bottom"
        target="header-ticket-link"
        triggers="hover"
      >
        {{ $t('help.tickets-header-link') }}
      </b-popover>
      <!-- Events history -->
      <div
        v-if="events && events.length"
        class="header-right header-event header-bell"
      >
        <div
          :class="{ show: isHistoryMenuOpen }"
          class="overlay"
          @click="onToggleHistoryMenu"
        />
        <div id="btn-history-panel">
          <a
            id="navbarDropdownHistory"
            aria-expanded="false"
            class=""
            data-bs-toggle="dropdown"
            href="#"
            role="button"
          >
            <i
              class="fas fa-history header-bell-icon"
            />
          </a>

          <div
            :class="{ show: isHistoryMenuOpen }"
            class="dropdown-menu dropdown-menu-end scrollable-menu dropdown-menu-notif"
            aria-labelledby="navbarDropdownHistory"
          >
            <div
              v-for="(event, index) in events"
              :key="index"
            >
              <EventItem
                :key="event.date"
                :ctx="this"
                :clickable="true"
                :event="event"
              />
              <div class="dropdown-divider dropdown-divider-sm"/>
            </div>
          </div>
        </div>
        <b-popover
          placement="bottom"
          target="btn-history-panel"
          triggers="hover"
        >
          {{ $t('pages.dashboard.title-history') }}
        </b-popover>
      </div>
      <!-- Notifications -->
      <div
        v-if="notifications && notifications.length"
        class="header-right header-event header-bell"
        @click="onToggleNotificationMenu"
      >
        <div
          class="overlay"
        />
        <div id="btn-notification-panel">
          <a
            id="navbarDropdownNotification"
            aria-expanded="false"
            class=""
            data-bs-toggle="dropdown"
            href="#"
            role="button"
          >
            <i
              id="notificationIcon"
              class="fas fa-bell header-bell-icon"
            />
          </a>

          <div
            class="dropdown-menu dropdown-menu-end scrollable-menu dropdown-menu-notif"
            aria-labelledby="navbarDropdownNotification"
          >
            <div
              v-for="(notification, index) in notifications"
              :key="index"
            >
              <NotificationCard :notification="notification"/>
              <div class="dropdown-divider dropdown-divider-sm"/>
            </div>
          </div>
          <b-popover
            placement="bottom"
            target="btn-notification-panel"
            triggers="hover"
          >
            {{ $t('pages.dashboard.notifications') }}
          </b-popover>
        </div>
      </div>
      <!-- User Menu -->
      <div
        v-if="user"
        id="header-right-user"
        :class="{ impersonating: impersonating }"
        class="header-right header-menu"
        @click="onToggleUserMenu"
      >
        <div
          :class="{ show: isUserMenuOpen }"
          class="overlay"
        />
        <div id="btn-user-panel">
          <a
            id="navbarDropdown"
            aria-expanded="false"
            class=""
            data-bs-toggle="dropdown"
            href="#"
            role="button"
          >
            <Avatar
              :key="common.avatarChanged"
              :data="user.avatar"
              :name="user.firstname + ' ' + user.lastname"
              custom-class="avatar-md"
            />
<!--            <div class="corner-container">-->
<!--              <div-->
<!--                class="corner-ribbon top-right sticky"-->
<!--                style="background-color: orange"-->
<!--              >-->
<!--                V2-->
<!--              </div>-->
<!--            </div>-->
          </a>

          <div
            :class="{ show: isUserMenuOpen }"
            aria-labelledby="navbarDropdown"
            class="dropdown-menu dropdown-menu-end scrollable-menu"
          >
            <h6 class="dropdown-header">
              {{ $t("menu.accounts") }}
            </h6>

            <a
              v-for="user_company_role in user.companies_full"
              :key="user_company_role.oid"
              :class="{
                selected: user_company_role.oid === user.company_oid,
              }"
              class="dropdown-item d-flex align-content-center"
              @click="
                switchCompanyContext(user_company_role.oid);
                changeDefCompany(user_company_role);
              "
            >
              <span class="d-inline-flex">
                <Avatar
                  :data="user_company_role.avatar"
                  :name="user_company_role.name"
                />
                <span class="ms-2">
                  {{ user_company_role.position }} @
                  {{ user_company_role.name }} <br>
                  <small>
                    {{
                      $t("user.roles." + user_company_role.role).includes(
                        "user.roles"
                      )
                        ? user_company_role.role || "-"
                        : $t("user.roles." + user_company_role.role)
                    }}
                  </small>
                </span>
              </span>
            </a>

            <div class="dropdown-divider"/>

            <!--            <h6 class="dropdown-header">-->
            <!--              {{ $t("menu.menu") }}-->
            <!--            </h6>-->

            <router-link
              id="account"
              :to="{
                name: 'account',
                query: {
                  t: 0,
                },
              }"
              class="dropdown-item"
            >
              {{ $t("pages.account.title") }}
            </router-link>

            <router-link
              id="account-ssh"
              :to="{
                name: 'account',
                query: {
                  t: 1,
                },
              }"
              class="dropdown-item"
            >
              {{ $t("pages.account-ssh.title") }}
            </router-link>

            <router-link
              id="account-api"
              :to="{
                name: 'account',
                query: {
                  t: 2,
                },
              }"
              class="dropdown-item"
            >
              {{ $t("pages.account-api.title") }}
            </router-link>

            <router-link
              id="account-2fa"
              :to="{
                name: 'account',
                query: {
                  t: 3,
                },
              }"
              class="dropdown-item"
            >
              {{ $t("pages.account-2fa.title") }}
            </router-link>
            
            <a
              class="dropdown-item"
              @click.prevent="addNewCompany"
            >
              {{ $t("pages.account.create-new-company") }}
            </a>

            <!--            div class="dropdown-divider"></div-->
            <!--            <router-link v-if="!user.default_company.role.accountant" class="dropdown-item" :to="{ name: 'faq' }">-->
            <!--              {{ $t('pages.faq.title') }}-->
            <!--            </router-link>-->

            <!--            <div
              v-if="
                !user.default_company.role.accountant &&
                  !user.default_company.role.manager &&
                  orders
              "
              class="dropdown-divider"
            />-->

            <!--            <router-link
              v-if="
                !user.default_company.role.accountant &&
                  !user.default_company.role.manager &&
                  orders
              "
              id="order-cart"
              :to="{ name: orders ? 'orders-cart' : 'orders-creation' }"
              class="dropdown-item"
            >
              {{ $t("cart.title") }}
              <span
                :class="{
                  'badge-light': !orders.length,
                  'bg-primary': orders.length
                }"
                class="badge"
              >
                {{ orders.size }}
              </span>
            </router-link>-->

            <!--            <div
              v-if="!user.default_company.role.manager"
              class="dropdown-divider"
            />-->

            <div class="dropdown-divider"/>

            <div class="row">
              <div class="col">
                <a
                  id="api-doc"
                  href="/api/v2/doc/new/index.html"
                  style="text-decoration: none"
                  target="_blank"
                >
                  <div class="dropdown-item">
                    <small class="text-dark">
                      <strong>API</strong>
                      {{ apiVersion }}
                    </small>
                  </div>
                </a>
              </div>
              <div class="col">
                <a
                  id="api-changelog"
                  style="text-decoration: none"
                  @click.prevent="showChangelogModal"
                >
                  <div class="dropdown-item">
                    <small class="text-dark">
                      <strong>App</strong>
                      {{ appVersion }}
                    </small>
                  </div>
                </a>
              </div>
            </div>

            <div class="dropdown-divider btn-rounded"/>
            <div class="row">
              <div class="col">
                <a
                  id="global-tour-start"
                  style="text-decoration: none"
                  @click.prevent="launchGlobalTour"
                >
                  <div class="dropdown-item">
                    <small class="text-dark">
                      {{ $t("tour.global.play") }}
                    </small>
                  </div>
                </a>
              </div>
              <div class="col">
                <a
                  id="logout"
                  class="dropdown-item"
                  @click.prevent="onLogout"
                >
                  <small class="text-danger">
                    {{ $t("menu.logout") }}
                  </small>
                </a>
              </div>
            </div>
          </div>

          <ModalInterventions ref="interventionsModal"/>
          <ChangelogModal
            id="changelog-modal"
            ref="modalChangelog"
            :version="appVersion || ''"
            @confirmClose="confirmCloseChangelog"
          />
        </div>
      </div>
      <ModalTicketCreation
        ref="modalTicketCreation"
        :show-buttons="false"
      />
    </div>
    <v-tour
      :callbacks="tourCallbacks"
      :class="{ dark: colorModeBool }"
      :options="tourOptions"
      :steps="getTour()"
      name="globalTour"
    >
      <template #default="tour">
        <transition name="fade">
          <v-step
            v-if="tour.steps[tour.currentStep]"
            :key="tour.currentStep"
            :highlight="tour.highlight"
            :is-first="tour.isFirst"
            :is-last="tour.isLast"
            :labels="tour.labels"
            :next-step="tour.nextStep"
            :previous-step="tour.previousStep"
            :skip="tour.skip"
            :step="tour.steps[tour.currentStep]"
            :stop="tour.stop"
          >
            <template #header>
              <div
                v-if="tour.steps[tour.currentStep].header"
                class="v-step__header"
              >
                <div
                  v-if="tour.steps[tour.currentStep].header.title"
                  v-html="$t(tour.steps[tour.currentStep].header.title)"
                />
              </div>
            </template>

            <template #content>
              <div
                v-if="tour.steps[tour.currentStep].content"
                class="v-step__content"
              >
                <div v-html="$t(tour.steps[tour.currentStep].content)"/>
              </div>
            </template>

            <template #actions>
              <button
                v-if="!tour.isLast"
                class="btn btn-danger"
                @click="tour.skip"
              >
                {{ tourOptions.labels.buttonSkip }}
              </button>

              <button
                v-if="tour.currentStep > 0"
                class="btn btn-dark ms-2"
                @click="tour.previousStep"
              >
                {{ tourOptions.labels.buttonPrevious }}
              </button>

              <button
                v-if="tour.isLast"
                class="btn btn-primary ms-2"
                @click="tour.stop"
              >
                {{ tourOptions.labels.buttonStop }}
              </button>
              <button
                v-if="!tour.isLast"
                class="btn btn-primary ms-2"
                @click="tour.nextStep"
              >
                {{ tourOptions.labels.buttonNext }}
              </button>
            </template>
          </v-step>
        </transition>
      </template>
    </v-tour>

    <ScriptComponent
      :script="`
    <script
      id='scriptReactBot'
      type='text/javascript'
    >
      (function(w, d, s, u) {
      w.RocketChat = function(c) {
      w.RocketChat._.push(c);
      };
      w.RocketChat._ = [];
      w.RocketChat.url = u;
      var h = d.getElementsByTagName(s)[0],
      j = d.createElement(s);
      j.async = true;

      j.src =
      &quot;//rocket.titandc.io/livechat/rocketchat-livechat.min.js?_=201903270000&quot;;
      h.parentNode.insertBefore(j, h);
      })(window, document, &quot;script&quot;, &quot;https://rocket.titandc.io/livechat&quot;);

      RocketChat(function() {
      this.initialize({
      theme: {
      title: 'SmallCloud Support Chat',
      offlineTitle: 'Service is offline',
      },
      guestEmail: '${user.email}',
      guestName: '${user.firstname + ' ' + user.lastname}',
      guestToken: '${user.oid}',
      department: 'dep. Commercial',
      language: 'en',
      });
      });
    </script>
    `"
      class-name="rocketchat-widget"
      script-id="scriptReactBot"
    />
  </div>
  <CompanyCreation
    ref="companyCreation"
    @close="closeNewCompany"
  />
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import Avatar from "./shared/Avatar";
import { settings, common } from "@/settings";
// import {settings} from "@/settings";
import versionService from "../services/version.service";
import authService from "../services/auth.service";
import ChangelogModal from "./all/ChangelogModal";
import adminSteps from "../tour/global/admin.json";
import accountantSteps from "../tour/global/accountant.json";
import managerSteps from "../tour/global/manager.json";
import EventItem from "./all/servers/EventItem";
import ScriptComponent from "./ScriptComponent";
import userService from "../services/user.service";
import {tryCatch} from "./shared/util";
import {
  isDefaultCompanyAdmin,
  isDefaultCompanyManager,
  isDefaultCompanyAccountant,
  isDefaultCompanyCommercial, isTitanSuperAdmin, isTitanAdmin,
} from "@/services/utils/company";
import ModalInterventions from "@/components/all/ModalInterventions";
import {EventSourcePolyfill} from "event-source-polyfill";
import {createToast, withProps} from "mosha-vue-toastify";
// import the styling for the toast
import "mosha-vue-toastify/dist/style.css";
import eventItem from "@/components/all/servers/EventItem";
import {getGraylogColorClass} from "@/services/utils/server.utils";
import companyService from "@/services/company.service";
import {vBPopover, BPopover} from "bootstrap-vue-3";
import ModalTicketCreation from "@/components/shared/ModalTicketCreation.vue";
import notificationService from "@/services/notification.service";
import {getSearchParams} from "@/services/utils/searchBuilder";
import NotificationCard from "@/components/all/dashboard/NotificationCard.vue";
import CompanyCreation from "@/components/all/company/CompanyCreation.vue";
import {drpInitialization, drpReset, isDrpUrlInLocalStorage} from "@/services/utils/miscellaneous";
import {getLastUpdate} from "@/services/utils/ticket";
import ticketService from "@/services/ticket.service";
import TicketAlert from "@/components/all/ticket/ticketAlert.vue";

export default {
  directives: {
    "b-popover": vBPopover
  },
  components: {
    CompanyCreation,
    NotificationCard,
    ModalTicketCreation,
    ModalInterventions,
    ScriptComponent,
    EventItem,
    ChangelogModal,
    Avatar,
    BPopover,
  },

  data() {
    return {
      isTourLaunched: false,
      interval: null,
      ticketInterval: null,
      isUserMenuOpen: false,
      isHistoryMenuOpen: false,
      isNotificationMenuOpen: false,
      apiVersion: 1,
      appVersion: settings.version,
      needUpgrade: false,
      ignoreUpgrade: null,
      impersonating: false,
      isChangelogOpen: false,
      events: [],
      notifications: [],
      tourOptions: {
        useKeyboardNavigation: true,
        highlight: true,
        labels: {
          buttonSkip: this.$t("tour.skip"),
          buttonPrevious: this.$t("tour.previous"),
          buttonNext: this.$t("tour.next"),
          buttonStop: this.$t("tour.end"),
        },
      },
      tourCallbacks: {
        onStop: this.onTourStop,
      },
      eventSource: null,
      client: null,
      changed: false,
      notificationFilters: {
        limit: 10,
        company_oid: "_",
      },
      lastTicketUpdate: null,
      ticket: null,
      ticketFilters: {
        limit: 1,
        page: 1,
        sort_field: "state_date",
        sort: -1,
      },
    };
  },
  computed: {
    common() {
      return common
    },
    ...mapGetters("auth", ["avatar"]),
    ...mapState("auth", ["user", "currentCGV"]),
    ...mapState("cart", ["orders"]),
    ...mapState("kvm", ["serversKvm"]),
    colorModeBool: {
      set(val) {
        if (this.user && this.user.preference) {
          this.user.preference.color_mode = val ? "DARK" : "LIGHT";
          const element = document.getElementsByTagName("body")[0];
          element.classList.toggle("dark", val);
          this.sendUserPref();
        this.emitter.emit("changeColorMode", val);
        }
      },
      get() {
        if (this.user && this.user.preference)
          return this.user.preference.color_mode === "DARK";
        return "LIGHT";
      },
    },
  },
  watch: {
    common(value) {
      this.changed = value.avatarChanged;
    }
  },
  async mounted() {
    const result = await tryCatch(() => versionService.get());
    if (result.isSuccess) this.apiVersion = result.response.version;

    this.user = await authService.loadCompanies(this.user)
    this.user = await authService.setDrp(this.user)
    // this.loadCompanies();
    await this.loadEvent();
    await this.loadNotifications();
    await this.loadSSE();
    this.lastTicketUpdate = await getLastUpdate();

    this.ticketInterval = setInterval(
      this.checkForTicketUpdate,
      process.env.VUE_APP_REFRESH || 30 * 1000
    );

    //Force load immediately
    setTimeout(this.check4Update);
    this.interval = setInterval(
      this.check4Update,
      process.env.VUE_APP_REFRESH_VERSION || 2 * 60 * 1000
    );

    if (this.user) this.getOrders(this.user.oid);
  },

  beforeUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
    if (this.ticketInterval) {
      clearInterval(this.ticketInterval);
      this.ticketInterval = null;
    }
    if (this.eventSource) {
      this.eventSource.close();
    }
    if (this.client) {
      this.client.disconnect();
    }
  },

  methods: {
    isTitanSuperAdmin,
    isTitanAdmin,
    drpReset,
    drpInitialization,
    isDrpUrlInLocalStorage,
    ...mapActions("app", ["toggleSidebar"]),
    ...mapActions("auth", ["logout", "switchCompanyContext", "sendUserPref", "refreshUser"]),
    ...mapActions("cart", ["resetOrders", "resetState", "getOrders"]),
    ...mapActions("kvm", ["removeKvm"]),

    isDefaultCompanyManager,
    isDefaultCompanyAdmin,
    isDefaultCompanyCommercial,
    isDefaultCompanyAccountant,
    async loadEvent() {
      let payload = {
        limit: 10,
      };
      let result = await tryCatch(() =>
        companyService.events(this.user.company_oid, payload)
      );

      this.events = result.response;
    },
    async loadSSE() {
      this.eventSource = new EventSourcePolyfill(
        common.api.site_specific_url + "/sse/company_servers/" + this.user.company_oid,
        {
          headers: authService.getAuthHeader(),
          heartbeatTimeout: 60 * 1000 * 1000 * 1000,
        }
      );
      this.eventSource.addEventListener(
        "company_servers/" + this.user.company_oid,
        (event) => {
          let data = JSON.parse(event.data);
          // console.log(data);

          this.events.pop();
          this.events.unshift(data);
          if (data.status === "request")
            createToast(
              withProps(eventItem, {event: data, ctx: this, withIcon: false}),
              {
                type: "info",
                showIcon: false,
                position: "bottom-right",
              }
            );
          else if (data.status === "success") {
            createToast(
              withProps(eventItem, {event: data, ctx: this, withIcon: false}),
              {
                type: getGraylogColorClass(data.status),
                showIcon: false,
                position: "bottom-right",
              }
            );
          }
        }
      );

      this.eventSource.addEventListener("error", (data) => {
        if (data.status === 504) this.loadSSE();
      });

      this.eventSource.addEventListener("close", (data) => {
        console.error(data);
      });
    },
    async loadNotifications() {
      this.notificationFilters.company_oid = this.user.company_oid;
      let result = await tryCatch(() =>
        notificationService.listNotifications(
          getSearchParams(this.notificationFilters)
        )
      );

      this.notifications = result.response;
    },
    async onTourStop() {
      if (!this.user.preference.tour) this.user.preference.tour = {};
      this.user.preference.tour.global = true;
      this.sendUserPref();
      this.isTourLaunched = false;
    },
    getTour() {
      //return this.$t('tour.global')
      if (isDefaultCompanyAccountant(this.user)) return accountantSteps;
      else if (isDefaultCompanyAdmin(this.user)) return adminSteps;
      else if (isDefaultCompanyManager(this.user)) return managerSteps;
      else return [];
    },
    openInterventionModal() {
      this.$refs.interventionsModal.show();
    },
    async confirmCloseChangelog() {
      this.isChangelogOpen = false;
      this.user.preference.last_dashboard_version = this.appVersion;
      try {
        await userService.updateUserPreferences(this.user.preference);
      } catch (e) {
        console.error(e);
      }
    },
    async showChangelogModal() {
      if (!this.isChangelogOpen) {
        this.isChangelogOpen = true;
        //        this.$bvModal.show("changelog-modal");

        await this.$refs.modalChangelog.showModal();
      }
    },
    launchGlobalTour() {
      this.isTourLaunched = true;
      this.$tours["globalTour"].start();
    },
    async check4Update() {
      var appVersion = "";
      let result = await tryCatch(versionService.getAppVersion);
      if (result.isSuccess) appVersion = result.response;

      // Force to not show changelog to new user
      if (this.user.preference.last_dashboard_version === "")
        this.user.preference.last_dashboard_version = appVersion;

      if (
        (!this.user.preference.tour || !this.user.preference.tour.global) &&
        !this.isTourLaunched &&
        settings.showTour
      )
        this.launchGlobalTour();

      const appVersionStored = this.user.preference.last_dashboard_version;

      // console.log(appVersion);
      // console.log(this.appVersion);
      if (appVersion !== this.appVersion) {
        this.needUpgrade = appVersion;
      } else if (appVersion !== appVersionStored) {
        await this.showChangelogModal();
      }
    },
    onToggleSidebar() {
      this.toggleSidebar();
    },
    onLogout() {
      this.resetState();
      this.closeAllKVM();
      this.logout(this.user.preference);
    },
    async onToggleUserMenu() {
      if (!this.isUserMenuOpen) {
        await this.refreshUser();
        // this.loadCompanies();
      }
      this.isUserMenuOpen = !this.isUserMenuOpen;
    },
    onToggleHistoryMenu() {
      this.isHistoryMenuOpen = !this.isHistoryMenuOpen;
    },
    async onToggleNotificationMenu() {
      this.isNotificationMenuOpen = !this.isNotificationMenuOpen;
      await this.loadNotifications();
    },
    async loadCompanies() {
      this.user = await authService.loadCompanies(this.user)
      this.user = await authService.setDrp(this.user)
      // let result = await tryCatch(() => userService.myCompanies());
      //
      // this.user.companies_full = result.response;
      // this.user.companies.forEach((c) => {
      //   this.user.companies_full.forEach((c_full) => {
      //     if (c.oid === c_full.oid) {
      //       c_full.role = c.role;
      //     }
      //   });
      // });
      this.$forceUpdate();
    },
    async changeDefCompany(newCompany) {
      await tryCatch(() =>
        userService.changeDefaultCompany(newCompany.oid)
      );

      if (newCompany.role && this.$route.path !== "/company") {
        await this.$router.push("/company");
      } else if (
        this.$route.path === "/dashboard" ||
        this.$route.path === "/"
      ) {
        location.reload();
      } else {
        await this.$router.push("/dashboard");
      }
    },
    async reload() {
      let result = await tryCatch(() => userService.myUser());
      authService.setAuthenticated(result.response);
      window.location.href = window.location.pathname + "?" + this.needUpgrade;
    },
    async setImpersonating() {
      const result = await userService.isImpersonating();
      this.impersonating = result;
    },
    isImpersonate() {
      this.setImpersonating();
      return this.impersonating;
    },
    async stopImpersonate() {
      this.closeAllKVM();
      await userService.tryStopImpersonate(this.user.oid);
    },
    closeAllKVM() {
      this.serversKvm.forEach((kvm) => {
        this.removeKvm(kvm.oid);
      });
    },
    openTicketCreation() {
      this.$refs.modalTicketCreation.show();
    },
    async addNewCompany() {
      this.$refs.companyCreation.show();
    },
    async closeNewCompany() {
      this.$refs.companyCreation.close();
      await this.refreshUser();
      // await this.loadCompanies();
    },
    async checkForTicketUpdate() {
      if (!this.isTitanAdmin(this.user)) return
      const lastUpdate =  await getLastUpdate();
      if (lastUpdate !== this.lastTicketUpdate) {
        this.lastTicketUpdate = lastUpdate
        await this.loadTicket()
        this.showTicket()
      }
    },
    async loadTicket() {
      let result = await tryCatch(() =>
        ticketService.list(getSearchParams(this.ticketFilters))
      );
      if (result.response && result.response.length > 0) {
        this.ticket = result.response[0];
      } else {
        this.ticket = null;
      }
    },
    showTicket() {
      if (this.ticket && this.ticket.state !== "closed" &&
        (this.ticket.state === "pending_admin" || this.ticket.state === "new_pending_admin")) {
        createToast(
          withProps(TicketAlert, {ticket: this.ticket, user: this.user, ctx: this}),
          {
            type: "success",
            showIcon: false,
            position: "bottom-right",
            showCloseButton: false,
            onclick() { document.querySelector('#goto-ticket').click(); }
          }
        );
      }
    },
    gotoTicket() {
      this.$router.push('/tickets/' + this.ticket.oid);
    },
  },
};
</script>
<style lang="sass" scoped>
//.dark
//  .selected.dropdown-item
//    background-color: var(--bs-gray-900)
//
//  .dropdown-item:hover
//    background-color: var(--bs-gray-900)
//
.dropdown-divider-sm
  margin: 0 0

.dropdown-menu-notif
  max-height: 30rem
  min-width: 500px !important

@media (max-width: 700px)
  .dropdown-menu-notif
    display: none
  .header-event
    display: none
</style>
<style>
.topbar {
  border-radius: 0 !important;
  margin: 0 !important;
}

.impersonating {
  border-left-color: #ad003d !important;
}

/*.bg-danger .btn-white {*/
/*  background-color: #ad003d !important;*/
/*}*/

.corner-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  width: 100%;
}

.corner-ribbon {
  width: 75px;
  background: white;
  position: absolute;
  top: 8px;
  right: -25px;
  left: auto;
  transform: rotate(45deg);
  text-align: center;
  line-height: 15px;
  font-size: 10px;
  color: black;
  letter-spacing: 1px;
  box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.3);
}

.scrollable-menu {
  height: auto;
  max-height: calc(100vh - 80px - 10px);
  overflow-x: hidden;
}

</style>
