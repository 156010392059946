<template>
  <div
    v-b-popover.auto.hover.html="popupContent" :title="popupTitle"
    class="avatar-face"
    :class="[{ 'avatar-absolute': edit, 'avatar-flipped': flip, 'avatar-anim-face': animation }]"
    @click="flipAvatar()"
  >
    <div
      v-if="!data || !imageData"
      :class="computeAvatarColor()"
      class="avatar"
    >
      {{ initials }}
    </div>
    <div
      v-else
      :class="customClass"
      class="avatar"
    >
      <img
        :src="`data:image/jpeg;base64,${imageData}`"
        alt="avatar-img"
      >
    </div>
  </div>
  <div
    class="avatar-face avatar-back"
    :class="[customClass, {
      'd-none': !edit,
      'avatar-anim-back': animation,
      'avatar-absolute': edit,
      'avatar-flipped': !flip,
      'avatar-trash-edit': imageData,
      'avatar-edit-only': !imageData,
      'avatar-trash-over': avatarTrashOver,
      'avatar-edit-over': avatarEditOver && imageData,
      'avatar-edit-only-over': avatarEditOver && !imageData 
    }]"
    @click="flipAvatar()"
  >
    <button
      id="btn-remove-company-avatar"
      class="btn btn-sm"
      :class="[ { 'd-none': !imageData }]"
      v-b-popover.hover.bottom.html="$t('actions.remove-avatar')"
      @mouseover="avatarTrashOver = true"
      @mouseleave="avatarTrashOver = false"
      @click.stop="removeAvatar()"
    >
      <i class="fas fa-trash" />
    </button>
    <button
      id="btn-change-company-avatar"
      class="btn btn-sm"
      v-b-popover.hover.bottom.html="$t('actions.change-avatar')"
      @mouseover="avatarEditOver = true"
      @mouseleave="avatarEditOver = false"
      @click.stop="changeAvatar()"
    >
      <i class="fa fa-pencil-alt" />
    </button>
  </div>
  <avatar-cropper
    ref="avatar"
    v-model="avatarEdition"
    :labels="{
      submit: `${$t('actions.confirm')}`,
      cancel: `${$t('actions.cancel')}`,
    }"
    :upload-handler="handleSubmit"
    mimes="image/png, image/jpeg"
    @cancel="flipAvatar()"
    @error="handleError"
  />
</template>

<script>
import AvatarCropper from "vue-avatar-cropper";
import avatarService from "../../services/avatar.service";
import { tryCatch } from "./util";
import {vBPopover} from "bootstrap-vue-3";

export default {
  directives: {
    "b-popover": vBPopover
  },
  components: {
    AvatarCropper,
  },
  props: {
    name: {
      type: String,
      default: "",
    },
    data: {
      type: String,
      default: "",
    },
    customClass: {
      type: String,
      default: "",
    },
    edit: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["remove", "change"],
  data() {
    return {
      imageData: null,
      flip: false,
      avatarTrashOver: false,
      avatarEditOver: false,
      avatarEdition: false,
      animation: false,
    };
  },
  computed: {
    initials() {
      const splits = this.name.trim().split(" ") || [];
      const initials = (splits[0] || " ")[0] + (splits[1] || " ")[0];

      return initials.toUpperCase();
    },
    popupContent() {
      return this.edit ? this.$t('actions.message-avatar') : this.name;
    },
    popupTitle() {
      return this.edit ? this.name : '';
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      if (this.data) {
        let result = await tryCatch(() => avatarService.get(this.data));
        if (result.response) this.imageData = result.response;
        else this.imageData = null;
      }
      if (this.edit && !this.imageData) {
        this.animation = true;
        setTimeout(() => this.animation = false, 3000)
      }
    },
    computeAvatarColor() {
      const splits = this.name.trim().split(" ");
      const first = splits[0][0];
      const second = splits[1] ? splits[1][0] : splits[0][1];
      const code = (first || "").charCodeAt() + (second || "").charCodeAt();

      return "avatar-color-" + (code % 8) + " " + this.customClass;
    },
    flipAvatar() {
      if (this.edit) {
        this.flip = ! this.flip;
      }
    },
    removeAvatar() {
      this.$emit("remove");
      this.flip = false;
      this.imageData = null;
    },
    changeAvatar() {
      this.avatarEdition = true;
    },
    async handleSubmit(cropperInstance) {
      this.imageData = cropperInstance
        .getCroppedCanvas({
          width: 80,
          height: 80,
        })
        .toDataURL("image/jpeg", 1)
        .split("base64,")[1];
      this.$emit("change", this.imageData);
      this.flip = false;
    },
    handleError({message, type, context}) {
      if (type === "upload") {
        const {request, response} = context;
        console.log(request, response, message);
      }
    },
  },
};
</script>

<style></style>
