import {extend} from "lodash-es";
import userService from "../services/user.service";
import {tryCatch} from "@/components/shared/util";
import authService from "../services/auth.service";
import ticketService from "@/services/ticket.service";

const initialState = {
  loading: false,
  user: null,
  ticketPending: 0,
  currentCGV: {oid:""},
  status: {}
};

const loggedUser = authService.getAuthenticated();

// console.log('loggedUser', loggedUser)
if (loggedUser) {
  initialState.user = loggedUser
  initialState.status = {loggedIn: true};
  setTimeout(async () => {
    let rUser = await tryCatch(() => userService.myUser());
    const user = rUser.response;
        
    let result = await tryCatch(() => userService.myCompanies());
    user.companies_full = result.response;
    // console.log('updated', user)

    user.drp_url = "";
    user.companies_full.forEach((c_full) => {
      if (user.company_oid === c_full.oid && c_full.drp_url) {
        user.drp_url = c_full.drp_url;
      }
    });

    initialState.user = extend(loggedUser, user)
    authService.setAuthenticated(user)

    let rTicketPending = await tryCatch(() => ticketService.getPendingTickets());
    initialState.ticketPending = rTicketPending.response;

    let rCGV = await tryCatch(() => authService.getCurrentCGV());
    initialState.currentCGV = rCGV.response;
  })
}

export const auth = {
  namespaced: true,

  state: initialState,

  getters: {
    avatar: state => state.user && (state.user.firstname[0].toUpperCase() + state.user.lastname[0].toUpperCase())
  },

  actions: {
    refreshTicketPending({commit}) {
      commit('REFRESH_TICKET_PENDING')
    },
    refreshCurrentCGV({commit}) {
      commit('REFRESH_CURRENT_CGV')
    },
    switchCompanyContext({commit}, user_company_role) {
      commit('COMPANY_CONTEXT', user_company_role)
    },
    register({commit}, user) {
      commit('LOADING', true)
      return userService.register(user)
        .then((authenticated) => {
          commit('LOGIN_SUCCESS', authenticated);
          return authenticated;
        })
        .catch((error) => {
          commit('LOGIN_FAILURE', error);
          return Promise.reject(error);
        })
        .finally(() => {
          commit('LOADING', false);
        })
    },
    registerOnInvite({commit}, {company_uuid, user}) {
      // console.log('registerOnInvite', company_uuid, user)
      commit('LOADING', true)
      return userService.registerInvitation(company_uuid, user)
        .then((authenticated) => {
          commit('LOGIN_SUCCESS', authenticated);
          return authenticated;
        })
        .catch((error) => {
          commit('LOGIN_FAILURE', error);
          return Promise.reject(error);
        })
        .finally(() => {
          commit('LOADING', false);
        })
    },

    login({commit}, {email, password, code, redirect}) {
      commit('LOADING', true);
      return authService.login({email, password, code, redirect})
        .then((authenticated) => {
          commit('LOGIN_SUCCESS', authenticated.user);
          return authenticated;
        })
        .catch((error) => {
          commit('LOGIN_FAILURE', error);
          return Promise.reject(error);
        })
        .finally(() => {
          commit('LOADING', false);
        });
    },

    login2fa({commit}, code) {
      commit('LOADING', true);
      return authService.auth2FA(code)
        .then((authenticated) => {
          commit('LOGIN_SUCCESS', authenticated);
          return authenticated;
        })
        .catch((error) => {
          commit('LOGIN_FAILURE', error);
          return Promise.reject(error);
        })
        .finally(() => {
          commit('LOADING', false);
        });
    },

    logout({commit}, usePrefs) {
      authService.clearSession(usePrefs).then(
        authService.logout())
      commit('LOGOUT');
    },

    // updateUnseenTickets({commit}) {
    //   commit('UPDATE_UNSEEN_TICKETS')
    // },

    refreshUser({commit}) {
      commit('LOADING', true);
      return authService.refreshUser()
        .then((authenticated) => {
          commit('LOGIN_SUCCESS', authenticated);
          return authenticated;
        })
        .catch((error) => {
          commit('LOGIN_FAILURE', error);
          return Promise.reject(error);
        })
        .finally(() => {
          commit('LOADING', false);
        });
    },

    getUserPref(store) {
      store.commit('GET_USER_PREF')
    },

    sendUserPref(store) {
      store.commit('SEND_USER_PREF')
    },
  },

  mutations: {
    REFRESH_TICKET_PENDING(state){
      tryCatch(() => ticketService.getPendingTickets()).then(r => {
        state.ticketPending = r.response;
      });
    },
    REFRESH_CURRENT_CGV(state){
      tryCatch(() => authService.getCurrentCGV()).then(r => {
        state.currentCGV = r.response;
      });
    },
    LOADING(state, loading) {
      state.loading = loading;
    },

    LOGIN_SUCCESS(state, user) {
      state.status = {loggedIn: true};
      state.user = user;
    },

    LOGIN_FAILURE(state) {
      state.status = {loggedIn: false};
      state.user = null;
    },

    LOGOUT(state) {
      state.status = {loggedIn: false};
      state.user = null;
    },
    COMPANY_CONTEXT(state, company_context) {
      state.user.company_oid = company_context
    },
    // async UPDATE_UNSEEN_TICKETS(state) {
    //   if (!state.user) {
    //     return
    //   }
    //
    //   let result = await tryCatch(ticketService.getUnseenTickets)
    //
    //   if (result.isSuccess) {
    //     state.user.number_ticket_answer_not_read = result.response
    //   }
    // }

    
    
    //TODO: Af inir
    GET_USER_PREF(state) {
      // console.log("GET_USER_PREF")
      userService.myUser().then(result => {
        // console.log("GET USER PREF", result)
        state.preference = result.data.preference
        localStorage.setItem('preference', JSON.stringify(result.data.preference))
      })
    },

    //Send user preference to the backend and Update Origin user preferences. 
    SEND_USER_PREF(state) {
      
      if (!state.user.preference.dashboard) {
        // console.log('no dashboard')
        state.user.preference.dashboard = []
      }

      userService.updateUserPreferences(state.user.preference)
      auth.state.user.preference = state.user.preference
    }
  }
};
