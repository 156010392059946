import {settings} from "@/settings";
import _ from "lodash-es";
import {UserLang} from "@/i18n";

function roundForPriceLocal(value, withZero = false) {
  if (value === null || value === undefined || !value)
    return '-'
  
  value = _.round((value / settings.pricingFactor),2)
  
  if (value === 0 ){
    if(withZero)
      return 0
    else 
      return "-"
  }
  
  return value.toLocaleString(UserLang, {maximumFractionDigits: 2, minimumFractionDigits: 2})
}

function round(value) {
  if (value === 0)
    return '-'
  if (!value) {
    return value;
  }
  return value.toLocaleString(UserLang, {maximumFractionDigits: 2, minimumFractionDigits: 2})
}

export {roundForPriceLocal, round}
