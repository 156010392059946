export function formatStorageValue(amount, precision = 1, startUnits = "b") {
  const byteUnits = [
    "b", 
    "kb",
    "Mb",
    "Gb",
    "Tb",
    "Pb",
    "Eb",
    "Zb",
    "Yb",
  ];
  let i = byteUnits.indexOf(startUnits);
  
  while (amount >= 1000) {
    amount = amount / 1000;
    i++;
  }
  
  if (i < 0)
    i = 0

  return amount.toFixed(precision) + " " + byteUnits[i];

}
