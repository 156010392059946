class LocalStorageService {

  saveDiscountCode(discountCode) {
    localStorage.setItem('promotion_code', discountCode)
  }

  getDiscountCode() {
    return localStorage.getItem('promotion_code')
  }
  removeDiscountCode() {
    return localStorage.removeItem('promotion_code')
  }

}

export default new LocalStorageService();
