<template>
  <div class="container">
    <div class="card">
      <div class="card-body">
        <h1 class="card-title">
          Legal notice.
        </h1>

        <h4>Editor:</h4>
        <p>
          Titan Datacenters France SAS, simplified joint-stock company with
          capital of € 1,042,907.50, registered with the Trade and Corporate
          Register of Cannes under number 789&nbsp;683&nbsp;190, located at 45
          Allée des Ormes E. Space Park Bâtiment D, 06250 Mougins.
        </p>

        <h4>Publication and Editorial Direction:</h4>

        <p>
          Director of Publication: Titan Datacenters France <br>
          Web development: Titan Datacenters France <br>
          Design: Ellen Coopers Paris <br>
        </p>

        <p>
          In accordance with the law n°1881-07-29 from July 29th, 1881 relating
          to the freedom of press and from the law n°86-1067 from September
          30th, 1986 relating to the freedom of communication, all
          correspondence shall be sent to Titan Datacenters France.
        </p>

        <h4>Hosting:</h4>

        <p>
          The website https://sc.titandc.net (hereinafter the “Website”) is
          hosted by the Company Titan Datacenters France
        </p>

        <h4>Website Credits:</h4>

        <p>
          Content management: Titan Datacenters France <br>
          Website design: Titan Datacenters France <br>
          Website realization: Titan Datacenters France <br>
          Website property: Titan Datacenters France <br>
        </p>

        <h4>Intellectual property:</h4>

        <p>
          Titan Datacenters France is holder of the intellectual property rights
          of the Website and its content, either as an owner, or as an operator
          partner’s concessionaire.
        </p>

        <p>
          As such, Titan Datacenters France is the owner of the domain name
          titandc.net, of copyrights relating to the Website’s content and of
          the Website databases producers ‘rights.
        </p>

        <p>
          Besides, Titan Datacenters France owns
        </p>
        <ul>
          <li>
            the French registered trademark « TITAN DATACENTER », filed at the
            French National Trademarks Office (INPI) on December 27, 2012 under
            the number ; Class ; NICE classification: 9, 38, and 42;
          </li>
          <li>
            the European registered trademark « TITAN DATACENTER », filed at the
            OHIM on March 8, 2015 under the number ; Class ; NICE
            classification: 9, 38, and 42.
          </li>
        </ul>

        <p>
          By consequence, and without limiting the foregoing, access to the
          Services in order to perform an extraction, reproduction, use, resale,
          exchange, rental, modification, adjustment, correction, whether free
          of charge or for a valuable consideration, in whole or in parts of the
          Website, its Services and their contents, protected by copyrights,
          trademark law, software rights, producers of the Website databases
          ‘rights or by any other intellectual property right, is expressly
          prohibited.
        </p>

        <h4>Personal Data:</h4>

        <p>
          The information collected is subject to a computer processing intended
          to improve the relationship between Titan Datacenters France and the
          User and to notify the latter with Titan Datacenters France
          promotional and business operations. Pursuant to the Data Protection
          Act of 6 January 1978, amended in 2004, the User has a right to access
          and correct the data referring to him/her, which he may directly
          exercise by sending a letter to Titan Datacenters France, 45 Allée des
          Ormes E. Space Park Bâtiment D, 06250 Mougins, or by filling the
          contact form to the following address:
          <a
            href="https://titandc.net/en/contact_queries"
          >https://titandc.net/en/contact_queries</a>
        </p>

        <p>
          The User may also, for legitimate purposes, object to the processing
          of his/her personal data by sending a letter to Titan Datacenters
          France, 45 Allée des Ormes E. Space Park Bâtiment D, 06250 Mougins, or
          by filling the contact form to the following address:
          <a
            href="https://titandc.net/en/contact_queries"
          >https://titandc.net/en/contact_queries</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
