import {common} from '@/settings'
import axios from "axios";

const NETWORK_STAT_API_URL = common.api.site_specific_url
const NETWORK_API_URL = common.api.site_specific_url + '/network'
const ADMIN_NETWORK_API_URL = common.api.site_specific_url + '/admin/network'
const NETWORK_IP_API_URL = common.api.site_specific_url + '/network/ip'
const IP_API_URL = common.api.site_specific_url + '/ip'

class NetworkService {
  vswitchStat(network_oid, {metric, start, end}) {
    return axios.get(NETWORK_STAT_API_URL + '/history/vswitch/' + network_oid +
      '/?'
      + 'metric=' + metric
      + '&start=' + (start || (Date.now() - (11 * 60 * 60 * 1000)))
      + '&end=' + (end || Date.now())
    )
  }

  vswitchStatTest(network_oid, {metric, start, end}) {
    return axios.get("http://localhost:8081/v2" + '/history/vswitch/' + network_oid +
      '/?'
      + 'metric=' + metric
      + '&start=' + (start || (Date.now() - (11 * 60 * 60 * 1000)))
      + '&end=' + (end || Date.now())
    )
  }

  list(searchParams = undefined) {
    return axios.get(NETWORK_API_URL + '/switch'+ (searchParams ? '?' + searchParams.toString() : ''))
  }

  listNetworks(company_oid) {
    return axios.get(NETWORK_API_URL + '/switch?company_oid=' + encodeURIComponent(company_oid))
  }

  createNetwork() {
    return axios.post(NETWORK_API_URL + '/switch', {})
  }

  deleteNetwork(network_oid) {
    return axios.delete(NETWORK_API_URL + '/switch/' + network_oid)
  }

  attachToNetwork(network_oid, servers_oid) {
    return axios.put(NETWORK_API_URL + '/switch/' + network_oid + '/attach', {servers_oid})
  }

  detachFromNetwork(network_oid, server_oid) {
    return axios.put(NETWORK_API_URL + '/switch/' + network_oid + '/detach', {server_oid})
  }

  updateNetwork(network_oid, payload) {
    return axios.put(NETWORK_API_URL + '/switch/' + network_oid, payload)
  }

  adminUpdateNetwork(network_oid, payload) {
    return axios.put(ADMIN_NETWORK_API_URL + '/switch/' + network_oid, payload)
  }

  resetNetwork(mac_oid) {
    return axios.post(NETWORK_API_URL + '/' + mac_oid + '/reset')
  }

  async attachIP(server_oid, ips_payload) {
    return await axios.post(NETWORK_IP_API_URL + '/' + server_oid, ips_payload)
  }

  async detachIP(server_oid, ip_payload) {
    return await axios.delete(NETWORK_IP_API_URL + '/' + server_oid, {data: ip_payload})
  }

  listIps(searchParams = undefined) {
    return axios.get(IP_API_URL + (searchParams ? '?' + searchParams.toString() : ''))
  }
}

export default new NetworkService()
