import {i18n} from "@/i18n";

const {t} = i18n.global;

export function translateError(error) {
  return error.response ?
    error.response.data ?
      error.response.data.message ?
        t('errors.' + error.response.data.message)
        : error.response.data.error
      : error.message
    : "Error"
}

export function translateSuccess(response) {
  return response.code ? t('errors.' + response.code) : response.success
}

/**
 * Try catch with translation
 * @param func
 * @param withMsgSuccess
 * @param onErrorValue
 * @return {Promise<{response: Object|Array<Object>|null,errorData: Object|Array<Object>|null, successMsg: string|null, totalItems: Number, errorMsg: string|null, isSuccess: boolean, isError: boolean}>}
 */
export async function tryCatch(func, withMsgSuccess = false, onErrorValue = null) {
  let object = {
    successMsg: null,
    errorMsg: null,
    errorData: null,
    response: onErrorValue,
    totalItems: 0,
    isSuccess: false,
    isError: false,
  }

  try {
    let result = (await func())

    if (result === undefined) {
      object.isError = true
      return object
    }
    object.response = result.data
    object.isSuccess = result.status < 300 && result.status >= 200
    object.isError = result.status >= 400
    object.totalItems = result.totalItems

    if (withMsgSuccess)
      object.successMsg = translateSuccess(object.response)
    if (result.isSuccess && !withMsgSuccess)
      object.successMsg = t("errors." + result.status);
    return object
  } catch (error) {
    object.isError = true
    object.errorMsg = translateError(error)

    if (error.response && error.response.data)
      object.errorData = error.response.data

    // console.log(object)

    // createToast(
    //   object.errorMsg, {
    //     type: 'danger',
    //     position: 'top-right',
    //     showIcon: true,
    //     timeout: 5000
    //   }
    // );

    return object
  }
}


