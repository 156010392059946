<template>
  <div class="modal-header-logo">
    <div class="modal-logo">
      <img
        v-if="allColorMode === 'DARK'"
        alt="logo"
        class="header-logo"
        src="../../assets/img/logo-small-cloud-dark-mode.svg"
      >
      <img
        v-if="allColorMode === 'LIGHT'"
        alt="logo"
        class="header-logo"
        src="../../assets/img/logo-small-cloud-light-mode.svg"
      >
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "ModalHeader",
  props: {
    public: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState("app", ["colorMode"]),
    ...mapState("auth", ["user"]),
    allColorMode() {
      if (this.public) return this.colorMode;
      if (!this.user || !this.user.preference || !this.user.preference.color_mode) return "LIGHT";
      return this.user.preference.color_mode.toUpperCase();
    }
  }
};
</script>

<style scoped></style>
