<template>
  <div class="container">
    <div class="card">
      <div class="card-body">
        <h1 class="card-title">
          Accès non autorisé
        </h1>
        <h1 class="card-title mt-5">
          Unauthorized Access
        </h1>
        <h1 class="card-title mt-5">
          Unberechtigter Zugriff
        </h1>
        <h1 class="card-title mt-5">
          Acceso no autorizado
        </h1>
        <h1 class="card-title mt-5">
          Accesso non autorizzato
        </h1>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
