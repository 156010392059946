<template>
  <!--  
  :translations="{
    countrySelectorLabel: $t('pages.account.phone-country'),
    countrySelectorError: $t('pages.account.phone-country-error'),
    phoneNumberLabel: ,
    example: $t('pages.account.phone-ex'),
    }"
    :dark="allColorMode === 'DARK'"
      :error="!isValid"
      :required="required"
       dark-color="black"
      error-color="red"
          color="#00dc77"
      valid-color="#00dc77"
      -->
  <!--    customValidate,defaultCountry,ignoredCountries,mode,preferredCountries-->
  <vue-tel-input
    :id="id"
    v-model="content"
    :auto-default-country="autoFetchCountry"
    :class="customClass"
    :invalid-msg="$t('pages.account.phone')"
    auto-format
    class="w-100 form-control"
    default-country="FR"
    mode="international"
    valid-characters-only
    @validate="onValidateChange($event)"
  />
</template>

<script>
import { VueTelInput } from "vue-tel-input";
import { mapState } from "vuex";

export default {
  name: "PhoneInput",
  components: {
    VueTelInput,
  },
  props: {
    customClass: {
      type: String,
      default: "",
    },
    modelValue: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    public: {
      type: Boolean,
      default: false,
    },
    autoFetchCountry: {
      type: Boolean,
      default: true,
    },
    error: {
      type: Boolean,
      default: null,
    },
  },
  emits:['update:modelValue'],
  data() {
    return {
      content: "",
      isValid: true,
    };
  },
  computed: {
    ...mapState("auth", ["user"]),
    ...mapState("app", ["colorMode"]),
    allColorMode() {
      if (this.public) return this.colorMode;
      if (!this.user || !this.user.preference || !this.user.preference.color_mode) return "LIGHT";
      return this.user.preference.color_mode.toUpperCase();
    },
  },
  mounted() {
    this.content = this.modelValue;
    // console.log(this.content)
  },
  methods: {
    onValidateChange(phone) {
      this.isValid = phone.valid;

      if (phone.number === undefined && !this.required)
        this.isValid = true;
      
      this.$emit("update:modelValue", phone.number);
    },
    initPhone() {
      this.content = "";
      this.isValid = true;
    },
  },
};
</script>

<style lang="sass" scoped></style>
