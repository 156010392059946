import cartService from '../services/cart.service'
import {sortBy} from "lodash-es";
import {tryCatch} from "@/components/shared/util";
import authService from "../services/auth.service";
import store from "./store";

const initialState = {
  orders: []
};

const globalLoggedUser = authService.getAuthenticated();
if (globalLoggedUser) {
  setTimeout(async () => {
    await store.dispatch("cart/reloadOrder")
  })
}

function sortingCart(myOrders) {
  const loggedUser = authService.getAuthenticated();

  myOrders.oid.cart_oid = loggedUser.oid
  myOrders.groups.map(group => {
    group.items = sortBy(group.items, "oid")
    if (!group.project || !group.project.frequency)
      group.project = {name: "Default", frequency: "MONTHLY"}
  })
  myOrders.groups = sortBy(myOrders.groups, "oid")
  return myOrders
}


export const cart = {
  namespaced: true,

  state: initialState,

  actions: {
    async reloadOrder(cart) {
      let without_prorata = JSON.parse(
        localStorage.getItem("adminQuoteMode") ?? "false");
      let user = authService.getAuthenticated()
      if (!user)
        return

      let payload = {
        cart_oid: user.oid,
        user_oid: user.oid,
        without_prorata: without_prorata,
      }
      let result = await tryCatch(() => cartService.getCart(payload));

      let myOrders = result.response
      if (myOrders)
        myOrders = sortingCart(myOrders)

      cart.commit('SET_ORDERS', myOrders)
    },
    addOrder(cart, data) {
      cart.commit('ADD_ORDER', data);
    },

    removeOrder(cart, data) {
      cart.commit('REMOVE_ORDER', data);
    },

    updateOrder(cart, data) {
      cart.commit('UPDATE_ORDER', data);
    },

    updateOrders(cart, data) {
      cart.commit('UPDATE_ORDERS', data);
    },

    resetOrders(cart, cart_oid) {
      cart.commit('RESET_ORDERS', cart_oid);
    },

    resetState(cart) {
      cart.commit('RESET_STATE');
    },

    getOrders(cart, cart_oid) {
      cart.commit('GET_ORDERS', cart_oid)
    },

    setOrders(context, orders) {
      //console.log("set orders", orders)
      context.commit('SET_ORDERS', orders)
    },
  },
  mutations: {
    ADD_ORDER(state, data) {
      state.orders.push(data.order);
      //if(!data.public) cartService.cartUpdate(data.oid,state.orders)
    },

    REMOVE_ORDER(state, data) {
      state.orders = state.orders.filter((item) => data.order.id !== item.id);
      //if(!data.public) cartService.cartUpdate(data.oid,state.orders)
    },

    UPDATE_ORDER(state, data) {
      state.orders = state.orders.map((item) => {
        if (item.id === data.order.id) {
          item = Object.assign({}, item, data.order);
        }
        return item;
      });
      //if(!data.public) cartService.cartUpdate(data.oid,state.orders)
    },

    UPDATE_ORDERS(state, data) {
      state.orders = sortingCart(data);
      //if(!data.public) cartService.cartUpdate(data.oid,state.orders)
    },

    RESET_ORDERS(state) {
      state.orders = [];
      //cartService.cartDeletion(user_uuid)
    },

    RESET_STATE(state) {
      state.orders = [];
    },

    GET_ORDERS(state, cart_oid) {
      let without_prorata = JSON.parse(
        localStorage.getItem("adminQuoteMode") ?? "false");
      let payload = {
        cart_oid: cart_oid,
        user_oid: cart_oid,
        without_prorata: without_prorata,
      }
      setTimeout(async () => {
        let result = await tryCatch(() => cartService.getCart(payload))
        state.orders = sortingCart(result.response)
      })
    },

    SET_ORDERS(state, orders) {
      state.orders = orders
    }
  }
};

