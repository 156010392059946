<template>
  <div :id="compId" />
</template>

<script>
import postscribe from "postscribe";

var ready = require("document-ready");
export default {
  name: "ScriptComponent",
  props: {
    script: {
      type: String,
      default: null,
    },
    className: {
      type: String,
      default: null,
    },
    scriptId: {
      type: String,
      default: null,
    },
  },
  data() {
    let randomID = Math.random().toString(36).substring(7);
    return {
      compId: window.performance.now() + "-" + randomID,
    };
  },
  beforeUnmount() {
    let doc = this.className
      ? document.getElementsByClassName(this.className)
      : [];
    console.log(typeof doc)
    if (doc && doc.length > 0 && typeof doc === Array) {
      doc.forEach((e) => {
        e.remove();
      });
    }

    let scriptElt = document.getElementById(this.scriptId);
    if (scriptElt) {
      scriptElt.remove();
    }
  },
  mounted() {
    if (window.location.pathname !== "/cgv-sign")
      setTimeout(() => {
        let doc = this.className
          ? document.getElementsByClassName(this.className)
          : [];
        if (!doc || !doc.length) {
          var vm = this;
          ready(function () {
            let addEl = new Promise((resolve) => {
              postscribe(`#${vm.compId}`, `${vm.script}`, {
                done: function (x) {
                  resolve(x);
                },
              });
              resolve();
            });
            addEl.then(function () {});
          });
        }
      }, 500);
  },
};
</script>

<style scoped></style>
