<template>
  <div
    v-if="ticket"
    :id="`row-tickets-${ticket.number}`"
    class="ticket"
  >
    <div class="ticket-meta">
      <div class="ticket-object">
        <div>
          {{ title }}
        </div>
        {{ ticket.number }} - {{ ticket.object }}
      </div>
      <div class="ticket-footer">
        <div class="ticket-date">
          <div>
            {{ ticket.company.name }}
          </div>
          {{ ticket.user.firstname + ' ' + ticket.user.lastname }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {vBPopover} from "bootstrap-vue-3";

export default {
  name: "TicketAlert",
  directives: {
    "b-popover": vBPopover
  },
  components: {},
  props: {
    ticket: {
      type: Object,
      default: ()=>{},
    },
    user: {
      type: Object,
      default: ()=>{},
    },
    ctx: {type: Object, default: () => null},
  },
  computed: {
    title() {
      if (this.ticket.state === "new_pending_admin") {
        return this.ctx.$t('ticket.ticket-new');
      }
      return this.ctx.$t('ticket.ticket-response');
    },
  },
  methods: {
  },
};
</script>

<style scoped></style>
