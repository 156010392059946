import {createStore} from 'vuex'

import {auth} from './auth.module';
import {app} from './app.module';
import {cart} from './cart.module';
import {kvm} from './kvm.module';
// import {winTicket} from './ticket.module';


export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth: auth,
    app: app,
    cart,
    kvm: kvm,
    // winTicket: winTicket
  }
})
