import {common} from '@/settings'
import axios from "axios";
import {getSearchParams} from "@/services/utils/searchBuilder";


const API_URL = common.api.url;
const API_LICENSE_PLESK = '/license/plesk';

class licenseService {
  getPleskLicenseDetail(key_id) {
    return axios.get(API_URL + API_LICENSE_PLESK + "/" + key_id)
  }

  getPleskLicenceList(searchParams) {
    return axios.get(API_URL + API_LICENSE_PLESK + '/list' + (searchParams ? '?' + searchParams.toString() : ''))
  }

  createPleskLicenseList() {
    return axios.post(API_URL + API_LICENSE_PLESK + "/list")
  }

  disablePleskLicenseList(key_id) {
    return axios.delete(API_URL + API_LICENSE_PLESK + "/" + key_id)
  }

  async getPleskLicenceListCsv(searchParams = undefined) {
    return axios.get(API_URL + API_LICENSE_PLESK + '/csv' + (searchParams ? '?' + searchParams.toString() : ''))
  }

  getServerPleskLicense(key_id) {
    return axios.get(API_URL + API_LICENSE_PLESK + "/server/" + key_id)
  }
}

export default new licenseService()
