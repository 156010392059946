<template>
  <div class="container">
    <div class="card">
      <div class="card-body">
        <h1 class="card-title">
          Mentions légales.
        </h1>

        <h4>Éditeur:</h4>
        <p>
          Titan Datacenters France SAS, société par actions simplifiée au
          capital de 1&nbsp;042&nbsp;907,50 €, inscrite au Registre du Commerce
          et des Sociétés de Cannes sous le numéro 789&nbsp;683&nbsp;190, situé
          au 45 Allée des Ormes E. Space Park Bâtiment D, 06250 Mougins.
        </p>

        <h4>Direction de la publication et de la rédaction:</h4>

        <p>
          <b>Directeur de publication:</b> Titan Datacenters France<br>
          <b>Développement Web:</b> Titan Datacenters France<br>
          Conformément à la loi n° 1881-07-29 du 29 juillet 1881 relative à la
          liberté de la presse et à la loi n ° 86-1067 du 30 septembre 1986
          relative à la liberté de communication, toute correspondance sera
          adressée à Titan Datacenters France.
        </p>

        <h4>Hébergement:</h4>

        <p>
          Le site https://sc.titandc.net (ci-après le «Site») est hébergé par la
          société Titan Datacenters France
        </p>

        <h4>Crédits du site:</h4>

        <p>
          Gestion de contenu: Titan Datacenters France <br>
          Conception du site web: Titan Datacenters France <br>
          Design du site web: Ellen Coopers Paris <br>
          Réalisation du site internet: Titan Datacenters France <br>
          Propriété du site: Titan Datacenters France
        </p>

        <h4>Propriété intellectuelle:</h4>

        <p>
          Titan Datacenters France est titulaire des droits de propriété
          intellectuelle du site et de son contenu, soit en tant que
          propriétaire, soit en tant que concessionnaire du partenaire
          opérateur.
        </p>
        <p>
          À ce titre, Titan Datacenters France est propriétaire du nom de
          domaine titandc.net, des droits d’auteur relatifs au contenu du site
          et des droits des producteurs de bases de données du site.
        </p>
        <p>En outre, Titan Datacenters France possède</p>
        <ul>
          <li>
            la marque déposée française «TITAN DATACENTER», déposée à l'Office
            national des marques (INPI) le 27 décembre 2012 sous le numéro;
            Classe; Classification NICE: 9, 38 et 42;
          </li>
          <li>
            la marque déposée européenne «TITAN DATACENTER», déposée à l'OHMI le
            8 mars 2015 sous le numéro; Classe; Classification NICE: 9, 38 et
            42.
          </li>
        </ul>

        <p>
          Par conséquence et sans limitation de ce qui précède, l’accès aux
          Services pour effectuer une extraction, une reproduction, une
          utilisation, une revente, un échange, une location, une modification,
          un ajustement, une correction, gratuitement ou pour une contrepartie
          valable, en totalité ou en parties du site Web, ses services et leur
          contenu, protégés par les droits d'auteur, le droit des marques, les
          droits sur les logiciels, les producteurs des bases de données du site
          Web ou tout autre droit de propriété intellectuelle, sont expressément
          interdits.
        </p>

        <h4>Données personnelles:</h4>

        <p>
          Les informations collectées font l'objet d'un traitement informatique
          destiné à améliorer la relation entre Titan Datacenters France et
          l'Utilisateur et à notifier ce dernier aux opérations promotionnelles
          et commerciales de Titan Datacenters France. Conformément à la loi
          informatique et libertés du 6 janvier 1978 modifiée en 2004,
          l'Utilisateur dispose d'un droit d'accès et de rectification aux
          données qui le concernent, qu'il peut exercer directement en adressant
          un courrier à Titan Datacenters France, 45 Allée des Ormes E. Space
          Park Bâtiment D, 06250 Mougins, ou en remplissant le formulaire de
          contact depuis le site à l'adresse suivante:
          <a
            href="https://titandc.net/fr/contact_queries"
          >https://titandc.net/fr/contact_queries</a>
        </p>

        <p>
          L'utilisateur peut également, à des fins légitimes, s'opposer au
          traitement de ses données personnelles en adressant un courrier à
          Titan Datacenters France, 45 Allée des Ormes E. Space Park Bâtiment D,
          06250 Mougins, ou en remplissant le formulaire de contact depuis le
          site à l'adresse suivante:
          <a
            href="https://titandc.net/fr/contact_queries"
          >https://titandc.net/fr/contact_queries</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
