class OsInfos {

  /**
   * Construct
   * @param {String} os
   * @param {String} version
   */
  constructor(os, version = '') {
    this.os = os;
    this.version = version
  }
  
  static empty() {
    return new OsInfos('')
  }
  
  isPlesk() {
    return RegExp('plesk','i').test(this.os) || (this.isUbuntu() && RegExp('plesk', 'i').test(this.version))
  }

  isWindows() {
    return RegExp('windows','i').test(this.os)
  }

  isCentos() {
    return RegExp("centos", "i").test(this.os)
  }
  
  isRedhat() {
    return RegExp("redhat", "i").test(this.os)
  }
  
  isDebian() {
    return RegExp("debian", "i").test(this.os)
  }
  
  isPfsense() {
    return RegExp("pfsense", "i").test(this.os)
  }
  
  isFedora() {
    return RegExp("fedora", "i").test(this.os)
  }
  
  isUbuntu() {
    return RegExp("ubuntu", "i").test(this.os)
  }

  is3cx() {
    return RegExp("3cx", "i").test(this.os)
  }

  isTrueNas() {
    return RegExp("truenas", "i").test(this.os)
  }

  isESX() {
    return RegExp("esx", "i").test(this.os)
  }

  isFreePBX() {
    return RegExp("freepbx", "i").test(this.os)
  }

  isRocky() {
    return RegExp("rocky", "i").test(this.os)
  }

  isOpnsense() {
    return RegExp("opnsense", "i").test(this.os)
  }

  isYeastar() {
    return RegExp("yeastar", "i").test(this.os)
  }

  isIspConfig() {
    return RegExp("ispconfig", "i").test(this.os)
  }

  isSophos() {
    return RegExp("sophos", "i").test(this.os)
  }

  isPacketFence() {
    return RegExp("packetfence", "i").test(this.os)
  }

  isClearOS() {
    return RegExp("clearos", "i").test(this.os)
  }

  isPBXware() {
    return RegExp("pbxware", "i").test(this.os)
  }

  isCloudLinux() {
    return RegExp("cloudlinux", "i").test(this.os)
  }

  isImage() {
    return this.os.toUpperCase() === "IMAGE";
  }
  
  osHasLicense() {
    return this.isPlesk() || this.isWindows()
  }
  
  isWindowsWithLicense() {
    return this.isWindows() && RegExp('license','i').test(this.version)
  }
  
  hasLicense() {
    return this.isPlesk() || this.isWindowsWithLicense()
  }
  
  isAllowedToMigrateTo(otherOs) {
    let errorMigration = null
    //Check compliance licence migration
    if(this.hasLicense() !== otherOs.hasLicense())
      errorMigration = 'tooltip_license'
    
    //Windows to windows only
    if(this.isWindows() !== otherOs.isWindows())
      errorMigration = 'tooltip_windows_to_windows'
    
    return errorMigration
  }
  
}

export default OsInfos
