import {common} from '@/settings';
import axios from "axios";
import {tryCatch} from "@/components/shared/util";
import authService from "./auth.service";
import {omit, extend} from "lodash-es";

const API_URL_USER = common.api.url + '/user'
const API_URL_PREFERENCE = API_URL_USER + '/preference'

class UserService {

  async register(user) {
    const response = await axios.post(API_URL_USER, user)
    /*if (every(response.companies, company => company.role.accountant)) {
      throw new Error('Accountant cannot register')
    }*/
    
    //console.log(response)

    if (response.jwt) {
      this.setToken(response.jwt)
      this.setAuthenticated(omit(response, ['jwt']))
    }

    return response
  }

  async registerInvitation(company_id, user) {
    return await axios.post(API_URL_USER + '/' + company_id + '/register', user)
  }

  addNewCompanyToUser(payload) {
    return axios.post(API_URL_USER + '/add_company', payload)
  }

  list(searchParams = undefined) {
    return axios.get(API_URL_USER + (searchParams ? '?' + searchParams.toString() : ''))
  }

  detail(user_oid = undefined) {
    return axios.get(API_URL_USER + '/' + (user_oid !== undefined ? user_oid : ''))
  }

  myUser() {
    return axios.get(API_URL_USER + '/me')
  }
  myPrefenrences() {
    return axios.get(API_URL_PREFERENCE)
  }

  myCompanies() {
    return axios.get(API_URL_USER + "/companies")
  }

  changeDefaultCompany(company_oid) {
    return axios.put(API_URL_USER + '/default_company/' + company_oid)
  }

  changePassword(payload) {
    return axios.put(API_URL_USER + '/update_password', payload)
  }

  forgetPassword(data) {
    return axios.put(API_URL_USER + '/reset_password', data)
  }

  async updateUserPreferences(preference) {
    const response = await tryCatch(() => axios.put(API_URL_PREFERENCE, preference))
    // console.log(response)
    if (response.isSuccess) {
      authService.setAuthenticated(extend(authService.getAuthenticated(), {preference: preference}))
    }
    return response
  }

  getPreferences() {
    return axios.get(API_URL_USER + '/preference')
  }

  /**
   *
   * @param id
   * @param user
   * @return {Promise<{response: Object|Array<Object>|null,errorData: Object|Array<Object>|null, successMsg: string|null, totalItems: Number, errorMsg: string|null, isSuccess: boolean, isError: boolean}>}   */
  async updateUser(id, user) {
    const result = await tryCatch(() => axios.put(API_URL_USER + "/" + id, user))
    if (result.isSuccess) {
      authService.setAuthenticated(extend(authService.getAuthenticated(), user))
    }
    return result
  }

  resendEmail(data) {
    return axios.put(API_URL_USER + '/resend', data)
  }

  async tryImpersonate(userOID) {
    const result = await tryCatch(() => axios.post(API_URL_USER + '/impersonate/' + userOID))
    if (!result.isSuccess)
      return result

    authService.setToken({token: result.response.token, expire: result.response.expire})
    let r = await tryCatch(() => this.myUser());
    if (!r.isSuccess)
      return result

    authService.setAuthenticated(r.response)
    window.location = "/"
    
    return result
  }

  async tryStopImpersonate() {
    const result = await tryCatch(() => axios.delete(API_URL_USER + '/impersonate'))
    if (!result.isSuccess)
      return

    authService.setToken({token: result.response.token, expire: result.response.expire})

    let r = await tryCatch(() => this.myUser());
    if (!result.isSuccess)
      return

    authService.setAuthenticated(r.response)
    window.location = "/"
  }

  isImpersonating() {
    const parsedToken = authService.parsingJwt(authService.getToken())

    return !!parsedToken.impersonate_id
  }


  async create() {
    return await axios.post(API_URL_USER + '/')
  }

  async update(contact_uuid, user_payload) {
    return await axios.put(API_URL_USER + '/' + contact_uuid, user_payload)
  }

  async delete(contact_uuid) {
    return await axios.delete(API_URL_USER + '/' + contact_uuid)
  }

  async updateUserRole(data, user_uuid) {
    return axios.put(API_URL_USER + '/' + user_uuid + '/role', data)
  }

  async getCsv(searchParams = undefined) {
    return axios.get(API_URL_USER + '/csv' + (searchParams ? '?' + searchParams.toString() : ''))
  }

}

export default new UserService()
