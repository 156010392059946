<template>
  <div
    v-if="user"
    class="sidebar"
  >
    <div class="sidebar-header">
      <button @click="onToggleSidebarMinified">
        <img
          alt="menu"
          src="../assets/img/burger.svg"
        >
      </button>
    </div>
    <ul class="sidebar-menu">
      <li v-if="isDefaultCompanyManager(user) || isDefaultCompanyAdmin(user) || isDefaultCompanyCommercial(user)">
        <router-link
          id="dashboard"
          :to="{ name: 'dashboard' }"
          class="sidebar-item"
          exact
        >
          <div
            id="dashboard-side"
            class="sidebar-icon"
          >
            <i class="fas fa-tachometer-alt" />
          </div>
          <div class="sidebar-text">
            {{ $t("menu.dashboard") }}
          </div>
          <b-popover
            v-if="isSidebarMinified"
            boundary="window"
            placement="left"
            target="dashboard"
            triggers="hover"
            variant="dark"
          >
            {{ $t("menu.dashboard") }}
          </b-popover>
        </router-link>
      </li>

      <li v-if="isDefaultCompanyManager(user) || isDefaultCompanyAdmin(user)">
        <router-link
          id="servers-list-side"
          :to="{ name: 'servers' }"
          class="sidebar-item"
          :class="$route.name === 'server-detail' ? 'router-link-active' : ''"
          exact-path
        >
          <div
            id="vms"
            class="sidebar-icon"
          >
            <i class="fas fa-server" />
          </div>
          <div class="sidebar-text">
            {{ $t("menu.vms-sidebar") }}
          </div>
          <b-popover
            v-if="isSidebarMinified"
            :delay="{ show: 500, hide: 50 }"
            boundary="window"
            placement="left"
            target="servers-list-side"
            triggers="hover"
            variant="dark"
          >
            {{ $t("menu.vms-sidebar") }}
          </b-popover>
        </router-link>
      </li>

      <li v-if="isDefaultCompanyManager(user) || isDefaultCompanyAdmin(user)">
        <router-link
          id="network-side"
          :to="{ name: 'network' }"
          class="sidebar-item"
          exact-path
        >
          <div
            id="network"
            class="sidebar-icon"
          >
            <i class="fas fa-network-wired" />
          </div>
          <div class="sidebar-text">
            {{ $t("menu.network") }}
          </div>
          <b-popover
            v-if="isSidebarMinified"
            :delay="{ show: 500, hide: 50 }"
            boundary="window"
            placement="left"
            target="network-side"
            triggers="hover"
            variant="dark"
          >
            {{ $t("menu.network") }}
          </b-popover>
        </router-link>
      </li>

      <li
        v-if="
          user &&
            (isDefaultCompanyAccountant(user) || isDefaultCompanyAdmin(user) || isTitanAccountant(user))
        "
      >
        <router-link
          id="company-side"
          :to="{ name: 'company' }"
          class="sidebar-item"
          exact-path
        >
          <div
            id="company"
            class="sidebar-icon"
          >
            <i class="fas fa-building" />
          </div>
          <div class="sidebar-text">
            {{ $t("menu.company") }}
          </div>
          <b-popover
            v-if="isSidebarMinified"
            :delay="{ show: 500, hide: 50 }"
            boundary="window"
            placement="left"
            target="company-side"
            triggers="hover"
            variant="dark"
          >
            {{ $t("menu.company") }}
          </b-popover>
        </router-link>
      </li>

      <li>
        <router-link
          id="tickets"
          :to="{ name: 'tickets' }"
          class="sidebar-item"
          exact-path
        >
          <div class="sidebar-icon">
            <i class="fas fa-comments" />
            <div
              v-if="ticketPending"
              class="badge bg-primary"
            >
              {{ ticketPending }}
            </div>
          </div>
          <div class="sidebar-text">
            {{ $t("menu.tickets") }}
          </div>
          <b-popover
            v-if="isSidebarMinified"
            :delay="{ show: 500, hide: 50 }"
            boundary="window"
            placement="left"
            target="tickets"
            triggers="hover"
            variant="dark"
          >
            {{ $t("menu.tickets") }}
          </b-popover>
        </router-link>
      </li>

      <!--      <li-->
      <!--        v-if="-->
      <!--          user &&-->
      <!--          (isDefaultCompanyAccountant(user) || isDefaultCompanyAdmin(user))-->
      <!--        "-->
      <!--      >-->
      <!--        <router-link-->
      <!--          id="affiliations"-->
      <!--          :to="{ name: 'affiliations' }"-->
      <!--          class="sidebar-item"-->
      <!--          exact-path-->
      <!--        >-->
      <!--          <div class="sidebar-icon">-->
      <!--            <i class="fas fa-handshake" />-->
      <!--          </div>-->
      <!--          <div class="sidebar-text">-->
      <!--            {{ $t("menu.affiliations") }}-->
      <!--          </div>-->
      <!--          <b-popover-->
      <!--            v-if="isSidebarMinified"-->
      <!--            :delay="{ show: 500, hide: 50 }"-->
      <!--            boundary="window"-->
      <!--            placement="left"-->
      <!--            target="affiliations"-->
      <!--            triggers="hover"-->
      <!--            variant="dark"-->
      <!--          >-->
      <!--            {{ $t("menu.affiliations") }}-->
      <!--          </b-popover>-->
      <!--        </router-link>-->
      <!--      </li>-->

      <li v-if="isDefaultCompanyAdmin(user)">
        <router-link
          id="vms-add"
          :to="{ name: 'orders-creation' }"
          class="sidebar-item"
          exact-path
        >
          <div class="sidebar-icon">
            <i class="fas fa-cart-plus" />
            <div
              v-if="orders && orders.length > 0"
              class="badge bg-primary"
            >
              {{ orders.length }}
            </div>
          </div>
          <div class="sidebar-text">
            {{ $t("menu.vms-add") }}
          </div>
          <b-popover
            v-if="isSidebarMinified"
            :delay="{ show: 500, hide: 50 }"
            boundary="window"
            placement="left"
            target="vms-add"
            triggers="hover"
            variant="dark"
          >
            {{ $t("menu.vms-add") }}
          </b-popover>
        </router-link>
      </li>

      <li
        v-if="
          user && (isTitanAdmin(user) || isTitanSuperAdmin(user) || isTitanManager(user))
        "
      >
        <router-link
          id="admin-new-ticket"
          to="#"
          class="sidebar-item-ticket sidebar-admin-ticket"
          @click.prevent="openTicketCreation"
        >
          <button
            @click.prevent="openTicketCreation"
          >
            <div class="sidebar-icon">
              <i class="fas fa-comment-medical" />
            </div>
          </button>
          <div class="sidebar-text">
            Admin Ticket
          </div>
          <b-popover
            v-if="isSidebarMinified"
            :delay="{ show: 500, hide: 50 }"
            boundary="window"
            placement="left"
            target="admin-new-ticket"
            triggers="hover"
            variant="dark"
          >
            Admin Ticket
          </b-popover>
        </router-link>
      </li>

      <li v-if="user && (isTitanAccountant(user) || isTitanSuperAdmin(user)) || isTitanCommercial(user)">
        <router-link
          id="admin-credit"
          :to="{ name: 'admin-compta' }"
          class="sidebar-item sidebar-admin"
          exact-path
        >
          <div class="sidebar-icon">
            <i class="fas fa-file-invoice" />
          </div>
          <div class="sidebar-text">
            Compta
          </div>
          <b-popover
            v-if="isSidebarMinified"
            :delay="{ show: 500, hide: 50 }"
            boundary="window"
            placement="left"
            target="admin-credit"
            triggers="hover"
            variant="dark"
          >
            Admin Compta
          </b-popover>
        </router-link>
      </li>

      <li
        v-if="
          user && isTitanSuperAdmin(user)
        "
      >
        <router-link
          id="admin-companies"
          :to="{ name: 'admin-companies' }"
          class="sidebar-item sidebar-admin"
          exact-path
        >
          <div class="sidebar-icon">
            <i class="far fa-building" />
          </div>
          <div class="sidebar-text">
            {{ $t("menu.companies") }}
          </div>
          <b-popover
            v-if="isSidebarMinified"
            :delay="{ show: 500, hide: 50 }"
            boundary="window"
            placement="left"
            target="admin-companies"
            triggers="hover"
            variant="dark"
          >
            Admin {{ $t("menu.companies") }}
          </b-popover>
        </router-link>
      </li>

      <li v-if="user && (isTitanAdmin(user) || isTitanManager(user) || isTitanSuperAdmin(user))">
        <router-link
          id="admin-vms"
          :to="{ name: 'super-admin' }"
          class="sidebar-item sidebar-admin"
          exact-path
        >
          <div class="sidebar-icon">
            <i class="far fa-hdd" />
          </div>
          <div class="sidebar-text">
            {{ $t("menu.vms") }}
          </div>
          <b-popover
            v-if="isSidebarMinified"
            :delay="{ show: 500, hide: 50 }"
            boundary="window"
            placement="left"
            target="admin-vms"
            triggers="hover"
            variant="dark"
          >
            Admin {{ $t("menu.vms") }}
          </b-popover>
        </router-link>
      </li>

      <li v-if="user && (isTitanAdmin(user) || isTitanManager(user) || isTitanSuperAdmin(user))">
        <router-link
          id="admin-tools"
          :to="{ name: 'admin-tools' }"
          class="sidebar-item sidebar-admin"
          exact-path
        >
          <div class="sidebar-icon">
            <i class="fas fa-screwdriver-wrench" />
          </div>
          <div class="sidebar-text">
            {{ $t("menu.tools") }}
          </div>
          <b-popover
            v-if="isSidebarMinified"
            :delay="{ show: 500, hide: 50 }"
            boundary="window"
            placement="left"
            target="admin-tools"
            triggers="hover"
            variant="dark"
          >
            Admin {{ $t("menu.tools") }}
          </b-popover>
        </router-link>
      </li>

      <!--      <li v-if="isTitanAccountant(user) && isTitanCommercial(user)">-->
      <!--        <router-link-->
      <!--          id="admin-templates"-->
      <!--          :to="{ name: 'admin-templates' }"-->
      <!--          class="sidebar-item sidebar-admin"-->
      <!--          exact-path-->
      <!--        >-->
      <!--          <div class="sidebar-icon">-->
      <!--            <i class="fas fa-pen-nib" />-->
      <!--          </div>-->
      <!--          <div class="sidebar-text">-->
      <!--            {{ $t("menu.templates") }}-->
      <!--          </div>-->
      <!--          <b-popover
                  v-if="isSidebarMinified"
                  :delay="{ show: 500, hide: 50 }"
                  boundary="window"
                  placement="left"
                  target="admin-vms"
                  triggers="hover"
                  variant="dark"
                >
                  Admin {{ $t("menu.templates") }}
                </b-popover>-->
      <!--        </router-link>-->
      <!--      </li>-->

      <!--
       <li v-if="user && user.administrator" >
          <router-link id="admin-contacts" :to="{ name: 'admin-coupons' }" class="sidebar-item sidebar-admin">
          <div class="sidebar-icon">
            <i class="far fa-address-book"></i>
          </div>
          <div class="sidebar-text">
            Code de reduction
          </div>
          <b-tooltip v-if="isSidebarMinified" target="admin-contacts" triggers="hover" placement="left" boundary="window" variant="dark" :delay="{ show: 500, hide: 50 }" >Admin Coupons</b-tooltip>
        </router-link>
      </li>
      -->
    </ul>

    <VueWinBox
      v-for="serverKvm in serversKvm"
      :key="serverKvm.oid"
      :options="{
        x: 'center',
        y: 'center',
        width: getPIPSize('width') + 'px',
        height: getPIPSize('height') + 'px',
        title: `${serverKvm.name}`,
        id: `${serverKvm.name}`,
      }"
      @close="closeKvm"
    >
      <KVM
        ref="kvm"
        :custom-id="`win-${serverKvm.oid}`"
        :resizable="true"
        :server-oid="serverKvm.oid"
        :websockify="serverKvm.kvm_ip.url"
      />
    </VueWinBox>
    <ModalTicketCreation
      ref="modalTicketCreation"
      admin
      return-to-tickets
      :show-buttons="false"
    />
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import VueWinBox from "vue-winbox";
import KVM from "./all/KVM";
import serverService from "../services/server.service";
import {
  isDefaultCompanyAccountant,
  isDefaultCompanyAdmin,
  isDefaultCompanyManager,
  isTitanAccountant,
  isTitanSuperAdmin,
  isTitanManager,
  isTitanAdmin,
  isTitanCommercial,
  isDefaultCompanyCommercial,
} from "@/services/utils/company";
import {BPopover} from "bootstrap-vue-3";
import ModalTicketCreation from "@/components/shared/ModalTicketCreation.vue";

export default {
  components: {ModalTicketCreation, BPopover, KVM, VueWinBox},
  data() {
    return {
      interval: null,
    };
  },
  computed: {
    ...mapState("app", ["isSidebarMinified"]),
    ...mapState("auth", ["user", "ticketPending"]),
    ...mapState("cart", ["orders"]),
    ...mapState("kvm", ["serversKvm"]),
  },
  mounted() {
    this.loadModules();
    this.refreshTicketPending();
    this.interval = setInterval( () => {
          this.refreshTicketPending();
        },
        process.env.VUE_APP_REFRESH_VERSION || 2 * 60 * 1000
    );
  },
  beforeUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  },
  methods: {
    ...mapActions("app", ["toggleSidebarMinified"]),
    ...mapActions("kvm", ["removeKvm", "reloadKVM"]),
    ...mapActions("auth", ["refreshTicketPending"]),
    isDefaultCompanyAccountant,
    isDefaultCompanyAdmin,
    isDefaultCompanyCommercial,
    isDefaultCompanyManager,
    isTitanCommercial,
    isTitanManager,
    isTitanAccountant,
    isTitanAdmin,
    isTitanSuperAdmin,
    getPIPSize(type, ticket = false) {
      let height =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;
      let width =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      let larger = width > height;
      let maxHeight = 680;
      var originalHeight = 680;
      if (ticket) {
        maxHeight = 500;
      }

      let maxWidth = 800;
      var originalWidth = 800;

      if (type === "height") {
        if (height < maxHeight + 150) {
          if (larger) {
            originalHeight = height - 150;
          } else {
            originalHeight = this.getPIPSize("width") * 0.85;
          }
        } else {
          originalHeight = maxHeight;
        }

        return originalHeight;
      } else {
        if (width < maxWidth + 50) {
          if (!larger) {
            originalWidth = width - 50;
          } else {
            originalWidth = this.getPIPSize("height") / 0.85;
          }
        } else {
          originalWidth = maxWidth;
        }

        return originalWidth;
      }
    },
    onToggleSidebarMinified() {
      this.toggleSidebarMinified();
    },
    async closeKvm(e) {
      let elt = document.getElementById(e.id);
      let content =
        elt.getElementsByClassName("wb-header")[0].lastElementChild.textContent;
      let oid = elt
        .getElementsByClassName("wb-body")[0]
        .firstElementChild.id.replace("win-", "");
      this.removeKvm(content);

      try {
        await serverService.stopIPKVM(oid, "stop");
      } catch (e) {
        console.error(e);
      }
    },
    loadModules() {
      this.reloadKVM();
    },
    openTicketCreation() {
      this.$refs.modalTicketCreation.show();
    },
  },
};
</script>
<style scoped></style>
