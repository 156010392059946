const initialState = {
  serversKvm: []
};

export const kvm = {
  namespaced: true,

  state: initialState,

  actions: {
    reloadKVM(kvm) {
      kvm.commit('RELOAD_KVM');
    },
    
    addKVM(kvm, data) {
      kvm.commit('ADD_KVM', data);
    },

    removeKvm(kvm, data) {
      kvm.commit('REMOVE_KVM', data);
      if(document) {
        let elt = document.getElementById(`win-${data}`)
          if(elt) {
            elt = elt.parentElement.parentElement;
            elt.remove();

          }
          
      }
    },

    resetState(kvm) {
      kvm.commit('RESET_STATE');
    },
  },
  mounted() {
    console.log('mount kvm module')
  },
  mutations: {
    RELOAD_KVM(state) {
      let data = localStorage.getItem("kvmOpenData") ?? '[]'
      const kvmData = JSON.parse(data);
      state.serversKvm= kvmData;
    },
    
    ADD_KVM(state, data) {
      state.serversKvm.push(data);
      localStorage.setItem("kvmOpenData", JSON.stringify(state.serversKvm))
    },

    REMOVE_KVM(state, data) {
      state.serversKvm = state.serversKvm.filter((item) => data !== item.oid);
      state.serversKvm = state.serversKvm.filter((item) => data !== item.name);
      localStorage.setItem("kvmOpenData", JSON.stringify(state.serversKvm))
    },

    RESET_STATE(state) {
      state.serversKvm = [];
      localStorage.setItem("kvmOpenData", JSON.stringify(state.serversKvm))
    },
  }
};

