<template>
  <div>
    <Default v-if="layout === 'default'" />
    <Simple v-if="layout === 'simple'" />
  </div>
</template>

<script>
import Default from "./layouts/Default";
import Simple from "./layouts/Simple";
import { mapState } from "vuex";

export default {
  components: {
    Default,
    Simple
  },
  props: {
    source: {
      type: String,
      default: ""
    }
  },

  data() {
   return {
     mini: null,
     drawer: null,
     drawerRight: null,
     right: false,
     left: false
   }
  },

  computed: {
    ...mapState("auth", ["user"]),
    layout() {
      if (this.$route.path === "/" && !this.user) return "simple";
      return this.$route.meta.layout || "default";
    }
  }
};
</script>

<style lang="sass">
@import './sass/styles.sass'
</style>
