import {common} from '@/settings'
import axios from "axios";

const API_URL = common.api.url + '/param'

class ParamService {
  get(param_name) {
    return axios.get(API_URL + "/" + param_name)
  }
  list(searchParams = undefined) {
    return axios.get(API_URL + (searchParams ? '?' + searchParams.toString() : ''))
  }
  create(payload) {
    return axios.post(API_URL + '/', payload)
  }

  update(id, payload) {
    payload = Object.assign({}, payload)
    delete payload.oid
    return axios.put(API_URL + '/' + id, payload)
  }

  delete(id) {
    return axios.delete(API_URL + '/' + id)
  }
}

export default new ParamService()
