import {  omit} from "lodash-es";


/**
 * originPreference is the user preference on login use to compare if there any update of preferences
 * this action take place in layout/Default.vue
 * */
const initialState = {
  isSidebarOpen: false,
  isSidebarMinified: true,
  colorMode: localStorage.getItem('colorMode') || 'LIGHT',
  show_public_card_confirm_modal: JSON.parse(localStorage.getItem('show_public_card_confirm_modal')) || true,
};

export const app = {
  namespaced: true,

  state: initialState,
  getters: {
    getParameter: (state) => {
      return omit(state, 'isSidebarOpen', 'isSidebarMinified')
    }
  },

  actions: {
    closeSidebar(store) {
      store.commit('CLOSE_SIDEBAR');
    },

    toggleSidebar(store) {
      store.commit('TOGGLE_SIDEBAR');
      //store.getters.getParameter()
    },

    toggleSidebarMinified(store) {
      store.commit('TOGGLE_SIDEBAR_MINIFIED');
    },
    
    setPublicColorMode(store, colorMode) {
      store.commit('SET_PUBLIC_COLOR_MODE', colorMode)
    },
    
  },

  mutations: {
    CLOSE_SIDEBAR(state) {
      state.isSidebarOpen = false;
    },

    TOGGLE_SIDEBAR(state) {
      state.isSidebarOpen = !state.isSidebarOpen;
    },

    TOGGLE_SIDEBAR_MINIFIED(state) {
      state.isSidebarMinified = !state.isSidebarMinified;
    },
    
    SET_PUBLIC_COLOR_MODE(state, colorMode) {
      state.colorMode = colorMode.toUpperCase()
      localStorage.setItem('colorMode', state.colorMode)
    },
  }
};

