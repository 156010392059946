<template>
  <div class="container">
    <div class="card">
      <div class="card-body">
        <h1 class="card-title">
          Politique de confidentialité.
        </h1>

        <p>
          Dans le cadre de son activité commerciale, la Société Titan
          Datacenters France est amenée à traiter des informations vous
          concernant. Par exemple, en naviguant sur nos sites Internet, en
          remplissant un formulaire de souscription à une newsletter, en
          cliquant sur un lien publicitaire que nous vous présentons, vous nous
          transmettez des informations dont certaines sont de nature à vous
          identifier (« données personnelles »).
        </p>

        <p>
          La présente Politique de confidentialité vous informe de la manière
          dont nous recueillons et traitons vos données personnelles.
        </p>

        <h3><u>Important</u></h3>

        <p>
          Nous n’utilisons vos données personnelles que dans les cas prévus par
          la réglementation en vigueur :
        </p>

        <ul>
          <li>
            L’exécution d’un contrat que nous avons conclu avec vous, et/ou
          </li>
          <li>Le respect d’une obligation légale, et/ou</li>
          <li>Votre consentement à l’utilisation de vos données, et/ou</li>
          <li>
            L’existence d’un intérêt légitime à utiliser vos données. L’intérêt
            légitime est un ensemble de raisons commerciales ou d’affaires qui
            justifie l’utilisation de vos données par Titan Datacenters France.
          </li>
        </ul>

        <h4>
          Qui est le responsable des traitements mentionnés dans le présent
          document ?
        </h4>
        <!--h4>QUI EST LE RESPONSABLE DES TRAITEMENTS MENTIONNÉS DANS LE PRÉSENT DOCUMENT ?</h4-->

        <p>
          Le responsable des traitements mentionnés par le présent document est
          la Société Titan Datacenters France, société par actions simplifiée au
          capital de 1 042 907,50 €, immatriculée au registre du commerce et des
          sociétés de Cannes sous le n° B 789 683 190, dont le siège social est
          situé 45 allée des Ormes, 06250 Mougins.
        </p>

        <h4>
          Quelles sont les données que nous recueillons sur vous et dans quelles
          circonstances les collectons-nous ?
        </h4>
        <!--h4>QUELLES SONT LES DONNÉES QUE NOUS RECUEILLONS SUR VOUS ET DANS QUELLES CIRCONSTANCES LES COLLECTONS-NOUS ?</h4-->

        <p><b>Les données que vous nous transmettez directement</b></p>

        <p>
          A l’occasion des différents contacts que nous avons avec vous, vous
          pouvez être amené à nous communiquer des informations qui vous
          concernent. Elles sont collectées notamment lors de l’inscription à
          une newsletter, de la création d’un compte sur nos sites ou pour
          accéder à toute autre offre ou tout autre service.
        </p>

        <p>
          Ces données sont notamment votre nom, prénom, adresse postale, adresse
          e-mail et toute autre information que vous souhaitez porter à notre
          connaissance.
        </p>

        <p>
          <b>Les données que nous recueillons à l’occasion de notre relation
            commerciale</b>
        </p>

        <p>
          Les données que nous recueillons à l’occasion de notre relation
          commerciale avec vous sont notamment les suivantes :
        </p>

        <ul>
          <li>
            Votre participation à des offres promotionnelles, à des jeux
            concours ou à des évènements ;
          </li>
          <li>
            Les demandes que vous avez faites auprès de notre community manager
            ou les incidents que vous nous avez signalés;
          </li>
        </ul>

        <p><b>Les données que nous recueillons automatiquement</b></p>

        <p>
          Lors de chacune de vos visites sur nos sites, nous recueillons des
          informations relatives à votre connexion et à votre navigation.
          Différentes technologies peuvent être mises en œuvre pour recueillir
          ces données. La principale est le cookie.
        </p>

        <ul>
          <li>
            <p><b>Les données recueillies par l’intermédiaire de cookies</b></p>

            <p>
              Un cookie est un fichier texte déposé lors de la consultation d’un
              site, d’une application ou d’une publicité en ligne et stocké dans
              un espace spécifique du disque dur de votre ordinateur ou de votre
              appareil mobile. Les cookies sont gérés par votre navigateur
              Internet et seul l’émetteur d’un cookie peut décider de la lecture
              ou de la modification des informations qui y sont contenues.
            </p>

            <p>
              Les cookies ont plusieurs fonctions, telles que vous permettre de
              naviguer efficacement sur un site ou une application, se souvenir
              de vos choix, des biens et services que vous souhaitez acheter,
              vous proposer des contenus publicitaires pertinents en fonction de
              vos centres d’intérêts exprimés lors de votre navigation.
            </p>

            <p>
              Vous trouverez de plus amples informations sur les cookies sur les
              sites suivants : www.cnil.fr, www.allaboutcookies.org,
              www.aboutcookies.org, www.youronlinechoices.eu.
            </p>
          </li>
          <li>
            <p><b>Données relatives aux mineurs</b></p>

            <p>
              En principe, nos produits et services s’adressent à des personnes
              majeures capables de souscrire des obligations contractuelles.
            </p>

            <p>
              L’utilisateur mineur doit obtenir le consentement de son
              responsable légal préalablement à la communication de données
              personnelles le concernant.
            </p>

            <p>
              Un internaute âgé de moins de 18 ans au moment de la création d’un
              compte en ligne bénéficie d’un droit spécifique à l’effacement des
              données le concernant. Pour toute information, merci de contacter
              le délégué à la protection des données (dpo@titandcfr).
            </p>
          </li>
          <li>
            <p><b>Exclusion de toute donnée sensible</b></p>

            <p>
              Titan Datacenters France ne collecte aucune donnée sensible vous
              concernant.
            </p>

            <p>
              Sont considérées comme des données sensibles : l’origine raciale
              ou ethnique, les opinions politiques, les croyances religieuses ou
              philosophiques, l’adhésion à un syndicat, les données relatives à
              la santé ou l’orientation sexuelle. Si de telles informations nous
              étaient d’une manière ou d’une autre communiquées, elles seront
              supprimées.
            </p>
          </li>
        </ul>

        <!--h4>POUR QUELLES FINALITÉS UTILISONS-NOUS VOS DONNÉES ?</h4-->
        <h4>Pour quelles finalités utilisons-nous vos données ?</h4>

        <p>
          Le présent article vous indique les principales finalités pour
          lesquelles nous utilisons vos données personnelles.
        </p>

        <p>
          <b>Opérations nécessaires à la fourniture de produits ou services</b>
        </p>

        <ul>
          <li>
            Gestion des abonnements à nos newsletters et/ou alertes e-mails ;
          </li>
          <li>
            Relation clients : mise à disposition d’un community manager
            accessible par e-mail pour toute question ou réclamation ;
          </li>
          <li>Réalisation d’enquêtes de satisfaction ;</li>
          <li>
            Envoi d’informations sur la modification ou l’évolution de nos
            services ;
          </li>
          <li>
            Gestion de l’exercice de vos droits sur vos données personnelles ;
          </li>
          <li>
            Vérification du respect de la législation applicable, de nos
            contrats et conditions générales d’utilisation.
          </li>
        </ul>

        <p>
          <b>Opérations de marketing et de prospection commerciale relatives aux
            publications, produits et services de Titan Datacenters France</b>
        </p>

        <ul>
          <li>
            Mise aux normes postales, mise à jour, enrichissement et
            déduplication de vos informations personnelles dans une base de
            données dédiée ;
          </li>
          <li>
            Envoi d’offres marketing, publicitaires et promotionnelles relatives
            à nos produits et services par courrier postal, e-mail, sur les
            réseaux sociaux ou tout autre support ;
          </li>
          <li>
            Actions de fidélisation ou de prospection commerciales
            personnalisées ;
          </li>
          <li>
            Mise en place de jeux concours ou autres opérations promotionnelles
            ;
          </li>
          <li>Recueil des avis clients ;</li>
          <li>Élaboration de statistiques commerciales.</li>
        </ul>

        <!--h4>PENDANT COMBIEN DE TEMPS CONSERVONS-NOUS VOS DONNÉES ?</h4-->
        <h4>Pendant combien de temps conservons-nous vos données ?</h4>

        <p>
          Vos données personnelles sont conservées pendant une durée conforme
          aux dispositions légales ou proportionnelle aux finalités pour
          lesquelles elles ont été enregistrées. Certaines durées de
          conservation répondent à l’intérêt légitime de Titan Datacenters
          France tel que spécifié en introduction.
        </p>

        <p>
          Les durées de conservation varient selon que nous avons une relation
          de nature contractuelle en cours (vous êtes un client actif), que nous
          avons eu avec vous par le passé une relation contractuelle (vous êtes
          un client inactif) ou que nous n’avons jamais eu avec vous de relation
          de ce type (vous êtes alors un prospect).
        </p>

        <!--h4>QUI EST SUSCEPTIBLE D’AVOIR ACCÈS AUX DONNÉES QUE NOUS RECUEILLONS ?</h4-->
        <h4>
          Qui est susceptible d'avoir accès aux données que nous recueillons ?
        </h4>

        <p><b>Accès aux données au sein de Titan Datacenters France</b></p>

        <p>
          Sont susceptibles d’avoir accès à certaines de vos données, les
          collaborateurs des services administratifs, comptabilité et contrôle
          de gestion, informatique, community management et marketing &
          commercial.
        </p>

        <p><b>Transmission de données</b></p>

        <p>
          Sont également susceptibles d’avoir accès à certaines de vos données :
        </p>

        <ul>
          <li>
            <p><b>Les sous-traitants</b></p>
            <p>Ils assurent des prestations pour notre compte.</p>
            <p>
              L’accès de nos sous-traitants à vos données se fait sur la base de
              contrats signés faisant mention des obligations leur incombant en
              matière de protection de la sécurité et de la confidentialité des
              données.
            </p>
          </li>
          <li>
            <p>
              <b>Les autorités de police, autorités judiciaires ou
                administratives</b>
            </p>
            <p>
              Lorsque nous avons l’obligation légale de le faire ou afin de
              garantir les droits, les biens et la sécurité de la société Titan
              Datacenters France.
            </p>
          </li>
        </ul>

        <!--h4>VOS DONNÉES SONT-ELLES TRANSFÉRÉES EN DEHORS DE L’UNION EUROPÉENNE ?</h4-->
        <h4>
          Vos données sont-elles transférées en dehors de l'Union européenne ?
        </h4>

        <p>
          Nous conservons vos données personnelles dans l’Union européenne. Dans
          tous les cas, nous nous assurons de ce que le traitement soit effectué
          conformément à la présente politique de confidentialité et qu’il soit
          encadré par les clauses contractuelles types de la Commission
          européenne qui permettent de garantir un niveau de protection
          suffisant de la vie privée et des droits fondamentaux des personnes.
        </p>

        <!--h4>COMMENT VOS DONNÉES SONT-ELLES PROTÉGÉES ?</h4-->
        <h4>Comment vos données sont-elles protégées ?</h4>

        <p>
          En tant que responsable de traitements, nous mettons en œuvre des
          mesures techniques et organisationnelles appropriées conformément aux
          dispositions légales applicables, pour protéger vos données
          personnelles contre l’altération, la perte accidentelle ou illicite,
          l’utilisation, la divulgation ou l’accès non autorisé, et notamment :
        </p>

        <ul>
          <li>La nomination d’un délégué à la protection des données ;</li>
          <li>
            La sensibilisation aux exigences de confidentialité de nos
            collaborateurs amenés à accéder à vos données personnelles ;
          </li>
          <li>
            La sécurisation de l’accès à nos locaux et à nos plates-formes
            informatiques ;
          </li>
          <li>
            La mise en œuvre d’une politique générale de sécurité informatique
            de l’entreprise ;
          </li>
          <li>
            La sécurisation de l’accès, du partage et du transfert des données ;
          </li>
          <li>
            Le haut degré d’exigence en matière de protection des données lors
            de la sélection de nos sous-traitants et partenaires.
          </li>
        </ul>

        <!--h4>QUELS SONT VOS DROITS ?</h4-->
        <h4>Quels sont vos droits ?</h4>

        <p><b>Vos droits en matière d’accès à vos données personnelles</b></p>

        <p>
          Vous avez le droit d’accéder à vos données personnelles et de demander
          qu’elles soient rectifiées, complétées ou mises à jour. Vous pouvez
          également demander l’effacement de vos données ou vous opposer à leur
          traitement, à condition de justifier d’un motif légitime.
        </p>

        <p>
          Vous pouvez demander à exercer votre droit à la portabilité de vos
          données, c’est-à-dire le droit de recevoir les données personnelles
          que vous nous avez fournies dans un format structuré, couramment
          utilisé et le droit de transmettre ces données à un autre responsable
          de traitements.
        </p>

        <p>
          Vous pouvez enfin formuler des directives relatives à la conservation,
          à l’effacement et à la communication de vos données à caractère
          personnel après votre décès.
        </p>

        <p>
          Vous pouvez exercer vos droits auprès de la déléguée à la protection
          des données personnelles de Titan Datacenters France: 45 allée des
          Ormes 06250 Mougins ou par email : dpo@titandc.fr.
        </p>

        <p>
          Avant de répondre à votre demande, nous sommes susceptibles de
          vérifier votre identité et/ou vous demander de nous fournir davantage
          d’informations pour répondre à votre demande. Nous nous efforcerons de
          donner suite à votre demande dans un délai raisonnable et, en tout
          état de cause, dans les délais fixés par la loi.
        </p>

        <p>
          En cas de réponse insatisfaisante, vous pouvez introduire une
          réclamation auprès de la Commission nationale de l’informatique et des
          libertés (CNIL).
        </p>

        <p>
          <b>Vos droits en matière d’opposition aux sollicitations
            commerciales</b>
        </p>

        <p>
          Nous sommes susceptibles d’utiliser vos coordonnées dans le cadre de
          sollicitations commerciales, en conformité avec la loi applicable et
          les clauses de la présente politique de confidentialité. Vous pouvez à
          tout moment vous opposer à ces sollicitations, selon des modalités qui
          dépendent du canal de communication que nous utilisons pour vous les
          adresser.
        </p>

        <ul>
          <li>
            <p>
              <b>Sollicitations commerciales par voie électronique (e-mail) :</b>
            </p>

            <p>
              Nous respectons les règles de l’article L.34-5 du code des postes
              et des communications électroniques qui prévoit le recueil
              préalable et exprès de votre consentement pour l’envoi de
              prospection commerciale par voie électronique (e-mail, push
              notifications ou SMS).
            </p>

            <p>
              Cependant, votre consentement exprès et préalable n’est pas
              obligatoire lorsque vous êtes déjà client de Titan Datacenters
              France et que l’objet de notre sollicitation est de vous proposer
              des produits ou services analogues à ceux que nous vous
              fournissons déjà.
            </p>

            <p>
              Dans tous les cas, vous avez toujours la possibilité de vous
              opposer à la réception de ces sollicitations en cliquant sur le
              lien de désabonnement prévu dans chaque e-mail.
            </p>
          </li>
        </ul>

        <!--h4>MODIFICATION DE NOTRE POLITIQUE DE CONFIDENTIALITE</h4-->
        <h4>Modification de notre politique de confidentialité</h4>

        <p>
          Nous pouvons être amené à modifier occasionnellement la présente
          politique de confidentialité. Lorsque cela est nécessaire, nous vous
          en informerons et/ou solliciterons votre accord. Nous vous conseillons
          de consulter régulièrement cette page pour prendre connaissance des
          éventuelles modifications ou mises à jour apportées à notre politique
          de confidentialité.
        </p>

        <!--h4>CONTACT</h4-->
        <h4>Contact</h4>

        <p>
          Pour toute question relative à la présente politique de
          confidentialité ou pour toute demande relative à vos données
          personnelles, vous pouvez nous contacter en :
        </p>

        <ul>
          <li>
            adressant un email à notre délégué à la protection des données à
            dpo@titandc.fr
          </li>
          <li>
            <p>ou en nous adressant un courrier à l’adresse suivante :</p>
            <p>
              <i>
                Titan Datacenters France<br>
                A l’attention du Délégué à la Protection des Données<br>
                45 allée des Ormes<br>
                06250 Mougins
              </i>
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
