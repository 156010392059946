<template>
  <div
    v-b-popover.hover.top.html="$t('server.states.' + status)"
    :class="computeRowClass(status)"
    class="dot"
  />
</template>

<script>
import serverService from "../../services/server.service";
import {vBPopover} from "bootstrap-vue-3";

export default {
  directives: {
    "b-popover": vBPopover
  },
  props: {
    status: {
      type: String,
      default: "",
    },
  },
  methods: {
    computeRowClass(state) {
      return { ["dot-" + serverService.getGlobalStateFromState(state)]: true };
    },
  },
};
</script>

<style></style>
