import {common} from '@/settings';
import axios from "axios";
//import {afterWrite} from "@popperjs/core";

const API_URL = common.api.url
const API_URL_STORAGE = common.api.url + '/storage'
const API_URL_IMAGE = API_URL_STORAGE + '/image'
const API_URL_COMPANY = common.api.url + '/company'
const API_URL_COMPANY_IP = common.api.site_specific_url + '/company'
const API_URL_AFFILIATE = common.api.url + '/affiliate'
const API_URL_INVITATION = common.api.url + '/invitation'
const API_URL_DISCOUNT = common.api.url + '/discount'

class CompanyService {

  getPoolStat(company_oid = null) {
    return axios.get(API_URL_STORAGE + '/pool/' + company_oid)
  }
  
  decreaseStorageItem(company_oid, payload) {
    return axios.delete(API_URL_STORAGE + '/pool/' + company_oid,{data: payload})
  }
  events(company_oid, payload) {
    return axios.get(API_URL_COMPANY + '/' +company_oid+ '/events', {params: payload})
  }

  eventsCsv(company_oid, payload) {
    return axios.get(API_URL_COMPANY + '/' +company_oid+ '/events/csv', {params: payload})
  }

  getImageTemplate(imageOID) {
    return axios.get(API_URL_IMAGE + imageOID)
  }

  putImageTemplate(imageOID, payload) {
    return axios.put(API_URL_IMAGE + '/' + imageOID, payload)
  }

  deleteImageTemplate(imageOID) {
    return axios.delete(API_URL_IMAGE + '/' + imageOID)
  }
  
  detail(company_id) {
    return axios.get(API_URL_COMPANY + '/' + company_id)
  }

  update(company_id, company) {
    return axios.put(API_URL_COMPANY + '/' + company_id, company)
  }

  list(searchParams = undefined) {
    return axios.get(API_URL_COMPANY + (searchParams ? '?' + searchParams.toString() : ''))
  }

  search(searchParams = undefined) {
    return axios.get(API_URL_COMPANY + "/search" + (searchParams ? '?' + searchParams.toString() : ''))
  }

  refreshValidityVAT(vat) {
    return axios.get(API_URL_COMPANY + '/validate_vat/' + vat)
  }

  getRoles(company_id) {
    return axios.get(API_URL_COMPANY + '/' + company_id + '/roles')
  }

  getAuthorizations(company_id) {
    return axios.get(API_URL_COMPANY + '/' + company_id + '/authorizations')
  }

  getCsv(searchParams = undefined) {
    return axios.get(API_URL_COMPANY + "/export" + (searchParams ? '?' + searchParams.toString() : ''))
  }

  deleteAvatar(avatar_id) {
    return axios.delete(API_URL + '/avatar/' + avatar_id)
  }
  updateAvatar(avatar_id) {
    return axios.put(API_URL + '/avatar/' + avatar_id)
  }
  addAvatar(payload) {
    return axios.post(API_URL + '/avatar', payload)
  }
  
  listInvitations(searchParams = undefined) {
    return axios.get(API_URL_INVITATION + (searchParams ? '?' + searchParams.toString() : ''))
  }

  deleteInvitation(invitation_id) {
    return axios.delete(API_URL_INVITATION + '/' + invitation_id)
  }

  updateUserRole(company_id, user_id, role_payload) {
    return axios.put(API_URL_COMPANY + '/' + company_id + '/user/' + user_id, role_payload )
  }

  async create() {
    return await axios.post(API_URL_COMPANY + '/')
  }

  async disable(company_id) {
    return await axios.post(API_URL_COMPANY + '/' + company_id + '/disable')
  }

  async delete(company_id) {
    return await axios.delete(API_URL_COMPANY + '/' + company_id)
  }

  async updateAddress(company_id, address) {
    return await axios.put(API_URL_COMPANY + '/' + company_id + '/addresses', address)
  }

  async inviteUser(company_id, {authorization, email, position, role}) {
    return await axios.post(API_URL_COMPANY + '/' + company_id + '/invite', {authorization, email, position, role})
  }

  async revokeUser(company_id, {email, authorization, role}) {
    return await axios.delete(API_URL_COMPANY + '/' + company_id + '/revoke', {data: {email, authorization, role}})
  }

  // async registerOnInvite(company_id, user) {
  //   return await axios.post(API_URL_COMPANY + '/' + company_id + '/register', user)
  // }

  async getAvailableIps(searchParams = undefined) {
    return await axios.get(API_URL_COMPANY_IP + '/ips'+ (searchParams ? '?' + searchParams.toString() : ''))
  }

  async getBuyableIps() {
    return await axios.get(common.api.url + '/compute/ips')
  }

  async assignCommercial(company_id, commercial_id) {
    return await axios.put(API_URL_COMPANY + '/' + company_id + '/commercial', {
      uuid: commercial_id
    })
  }

  async getResourceLimitations(company_id) {
    const result = await axios.get(API_URL_COMPANY + '/' + company_id + '/resource_limitations')
    // Handle super admin queries ('quotas' appears aside of 'limitations'
    if (result.limitations) {
      return result.limitations
    }
    return result
  }

  // Programs

  async assignProgram(company_id, program_id) {
    return await axios.post(API_URL_AFFILIATE + '/' + company_id, {
      program_uuid: program_id
    })
  }

  // Roles


  async createRole(company_id, role) {
    role = this.prepareRole(role)
    return await axios.post(API_URL_COMPANY + '/' + company_id + '/role', role)
  }

  async updateRole(company_id, role_id, role) {
    role = this.prepareRole(role)
    return await axios.put(API_URL_COMPANY + '/' + company_id + '/role/' + role_id, role)
  }

  async removeRole(company_id, role_id) {
    return await axios.delete(API_URL_COMPANY + '/' + company_id + '/role/' + role_id)
  }

  getQuotas(company_oid) {
    return axios.get(API_URL_COMPANY + '/' + company_oid + '/quota')
  }

  updateQuotas(company_oid, quotas) {
    return axios.put(API_URL_COMPANY + '/' + company_oid + '/quota', quotas)
  }

  prepareRole(role) {
    role.server_ids = role.servers ? role.servers.map((item) => item.oid) : []
    role.network_ids = role.networks ? role.networks.map((item) => item.oid) : []
    delete role.servers
    delete role.networks
    return role
  }

  updateAllCompanyBalance() {
    return axios.put(API_URL_COMPANY + '/balance')
  }

  updateComapnyBalance(company_id) {
    return axios.put(API_URL_COMPANY + '/' + company_id + '/balance')
  }

  getCompanyDiscountValue(company_id) {
    return axios.get(API_URL_DISCOUNT + '/' + company_id + "/value")
  }

  transfer(payload) {
    return axios.post(API_URL_COMPANY + '/transfer', payload)
  }
  
}

export default new CompanyService()
