import {common} from '@/settings'
import axios from "axios";
import {getSearchParams} from "@/services/utils/searchBuilder";

const API_URL = common.api.url
const API_SUBSCRIPTIONS = "/subscription"
const API_INVOICER = "/invoicer"
const API_DEPLOY = "/deploy"

class InvoiceService {
  listRecurring(company_oid) {
    return axios.get(API_URL + API_SUBSCRIPTIONS + "/projects?company_oid=" + encodeURIComponent(company_oid))
  }

  listAllRecurring(searchParams) {
    return axios.get(API_URL + API_SUBSCRIPTIONS + (searchParams ? '?' + searchParams.toString() : ''))
  }

  updateRecurring(uuid, data) {
    return axios.put(API_URL + API_SUBSCRIPTIONS + "/" + uuid, data)
  }

  updateRecurringPaymentMethod(uuid, data) {
    return axios.put(API_URL + API_SUBSCRIPTIONS + "/" + uuid + "/payment-method", data)
  }

  createRecurring(payload) {
    return axios.post(API_URL + API_SUBSCRIPTIONS + "/create", payload)
  }

  getRecurring(id) {
    return axios.get(API_URL + API_SUBSCRIPTIONS + "/" + id)
  }

  generateInvoice(payload) {
    return axios.post(API_URL + API_SUBSCRIPTIONS + "/generate-invoice", payload)
  }

  createInvoice(oid, payload) {
    return axios.post(API_URL + API_SUBSCRIPTIONS + "/" + oid + "/generate-invoice", payload)
  }

  InvoiceDetailsDoc(doc) {
    return axios.get(API_URL + API_INVOICER + "/detail/" + doc)
  }

  InvoiceDetails(id) {
    return axios.get(API_URL + API_INVOICER + "/" + id)
  }

  InvoiceDetailsCredit(id) {
    return axios.get(API_URL + API_INVOICER + "/remaining_infos/" + id)
  }

  getNextInvoice(uuid) {
    return axios.get(API_URL + API_SUBSCRIPTIONS + "/" + uuid + "/next",{responseType: 'blob'})
  }

  getPdfUrl(id) {
    return API_URL + API_INVOICER + "/" + id + "/pdf"
  }
  getPdf(id) {
    return axios.get(this.getPdfUrl(id), {responseType: 'blob'})
  }

  getZip(searchParams) {
    return axios.get(API_URL + API_INVOICER + "/zip" + (searchParams ? '?' + searchParams.toString() : ''), {responseType: 'blob'})
  }

  getCsv(searchParams) {
    return axios.get(API_URL + API_INVOICER + "/accounting-list" + (searchParams ? '?' + searchParams.toString() : ''))
  }

  getDocuments(searchParams) {
    return axios.get(API_URL + API_INVOICER + "/full_price" + (searchParams ? '?' + searchParams.toString() : ''))
  }

  generateCredit(payload) {
    return axios.post(API_URL + API_INVOICER + "/generate-credit", payload)
  }

  getRemainingInvoice(id) {
    return axios.get(API_URL + API_INVOICER + "/remaining_invoice/" + id)
  }

  getRemainingAmount(searchParams) {
    return axios.get(API_URL + API_INVOICER + "/remaining_amount" + (searchParams ? '?' + searchParams.toString() : ''))
  }

  clearInvoiceOrCredit(id, payload) {
    return axios.post(API_URL + API_INVOICER + "/" + id + "/clear", payload)
  }

  forceDeploy(id) {
    return axios.post(API_URL + API_DEPLOY + "/" + id)
  }

  async popDocumentDownloadOld(documentDetails) {
    let result = await this.getPdf(documentDetails.oid)

    const blob = new Blob([result.data], {type: 'application/pdf'})
    const fileURL = window.URL.createObjectURL(blob)
    const fileLink = document.createElement('a')
    fileLink.href = fileURL
    fileLink.setAttribute('download', documentDetails.document_number + '.pdf')
    fileLink.click()
  }

  deleteOrder(id) {
    return axios.delete(API_URL + API_INVOICER + "/order/" + id)
  }

  confirmOrder(id) {
    return axios.put(API_URL + API_INVOICER + "/" + id + "/confirm")
  }

  async popDownloadMulti(companyOid, list) {
    const param = {company_oid: companyOid, oid: list}
    let result = await this.getZip(getSearchParams(param))

    const blob = new Blob([result.data], {type: 'application/zip'})
    const fileURL = window.URL.createObjectURL(blob)
    const fileLink = document.createElement('a')
    fileLink.href = fileURL
    fileLink.setAttribute('download', 'archive.zip')
    fileLink.click()
  }

  async popDocumentDownload(documentDetails) {
    try {
      let result = await this.getPdf(documentDetails.oid);

      const blob = new Blob([result.data], { type: 'application/pdf' });

      // Create a temporary link and set the download attribute
      const tempLink = document.createElement('a');
      tempLink.href = URL.createObjectURL(blob);
      tempLink.setAttribute('download', documentDetails.document_number + '.pdf');

      // Programmatically trigger the download
      tempLink.click();

      // Clean up the URL object and the temporary link
      URL.revokeObjectURL(tempLink.href);
      tempLink.remove();
    } catch (error) {
      console.error('Error occurred during document download:', error);
    }
  }
}

export default new InvoiceService()
