<template>
  <div class="container-fluid">
    <div class="row align-items-center">
      <div class="col-lg-8 layout-fullheight">
        <div class="login">
          <div class="login-header">
            <ModalHeader />

            <h1>{{ $t("pages.login.title") }}</h1>

            <div class="langs">
              <div
                v-for="lang in langs"
                :key="lang.code"
                :class="{ 'lang-active': isLangSelected(lang.code) }"
                :value="lang.code"
                class="lang"
                @click="onSelectLang(lang.code)"
              >
                <img
                  :alt="lang.name"
                  :src="require('../../assets/img/flags/' + lang.code + '.svg')"
                  :title="lang.name"
                >
              </div>
            </div>

            <Alert
              :delay="5000"
              inline
              :show="message_success"
              success
              @closed="message_success = null"
            >
              {{ message_success }}
            </Alert>
            <Alert
              :delay="5000"
              inline
              :show="message_error"
              danger
              @closed="message_error = null"
            >
              {{ message_error }}
            </Alert>
          </div>

          <form
            id="login-form"
            @submit.prevent="onSubmit"
          >
            <div class="mb-3 form-required">
              <label for="input-email-login">
                {{ $t("pages.login.email-label") }}
              </label>
              <div>
                <input
                  id="input-email-login"
                  ref="email"
                  v-model.trim="email"
                  class="form-control"
                  minlength="6"
                  placeholder="Email"
                  required
                  type="email"
                >
              </div>
            </div>

            <div class="mb-3 form-required">
              <label for="input-pwd-login">
                {{ $t("pages.login.password-label") }}
              </label>
              <div class="form-password">
                <input
                  id="input-pwd-login"
                  v-model="password"
                  :type="passwordType"
                  autocomplete="current-password"
                  class="form-control"
                  maxlength="64"
                  minlength="8"
                  placeholder="Mot de passe"
                  required
                >
                <button
                  id="btn-login"
                  type="button"
                  @click.prevent="onSwitchPasswordType"
                >
                  <i
                    v-if="passwordType === 'password'"
                    class="fas fa-eye"
                  />
                  <i
                    v-if="passwordType === 'text'"
                    class="fas fa-eye-slash"
                  />
                </button>
              </div>
            </div>

            <div>
              <button
                :disabled="!loginBtnEnabled"
                class="btn btn-fat d-block w-100 btn-primary btn-shadow"
                data-cy="submit"
                type="submit"
              >
                {{ $t("pages.login.login-action") }}
              </button>
            </div>

            <div class="text-center">
              <div class="my-3">
                <router-link :to="'/forget-password?email=' + email">
                  {{ $t("pages.forget-password.title") }}
                </router-link>
              </div>

              <div class="my-4">
                <router-link
                  :to="{ name: 'register' }"
                  class="scope-button"
                >
                  {{ $t("pages.register.action") }}
                </router-link>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="d-none d-lg-block col-lg-4 login-bar" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Alert from "../../components/shared/Alert";
import authService from "../../services/auth.service";
import { settings } from "@/settings";
import localStorageService from "../../services/localStorage.service";
import { Base64 } from "js-base64";
import ModalHeader from "../../components/shared/ModalHeader";
import {hasSignedCGV} from "@/services/utils/validator";

export default {
  name: "Login",
  components: {
    ModalHeader,
    Alert,
  },
  data() {
    return {
      langs: settings.langs,
      message_error: null,
      message_success: null,
      email: "",
      password: "",
      passwordType: "password",
      loginInProgress: false,
    };
  },
  computed: {
    ...mapState("auth", ["user","currentCGV"]),
    ...mapState("cart", ["orders"]),
    loginBtnEnabled() {
      return (
        this.email &&
        this.email !== "" &&
        this.password &&
        this.password !== "" &&
        !this.loginInProgress
      );
    },
  },
  mounted() {
    setTimeout(() => {
      this.$refs.email.focus();
    }, 100);
    authService.clearLocalStorage();
    if (this.$route.query.promoCode) {
      localStorageService.saveDiscountCode(this.$route.query.promoCode);
      this.message_success = this.$t("pages.login.promotional_code_saved");
    }
    if (this.$route.query.success_code) {
      this.message_success = this.$t(
        "errors." + this.$route.query.success_code
      );
    }
    if (this.$route.query.error_code) {
      this.message_error = this.$route.query.error_code;
    }
  },
  methods: {
    ...mapActions("auth", ["login"]),
    ...mapActions("cart", ["setOrders"]),
    isLangSelected(lang) {
      return this.$root.$i18n.locale === lang;
    },
    onSelectLang(lang) {
      this.$root.$i18n.locale = lang;
    },
    async onSubmit() {
      this.loginInProgress = true;
      this.passwordType = "password";

      const response = await this.login({
        email: this.email,
        password: Base64.encode(this.password),
        redirect: this.$route.query.redirect ? this.$route.query.redirect : "",
      });

      if (response.error === "2fa_needed") {
        if (this.$route.query.redirect !== "") {
          await this.$router.push({
            path: "/2fa",
            query: { redirect: this.$route.query.redirect },
          });
        } else {
          await this.$router.push({ path: "/2fa" });
        }
      }
      this.message_error = response.error;
      
      if (response.user.preference) {
        // load user data
        localStorage.setItem('preference', JSON.stringify(response.user.preference))
        if (response.user.preference.preferred_language) {
          this.onSelectLang(response.user.preference.preferred_language)
        }
      }

      // Try fix login disabled
      this.loginInProgress = false;
      if (response.user) {
        if (this.$route.query.redirect) {
          if (this.$route.query.promoCode) {
            return this.$router.push({
              path: this.$route.query.redirect,
              query: {
                promoCode: this.$route.query.promoCode,
              },
            });
          }
          return this.$router.push(this.$route.query.redirect);
        }

        let cgvSigned = await hasSignedCGV(response.user, this.currentCGV)
        if (!cgvSigned) {
          return this.$router.push({ name: "cgv" });
        }
        await this.$router.push({ name: "dashboard" });
        return "";
      }
      /*
      if (
        user.preference &&
        user.preference.tour &&
        user.preference.tour.global
      ) {
        return this.$router.push({ name: "dashboard" });
      } else {
        return this.$router.push({ name: "orders-creation" });
      }
*/

      /*  //ERROR...
      if (error.response && error.response.status === 405) {
        // => E-mail not validated
        this.$router.push({
          name: "email-validation",
          query: {
            message_error: this.$t("pages.login.email-not-validated"),
            email: this.email
          }
        });
        this.loginInProgress = false;
        return;
      }
      this.loginInProgress = false;
      this.message_error = translateError(error);*/
    },
    onSwitchPasswordType() {
      this.passwordType =
        this.passwordType === "password" ? "text" : "password";
    },
  },
};
</script>

<style lang="sass" scoped>
@import '../../sass/vendors/bootstrap.vars'

.langs
  margin: -10px 0 30px
  display: block

.lang
  width: 16px
  margin: 0 5px
  display: inline-block
  opacity: .5
  cursor: pointer

.lang-active
  opacity: 1

.scope-button
  background: white
  border: 1px solid $primary
  padding: 10px 30px
  border-radius: 30px
  color: darken($primary, 2) !important
  text-decoration: none
</style>
