<template>
  <div class="layout-wrapper">
    <div
      :class="{
        'sidebar-is-minified': isSidebarMinified,
        'sidebar-is-open': isSidebarOpen,
      }"
      class="layout"
    >
      <Sidebar />

      <div class="layout-main">
        <HeaderComponent />
        <div
          id="main-layout"
          class="layout-body"
        >
          <router-view
            v-slot="{ Component }"
          >
            <transition
              name="route"
              mode="out-in"
            >
                <component :is="Component" />
            </transition>
          </router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderComponent from "../components/HeaderComponent";
import Sidebar from "../components/Sidebar";
import { mapState, mapActions } from "vuex";
import {tryCatch} from "@/components/shared/util";
import paramService from "@/services/param.service";
import {common} from "@/settings";

export default {
  components: {
    HeaderComponent,
    Sidebar,
  },
  data() {
    return {
      refresh_interval: null,
    };
  },
  computed: {
    ...mapState("app", [
      "isSidebarMinified",
      "isSidebarOpen",
    ]),
    ...mapState("auth", ["user"]),
    colorMode() {
      if (this.user && this.user.preference) {
        return this.user.preference.color_mode.toUpperCase();
      }
      return "LIGHT";
    },
  },
  async mounted() {
    await this.getUserPref();
    await this.initDefaultParam();
    
    //need to update this to make it more reliable
    if (document.getElementsByClassName("layout-wrapper")) {
      //update Unseen ticketsNumber
    
      const element = document.getElementsByTagName("body")[0];
      element.classList.toggle("dark", this.colorMode === "DARK");
    }
  },
  beforeUnmount() {
    this.stopRefresh();
  },
  methods: {
    // ...mapActions("auth", ["updateUnseenTickets"]),
    ...mapActions("auth", ["getUserPref"]),
    
    stopRefresh() {
      if (this.refresh_interval) {
        clearInterval(this.refresh_interval);
        this.refresh_interval = null;
      }
    },
    async initDefaultParam() {
      await this.getMultiGraph();
      await this.getMigrationDate();
    },
    async getMultiGraph() {
      let result = await tryCatch(() =>
        paramService.get("multi_graph")
      );
      if (result.isSuccess && result.response) {
        common.multi_graph = result.response.num_value === 1;
      }
    },
    async getMigrationDate() {
      let result = await tryCatch(() =>
        paramService.get("migration_date")
      );
      if (result.isSuccess && result.response && result.response.date_value) {
        common.migrationDate = new Date(result.response.date_value).getTime();
      }
    },
  },
};
</script>

<style>
.route-enter-from {
  opacity: 0;
  transform: translateY(100px);
}

.route-enter-active {
  transition: all 0.3s ease-out;
}

.route-leave-to {
  opacity: 0;
  transform: translateY(-100px);
}

.route-leave-active {
  transition: all 0.3s ease-in;
}

</style>
