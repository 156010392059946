<template>
  <b-modal
    id="interventions-modal"
    v-model="interventionModal"
    size="lg"
    centered
    hide-header-close
    @close="close()"
  >
    <!-- Header -->

    <ModalHeader/>

    <Alert
      :delay="5000"
      :show="message_error"
      danger
      @closed="message_error = null"
    >
      {{ message_error }}
    </Alert>

    <!-- Interventions  -->

    <div class="card">
      <div class="list-group list-scroll my-3">
        <a
          v-for="(intervention, index) in interventions"
          :id="'intervention-' + index"
          :key="intervention.oid"
          :class="{ active: isSelected(intervention) }"
          class="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
          @click.prevent.stop="select(intervention)"
        >
          <div
            class="intervention-name custom-control custom-checkbox d-inline-flex"
          >
            <b-form-checkbox
              :id="'choice-' + index"
              v-model="intervention.selected"
              :value="intervention"
              class="custom-control-input me-1"
              name="choice"
              type="checkbox"
              @click.stop
            />

            <label
              :for="'choice-' + index"
              class="custom-control-label"
            >
              {{ intervention.description }}
            </label>
          </div>

          <div class="intervention-quantity">
            <InputLimited
              :id="'intervention-quantity-' + index"
              v-model.number="intervention.quantity"
              custom-class="scope-quantity form-control"
              :max="quantity_max"
              min="1"
              type="number"
              @click.stop
            />
          </div>

          <div class="intervention-price">
          <span
            :class="{
              'bg-primary': isSelected(intervention),
              'bg-secondary': !isSelected(intervention),
            }"
            class="badge rounded-pill"
          >
            {{
              $filters.roundForPriceLocal(
                intervention.quantity * intervention.price_unit_ht, true
              )
            }}
            €
          </span>
          </div>
        </a>
      </div>
    </div>

    <!-- Footer -->

    <template #footer>
      <div class="modal-buttons">
        <button
          id="intervention-btn-cancel"
          class="mb-4 btn-dialog-cancel btn-cancel"
          @click="close()"
        >
          {{ $t("actions.cancel") }}
        </button>

        <button
          id="intervention-btn-confirm"
          :disabled="!hasUpdate"
          class="mb-4 btn-dialog-confirm btn-validate"
          @click="confirm()"
        >
          {{ $t("actions.confirm") }}
        </button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import Alert from "../shared/Alert";
import {tryCatch} from "../shared/util";
import ModalHeader from "../shared/ModalHeader";
import {BFormCheckbox} from "bootstrap-vue-3";
import BModal from "@/components/shared/BModal";
import itemService from "@/services/item.service";
import cartService from "@/services/cart.service";
import {mapActions, mapState} from "vuex";
import InputLimited from "@/components/shared/InputLimited";
import {settings} from "@/settings";
import {getSearchParams} from "@/services/utils/searchBuilder";
import {getPackList} from "@/services/utils/item";

export default {
  components: {
    InputLimited,
    BFormCheckbox,
    BModal,
    ModalHeader,
    Alert,
  },

  props: {
    id: {
      type: String,
      default: "",
    },
    itemType: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      interventionModal: false,
      interventions: [],
      // selected: [],
      message_error: null,
      quantity_max: settings.quantity_max,
      gotoCart: false,
      pack: 0,
    };
  },

  computed: {
    ...mapState("auth", ["user"]),
    ...mapState("cart", ["orders"]),
    selected() {
      return this.interventions
        .filter((item) => item.selected)
        .map((item) => {
          return {
            oid: item.oid,
            quantity: item.quantity,
          };
        });
    },
    hasUpdate() {
      return (
        this.interventions.filter(
          (item) =>
            (item.selected && !item.alreadyInCart) ||
            (item.selected && item.alreadyInCart && item.quantity !== item.alreadyInCart.quantity) ||
            (!item.selected && item.alreadyInCart)
        ).length > 0
      );
    },
  },

  mounted() {
  },

  methods: {
    ...mapActions("cart", ["reloadOrder"]),
    async show(pack = 0) {
      this.pack = pack;
      let result = await this.getItems();
      if (result.isSuccess) await this.reloadOrder();
      this.prepareItems(result);

      if (this.gotoCart && this.itemType !== "") {
        if (location.pathname !== "/orders/cart") {
          setTimeout(() => this.$router.push("/orders/cart"), 300)
        }
      } else {
        if (this.interventions && this.interventions.length > 0) {
          this.interventionModal = true;
        }
      }
    },
    
    async getItems() {
      let result = null;
      const adminQuoteMode = JSON.parse(
        localStorage.getItem("adminQuoteMode") ?? "false"
      );
      if (this.itemType !== "") {
        result = await this.getItemByType(this.itemType);
      } else {
        if (adminQuoteMode) {
          result = await this.getItemsForAdmin();
        } else {
          result = await this.getInterventionList();
        }
      }
      return result
    },
    
    async getItemByType(itemType) {
      let result = "";
      if (this.itemType !== "") {
        result = await tryCatch(() =>
          itemService.getItemList(getSearchParams({type: itemType}))
        );
      }
      return result
    },
    async getItemsForAdmin() {
      return await tryCatch(() =>
        itemService.getItemList(getSearchParams({types: ["SERVICE", "LICENSE"], enable: true}))
      );
    },
    async getInterventionList() {
      let result = "";
      if (this.pack !== 0) {
        result = await getPackList(this.pack);
      } else {
        result = await tryCatch(() => itemService.listInterventions());
      }
      return result;
    },
    
    prepareItems(result) {
      let itemList = this.sortItemList(result.response)
      this.interventions = itemList.map((item) => {
        // console.log(item.oid)
        // console.log(this.orders)
        item.alreadyInCart = cartService.getOrderItem(this.orders, item.oid);
        this.gotoCart = !!item.alreadyInCart;

        // console.log(item_in_cart)

        item.quantity = item.alreadyInCart ? item.alreadyInCart.quantity : 1;
        item.selected = item.alreadyInCart !== null;
        return item;
      });
    },

    sortItemList(list) {
      // Using the Array.sort() method and specifying the sorting criteria
      return list.sort((a, b) => {
        // Function to pad 'price_unit_ht' with leading zeros
        const padZeros = (value, length) => {
          return (value.toString().length < length) ? padZeros("0" + value, length) : value;
        };

        // Get 'type', first word of 'name', and padded 'price_unit_ht' for comparison
        const paddedPriceA = padZeros((a.price_unit_ht && a.price_unit_ht) || "0", 10); // Assuming a fixed length of 10
        const paddedPriceB = padZeros((b.price_unit_ht && b.price_unit_ht) || "0", 10); // Assuming a fixed length of 10

        const concatA = a.type + (a.name.split(' ')[0] || '') + paddedPriceA;
        const concatB = b.type + (b.name.split(' ')[0] || '') + paddedPriceB;

        // Compare the concatenated values
        if (concatA < concatB) return -1;
        if (concatA > concatB) return 1;

        // If the concatenated values are also the same, maintain the order
        return 0;
      });
    },
    
    completeCompany() {
      this.$router.push({name: "company", query: {complete: true}});
      this.close();
    },

    async confirm() {
      for (const item1 of this.interventions.filter((item) => item.selected)) {
        if (item1.alreadyInCart === null) {
          await tryCatch(() =>
            cartService.createItem({
              cart_oid: this.user.oid,
              item_oid: item1.oid,
              quantity: item1.quantity,
            })
          );
        } else {
          //console.log("already in cart");
          await tryCatch(() =>
            cartService.updateItem(item1.alreadyInCart.cart_item_oid, {
              cart_oid: this.user.oid,
              item_oid: item1.oid,
              quantity: item1.quantity,
            })
          );
        }
      }

      for (const item1 of this.interventions.filter(
        (item) => !item.selected && item.alreadyInCart !== null
      )) {
        //console.log(item1)
        await tryCatch(() =>
          cartService.deleteCartItem({
            item_oid: item1.alreadyInCart.cart_item_oid,
          })
        );
      }

      this.close();
      // if (this.pack === 0) {
        if (location.pathname !== "/orders/cart") {
          setTimeout(() => this.$router.push("/orders/cart"), 300)
        } else {
          const finalCaddie = document.getElementById('finalCaddie');
          if (finalCaddie) finalCaddie.click();
        }
      // } else {
      //   createToast(this.$t("pages.company.item-added"), {
      //     type: "success",
      //     showIcon: true,
      //     position: "bottom-right",
      //   });
      //   await this.reloadOrder();
      //   this.close();
      // }
    },

    close() {
      this.interventionModal = false;
    },

    select(intervention) {
      intervention.selected = !this.isSelected(intervention);
    },

    isSelected(intervention) {
      const selectedIds = this.selected.map((item) => item.oid);
      return selectedIds.indexOf(intervention.oid) > -1;
    },

    isValid() {
      return this.selected.every((item) => {
        return item.quantity > 0;
      });
    },
  },
};
</script>

<style lang="sass">
</style>

<style lang="sass" scoped>
@import '../../sass/vendors/bootstrap.vars.scss'

.dark .list-group-item-action.active .custom-control-label
  color: $primary

.dark .list-group-item-action.active
  background: #333 !important

.badge
  padding: 5px 8px
  min-width: 40px
  font-size: 15px

.intervention-name
  flex: 1 0

.intervention-quantity
  width: 100px
  padding: 0 10px

  input
    text-align: right
    padding: 0

.intervention-price
  width: 100px
  text-align: right

.custom-checkbox
  *
    cursor: pointer !important

  label:before,
  label:after
    margin-top: -1px

.list-group-item
  font-size: 1.1em
  
.list-scroll
  max-height: 300px
  overflow-y: auto
</style>
