<template>
  <div

    class="row history d-flex flex-fill"
    :class="withIcon ? '': 'notification'"
    @click="clickable ? goToServerDetail() : ''"
  >
    <table>
      <tbody>
        <tr>
          <td
            v-if="withIcon"
            class="history-icon"
          >
            <i :class="getTheClass()"/>
          </td>
          <td
            class="history-message"
            :class="['history-' + event.status, {'history-alert-msg': alertMessage}]"
          >
            {{ getTheAction() }}
            <p
              v-if="!alertMessage"
              class="history-date"
            >
              {{ ctx.$filters.localDatetime(event.timestamp) }}
            </p>
          </td>
          <td
            v-if="!alertMessage"
            class="history-server"
          >
            <div
              v-if="event.target_name"
            >
              {{ event.target_name }}
            </div>
            <div
              v-if="event.server_name || event.user_email"
            >
              <div v-if="event.server_name && event.server_name.length">
                <b> {{ ctx.$t("event_log.requests.server") }} </b> :
                {{ event.server_name }}
              </div>
              <div v-if="event.user_email && event.user_email.length">
                <b> {{ ctx.$t("event_log.requests.user") }} </b> :
                {{ email }}
              </div>
            </div>
          </td>
          <td
            v-if="withIcon"
            class="text-center history-button"
          >
            <button
              v-if="event.fields && event.fields.length && (event.status === 'success' || event.status === 'alert')"
              id="btn-toggle"
              @click.prevent.stop="toggle = !toggle"
            >
              <i
                v-if="toggle"
                class="fas fa-minus-circle text-warning"
                style="font-size: larger"
              />
              <i
                v-if="!toggle"
                class="fas fa-plus-circle text-primary"
                style="font-size: larger"
              />
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <table v-if="withIcon && toggle && event.fields && event.fields.length && (event.status === 'success' || event.status === 'alert')">
      <tbody>
        <tr>
          <td style="min-width: 30px; width: 30px" />
          <td class="history-field">
            <table
              v-for="(field) in event.fields"
              :key="field.name"
              class="w-100"
            >
              <tr
                v-if="field.old_data"
                class="d-flex"
              >
                <td class="event-data-sm">
                  <strong>{{ field.name }}:</strong>
                </td>
                <td
                  class="event-data"
                >
                  {{ field.old_data }}
                </td>
                <td
                  style="width: 30px"
                >
                  <i class="fas fa-arrow-right"/>
                </td>
                <td
                  class="event-data"
                >
                  {{ field.new_data }}
                </td>
              </tr>
              <tr
                v-else
                class="d-flex"
              >
                <td class="event-data-sm">
                  <strong>{{ field.name }}:</strong>
                </td>
                <td
                  v-if="field.data"
                  class="event-data-lg"
                >
                  {{ field.data }}
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
// import Avatar from "@/components/shared/Avatar";

export default {
  // components: {Avatar},
  props: {
    withIcon: {
      type: Boolean,
      default: true
    },
    event: {
      type: Object,
      default: () => {
      },
    },
    clickable: {
      type: Boolean,
      default: false,
    },
    ctx: {type: Object, default: () => null},
    message: {
      type: String,
      default: "",
    }
  },
  data() {
    return {
      toggle: false,
      alertMessage: false,
    }
  },
  computed: {
    getTheStatus() {
      return (this.event.status === "success" || this.event.status === "error") ? this.ctx.$t("event_log.status." + this.event.status) : null;
    },
    email() {
      if (this.event.user_email.includes("@titandc.fr")) {
        return "Admin SmallCloud";
      }
      return this.event.user_email;
    },
  },
  methods: {
    goToServerDetail() {
      //  console.log(this.event)
      if (this.event.server_oid) {
        this.$router.push({
          name: "server-detail",
          params: {server_oid: this.event.server_oid},
        });
      }
    },
    getTheClass() {
      let theClass = "";
      switch (this.event.status) {
        case "request": {
          theClass = "fas fa-info-circle";
          break
        }
        case "alert": {
          theClass = "fas fa-triangle-exclamation";
          break
        }
        default: {
          switch (this.event.type) {
            case 'ip':
            case 'network':
              theClass = "fas fa-network-wired";
              break;
            case 'snapshot':
              theClass = "fas fa-camera";
              break;
            case 'server':
              theClass = "fas fa-server";
              if (this.event.sub_type && this.event.sub_type.length) {
                switch (this.event.sub_type) {
                  case 'iso':
                    theClass = "fas fa-compact-disc";
                    break;
                  case 'addons':
                    theClass = "fas fa-puzzle-piece";
                    break;
                  case 'network':
                    theClass = "fas fa-ethernet";
                    break;
                  case 'configuration':
                    theClass = "fas fa-wrench";
                    break;
                  case 'storage':
                    theClass = "fas fa-hdd";
                    break;
                  default:
                    theClass = "fas fa-server";
                    break;
                }
              }
              break;
            case 'console':
            case 'kvmip':
            case 'ipkvm':
              theClass = "fas fa-desktop";
              break;
            case 'ssh':
              theClass = "fas fa-key";
              break;
            case 'note':
              theClass = "fas fa-sticky-note";
              break;
            case 'authentication':
              theClass = "fas fa-key";
              break;
            case 'storage':
              theClass = "fas fa-hdd";
              break;
            case 'material':
              theClass = "fas fa-screwdriver";
              break;
            case 'payment_method':
              theClass = "fas fa-credit-card";
              break;
            default:
              theClass = "fas fa-info-circle";
          }
        }
      }
      theClass = theClass + " history-" + this.event.status;
      return theClass;
    },
    getTheAction() {
      let message = this.getTheMessage();
      if (message) {
        return message;
      }
      
      // Check if translations exist
      const typeExists = this.ctx.$te("event_log.type." + this.event.type.toLowerCase());
      const actionExists = this.event.action ? this.ctx.$te("event_log.action." + this.event.action.toLowerCase()) : false;
      const subTypeExists = this.event.sub_type ? this.ctx.$te("event_log.subtype." + this.event.sub_type.toLowerCase()) : false;
      
      // Get values if translations exist
      let type = typeExists ? this.ctx.$t("event_log.type." + this.event.type.toLowerCase()) : "";
      let action = actionExists ? " " + this.ctx.$t("event_log.action." + this.event.action.toLowerCase()) : "";
      let subType = subTypeExists ? " " + this.ctx.$t("event_log.subtype." + this.event.sub_type.toLowerCase()) : "";
      
      if (subType === "" && this.event.status === "cancel") {
        subType = this.ctx.$t("event_log.action.cancel");
      }
      let minus = (subType.length || action.length) ? " -" : "";
      return type + minus + subType + action;
    },
    getTheMessage() {
      if (this.message) {
        this.alertMessage = true;
        return this.message;
      }
      let type = this.event.type.toLowerCase();
      let action = this.event.action ? this.event.action.toLowerCase() : "";
      let subType = this.event.sub_type ? this.event.sub_type.toLowerCase() : "";
      if (subType === "" && this.event.status === "cancel") {
        subType = "cancel";
      }
      // if (action === "updated" &&
      //   this.event.fields &&
      //   this.event.fields[0] &&
      //   this.event.fields[0].name &&
      //   this.event.fields[0].name === "name") {
      //   action = "renamed"
      // }
      const messageExists = this.ctx.$te("event_log.messages." + type + (subType !== "" ? "_" + subType : "") + (action !== "" ? "_" + action : "")); 
      if (!messageExists) {
        return null;
      }
      return this.ctx.$t("event_log.messages." + type + (subType !== "" ? "_" + subType : "") + (action !== "" ? "_" + action : ""));
    }
  },
};
</script>

<style lang="sass" scoped>
.history-success
  color: #00dc77

.history-error
  color: #f8065b

.history-danger
  color: #f8065b

.history-request
  color: black

.history-migrate
  color: #00dc77

.history-warning
  color: #dc8f00

.history-alert
  color: #dc8f00

.history-info
  color: #4ae3cb

.history-alert-msg
  font-size: 1rem
  width: 350px

.event-data
  display: block
  overflow: hidden
  text-overflow: ellipsis
  white-space: nowrap
  width: 200px

  &-sm
    @extend .event-data
    width: 150px

  &-lg
    @extend .event-data
    width: 400px

.dark
  .history-request
    color: white

</style>
