import { createI18n } from 'vue-i18n'
import authService from "./services/auth.service";
import AuthService from "./services/auth.service";
import {nextTick} from "vue";
import axios from "axios";

export var UserLang;
AuthService.CurrentUser = authService.getAuthenticated()

export const i18n = createI18n({
  locale: /* process.env.VUE_APP_I18N_LOCALE || */ GrabLocale(),
  fallbackLocale: 'fr',
  messages: {
    en: require('./locales/en.js'),
    fr: require('./locales/fr.js'),
    de: require('./locales/de.js'),
    es: require('./locales/es.js'),
    it: require('./locales/it.js'),
  }
})

setI18nLanguage(GrabLocale())

// set userCurrent language
UserLang = GrabLocale();

export function GrabLocale() {
  if (AuthService.CurrentUser && AuthService.CurrentUser.preference && AuthService.CurrentUser.preference.preferred_language) {
    UserLang = AuthService.CurrentUser.preference.preferred_language
  } else if (navigator.language.split('-')[0]) {
    UserLang =  navigator.language.split('-')[0];
  } else {
    UserLang =  'en';
  }

  return  UserLang;
}

export function setI18nLanguage(locale) {
  if (i18n.mode === 'legacy') {
    i18n.global.locale = locale
  } else {
    i18n.global.locale.value = locale
  }
   
  axios.defaults.headers.common['Accept-Language'] = locale
  document.querySelector('html').setAttribute('lang', locale)
}


export async function loadLocaleMessages(locale = GrabLocale()) {

  // load locale messages with dynamic import
  const messages = await import(
    /* webpackChunkName: "locale-[request]" */ `./locales/${locale}.js`
    )

  // set locale and locale message
  i18n.global.setLocaleMessage(locale, messages.default)
  setI18nLanguage(locale)

  return nextTick()
}

/*await loadLocaleMessages(i18n, paramsLocale)
  }

  // set i18n language
  setI18nLanguage(i18n, paramsLocale)*/
//
// function loadLocaleMessages() {
//   const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.js$/i)
//   // console.log(locales);
//   const messages = {}
//   // console.log('locales', locales)
//   locales.keys().forEach(key => {
//     const matched = key.match(/([A-Za-z0-9-_]+)\./i)
//     // console.log('locale', key, matched)
//     if (matched && matched.length > 1) {
//       const locale = matched[1]
//       messages[locale] = locales(key)
//     }
//   })
//   // console.log(userLang)
//   return messages
// }
//


