export function textAreaFormatter(value) {
  let regex = /^\s*$(?:\r\n{2,}?|\n{2,})/gm
  return value.replace(regex, "\n");
}

export function removeChatGPTOnlyFirstLine(text) {
  // Regular expression with case-insensitivity
  let regex = /^ChatGPT\s*$/i;

  // Split the text into lines
  let lines = text.split('\n');

  // Check if the first line contains only "ChatGPT" and remove it if so
  if (regex.test(lines[0])) {
    lines[0] = '';
  }

  // Join the lines to get the modified text
  return lines.join('\n');
}
