
export function getSeverityBadgeClass(severity) {
  return "bg-" + getSeverityColor(severity);
}

function getSeverityColor(severity) {
  const colors = {
    1: "info",
    2: "warning",
    3: "danger",
  };
  return colors[severity] || "dark";
}
