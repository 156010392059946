<template>
  <div class="container">
    <div class="card">
      <div class="card-body">
        <h1 class="card-title">
          Privacy policy.
        </h1>

        <p>
          As part of its commercial activity, Titan Datacenters France collects
          and processes information from and about you. This information is
          collected by browsing website, filling our newsletter subscription
          form, clicking on an advertising link, sending us your information
          linked to your account and your identification to Titan Datacenters
          France services ("personal data").
        </p>

        <p>
          This Privacy Policy describes how we collect and process your personal
          data.
        </p>

        <h3><u>Important</u></h3>

        <p>
          We only use your personal data in the following cases established by
          the current regulations:
        </p>

        <ul>
          <li>
            The execution of a contract that we have concluded with you, and/or
          </li>
          <li>The compliance with a legal obligation, and/or</li>
          <li>Your consent to use your data, and/or</li>
          <li>
            The existence of a legitimate interest in using your data. A
            legitimate interest could be a set of commercial or business reasons
            that justify the use of your data by Titan Datacenters France.
          </li>
        </ul>

        <h4>Who is the data controller mentioned in this document?</h4>
        <!--h4>WHO IS THE DATA CONTROLLER MENTIONED IN THIS DOCUMENT?</h4-->

        <p>
          The data controller mentioned in this document is Titan Datacenters
          France, a simplified joint-stock company registered under French law
          with a capital of € 1,042,907.50, in the Cannes Trade and Companies
          Register under number B 789 683 190, and which headquarter is located
          at 45 allée des Ormes, 06250 Mougins, France.
        </p>

        <h4>
          What type of data is being collected from you and in what
          circumstances?
        </h4>
        <!--h4>WHAT TYPE OF DATA IS BEING COLLECTED FROM YOU AND IN WHAT CIRCUMSTANCES?</h4-->

        <p><b>The data you send us directly</b></p>

        <p>
          You may provide us with personal information when you interact with us
          in different ways. These data are collected for example when
          subscribing to our newsletter, creating an account on our websites or
          accessing any other offer or Titan Datacenters France service.
        </p>

        <p>
          This information may include your name, address, telephone number,
          email address, password and any other information that you wish to
          bring to our attention.
        </p>

        <p><b>The data we collect during our business relationship</b></p>

        <p>
          The data we collect during our business relationship include the
          following:
        </p>

        <ul>
          <li>Your participation in promotional offers, contests or events;</li>
          <li>
            The requests you have made to our community manager or the incidents
            that you have reported to us;
          </li>
        </ul>

        <p><b>The data we collect automatically</b></p>

        <p>
          When you visit our websites, we collect information related to your
          connection and your navigation. Different technologies can be used to
          collect these data. The main one is the cookie.
        </p>

        <ul>
          <li>
            <p><b>The data collected through cookies</b></p>

            <p>
              A cookie is a small text file that is placed on your computer or
              mobile device when you visit a site, that enables us to: (i)
              recognize your computer and login session; (ii) store your
              preferences and settings; (iii) understand which pages of the
              Websites you have visited; (iv), enhance your user experience by
              delivering and measuring the effectiveness of content and
              advertising tailored to your interests; (v) perform analytics; and
              (vi) assist with security and administrative functions.
            </p>

            <p>
              You will find more information on cookies on the following sites:
              www.cnil.fr, www.allaboutcookies.org, www.aboutcookies.org,
              www.youronlinechoices.eu.
            </p>

            <p>
              Cookies are managed by your Internet browser and only the issuer
              of a cookie can decide to read or modify the information contained
              in it.
            </p>
          </li>
          <li>
            <p><b>Data related to minors</b></p>

            <p>
              In principle, our products and services are aimed to adults who
              are able to sign a contract and therefore agree with its legal
              obligations.
            </p>

            <p>
              The minor user must obtain the consent of their legal guardian
              prior to the communication of their personal data.
            </p>

            <p>
              An internet user who is under the age of 18 when creating an
              online account, has the exceptional right to erase their data at
              any time. For any information, please contact the data protection
              officer (dpo@titandc.fr).
            </p>
          </li>
          <li>
            <p><b>Exclusion of any sensitive data</b></p>

            <p>
              Titan Datacenters France does not collect any sensitive data about
              you.
            </p>

            <p>
              The following are considered sensitive data: racial or ethnic
              origin, political opinions, religious or philosophical beliefs,
              membership of a union, data related to health or sexual
              orientation. If such information is in any way communicated to us,
              it will be deleted.
            </p>
          </li>
        </ul>

        <!--h4>FOR WHAT PURPOSES DO WE USE YOUR DATA?</h4-->
        <h4>For what purposes do we use your data?</h4>

        <p>
          This article tells you the main purposes for which we use your
          personal data.
        </p>

        <p><b>Operations necessary to provide products or services</b></p>

        <ul>
          <li>Management of newsletters and/or email alerts subscriptions;</li>
          <li>
            Customer relations: A community manager will be available by e-mail
            for any question or complaint;
          </li>
          <li>Completion of satisfaction surveys;</li>
          <li>
            Updating our customers with the lastest innovations or developments
            of our services;
          </li>
          <li>Management of the exercise of your personal data’s rights;</li>
          <li>
            Verification of compliance with existing legislation, our contracts
            and our Terms and Conditions.
          </li>
        </ul>

        <p>
          <b>Marketing and prospecting operations related to Titan Datacenters
            France publications, products and services</b>
        </p>

        <ul>
          <li>
            Updating postal standards, and updating, enriching and deduplicating
            your personal information in a dedicated database;
          </li>
          <li>
            Sending marketing, advertising and promotional offers related to our
            products and services by mail, email, social media, blog posts or
            any other medium;
          </li>
          <li>Customer loyalty program or prospecting actions;</li>
          <li>Contests or other promotional operations;</li>
          <li>Collection of customer reviews;</li>
          <li>Development of trade statistics.</li>
        </ul>

        <!--h4>FOR HOW LONG DO WE KEEP YOUR DATA?</h4-->
        <h4>For how long do we keep your data?</h4>

        <p>
          Your personal data is kept for a certain period of time in accordance
          with the regulations in force or proportional to the purposes for
          which it was kept. Some retention periods meet Titan Datacenters
          France’s legitimate interest as specified in the introduction.
        </p>

        <p>
          The retention periods may vary accordingly with an ongoing contractual
          relationship (you are an active client), a past contractual
          relationship with you (you are an inactive client) or a never existing
          relationship with you (then you are a prospect).
        </p>

        <!--h4>WHO IS LIKELY TO HAVE ACCESS TO THE DATA WE COLLECT?</h4-->
        <h4>Who is likely to have access to the data we collect?</h4>

        <p><b>Access to data within Titan Datacenters France</b></p>

        <p>
          Are likely to have access to some of your data, employees of
          administrative services, accounting and management control, IT,
          community management and marketing & sales.
        </p>

        <p><b>Data transmission</b></p>

        <p>Are also likely to have access to some of your data:</p>

        <ul>
          <li>
            <p><b>The subcontractors</b></p>
            <p>They provide services on our behalf.</p>
            <p>
              We may provide access to or share your information with
              subcontractors that use the information only to perform services
              on our behalf. These third parties are contractually obliged to
              respect confidentiality and data protection.
            </p>
          </li>
          <li>
            <p><b>Police, judicial or administrative authorities</b></p>
            <p>
              When we have the legal obligation to do so or to guarantee the
              rights, property and security of Titan Datacenters France.
            </p>
          </li>
        </ul>

        <!--h4>IS YOUR DATA TRANSFERRED OUTSIDE THE EUROPEAN UNION?</h4-->
        <h4>Is your data transferred outside the european union?</h4>

        <p>
          We keep your personal data within the European Union. In any case, we
          ensure that the data processing is made in accordance with this
          privacy policy and that it is regulated by the standard contractual
          clauses of the European Commission. Thus, it is possible to guarantee
          privacy protection and the respect of fundamental human rights.
        </p>

        <!--h4>HOW IS YOUR DATA PROTECTED?</h4-->
        <h4>How is your data protected?</h4>

        <p>
          As data controller, we implement appropriate technical and
          organizational measures in accordance with the standards in force, to
          protect your personal data against alteration, accidental or unlawful
          loss, use, disclosure or unauthorized access, especially:
        </p>

        <ul>
          <li>The nomination of a “Data Protection Officer”;</li>
          <li>
            Make employees aware about the confidentiality level of your data;
          </li>
          <li>Secure the access to our premises and our IT platforms;</li>
          <li>Implement a general IT security policy for the company;</li>
          <li>Secure the data access, sharing and transfer;</li>
          <li>
            The high level of data protection requirements when selecting our
            subcontractors and partners.
          </li>
        </ul>

        <!--h4>WHAT ARE YOUR RIGHTS?</h4-->
        <h4>What are your rights?</h4>

        <p><b>Your rights regarding the access to your personal data</b></p>

        <p>
          You have the right to access your personal data and to request their
          rectification, or update. You may, for legitimate reasons, request the
          deletion or oppose the use of your personal data.
        </p>

        <p>
          You can request to exercise your data portability right, i.e. the
          ability to shift your data from one data controller to another and not
          be tied to a particular system.
        </p>

        <p>
          Finally, you can formulate directives relating to the storage,
          deletion and communication of your personal data after your death.
        </p>

        <p>
          You can contact Titan Datancenters France’s Data Protection Officer to
          assert your rights by mail: 45 allée des Ormes 06250 Mougins FRANCE or
          by email: dpo@titandc.fr.
        </p>

        <p>
          Before responding to your request, we may verify your identity and/or
          ask you to provide us with more information. We make every effort to
          comply with your request in a timely manner and within the time limits
          set by law.
        </p>

        <p>
          In the case of an unsatisfactory response, you can address a complaint
          to the National Commission for Data Protection (CNIL).
        </p>

        <p><b>Your rights to oppose to sales operations</b></p>

        <p>
          We may use your contact details for commercial purposes, in accordance
          with the law in force and the clauses of this privacy policy. You can
          choose not to accept these solicitations at any time, by unsubscribing
          to the communication channel through which they are sent.
        </p>

        <ul>
          <li>
            <p><b>Electronic sales operations (emails):</b></p>

            <p>
              We respect the provisions of section L.34-5 which sets the rules
              for posts and electronic communications that requires the
              systematic prior consent for sending commercial offers by
              electronic means (emails, push notifications or text messages).
            </p>

            <p>
              However, your express and prior consent is not compulsory when you
              already are a customer of Titan Datacenters France and that the
              purpose of our solicitation is to offer you products or services
              similar to those we already provide you.
            </p>

            <p>
              In any case, you always have the possibility to unsubscribe to
              these electronic sales operations and opt out by clicking on the
              link provided in each email.
            </p>
          </li>
        </ul>

        <!--h4>MODIFICATION OF OUR PRIVACY POLICY</h4-->
        <h4>Modification of our privacy policy</h4>

        <p>
          We may occasionally modify this privacy policy. When necessary, we
          will notify you and/or seek your agreement. We advise you to regularly
          consult this page to be aware of any changes or updates that may
          impact our privacy policy.
        </p>

        <!--h4>CONTACT</h4-->
        <h4>Contact</h4>

        <p>
          For any question related to this privacy policy or for any request
          related to your personal data, you can contact us by:
        </p>

        <ul>
          <li>
            sending an email to our Data Protection Officer at dpo@titandc.fr
          </li>
          <li>
            <p>or by sending us a letter to the following address:</p>
            <p>
              <i>
                Titan Datacenters France<br>
                Attention: Data Protection Officer<br>
                45 allée des Ormes<br>
                06250 Mougins
              </i>
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
