<template>
  <!--v-model: to show/hide the modal-->
  <!--size: when one or more files are selected (filelist.length) expand the modal horizontally-->
  <b-modal
    id="add-attachments-modal"
    v-model="showModal"
    centered
    hide-footer
    hide-header-close
    :size="fileList.length ? 'lg' : ''"
    @close="close()"
  >
    <template #title>
      <div
        class="ms-0 d-inline-flex align-items-center"
      >
        <h4>{{ $t('tickets.attachments.sent.title') }}</h4>
      </div>
    </template>
    <b-form>
      <b-row class="mt-2 px-3">
        <!--the file list column has different width based on boreakpoint-->
        <!--and is shown only if one or more files are selected-->
        <b-col
          v-if="fileList.length"
          v-cloak
          sm="12"
          xl="8"
        >

          <!--show a list of the selected files, with an input to (optionally) rename the file before upload-->
          <b-list-group>
            <b-list-group-item
              v-for="(file, i) in fileList"
              :key="i"
              class="d-inline-flex p-2"
            >

              <!--file name input to rename the file before upload-->
              <b-form-input
                v-model="fileList[i].fileName"
                :placeholder="$t('tickets.attachments.sent.placeholder')"
                required
              />

              <!--file extension-->
              <span class="d-inline-flex align-items-center ms-1 me-3">.{{ fileList[i].extension }}</span>

              <!--remove file from list (and from upload)-->
              <b-button
                class="btn btn-remove text-danger"
                size="sm"
                @click="remove(fileList.indexOf(file))"
              >
                <i class="fas fa-trash"/>
              </b-button>

            </b-list-group-item>
          </b-list-group>

        </b-col>

        <!--this column contains the file drop area-->
        <!--its width is automatically set based on existance of the file list column and breakpoint-->
        <b-col class="file-drop-column my-4 my-xl-0 p-0">
          <div
            class="file-drop-area bg-light"
            @dragover.prevent="dragover"
            @drop.prevent="drop"
          >

            <!--we need an actual file input in order to have the drag&drop functionality-->
            <!--and the File object(s) containing the files dropped-->
            <!--We keep it invisible because we want to display something different than a file input-->
            <input
              id="file-input"
              type="file"
              :accept="extensionFilter"
              multiple
              name="file-input"
              class="file-input invisible"
              @change="onChange"
            />

            <!--clicking on this label will trigger the clicked event on the file input-->
            <!--this will cause the 'choose file' system dialog-->
            <label
              for="file-input"
              class="w-100 text-center cursor-pointer m-0 px-3 py-5"
            >
              <p>
                <i
                  class="fas fa-cloud-upload-alt"
                  style="font-size: xx-large"
                />
              </p>
              <div>
                <p class="mb-0">
                  {{ $t('tickets.attachments.sent.text[0]') }}
                </p>
                <p class="mb-0">
                  {{ $t('tickets.attachments.sent.text[1]') }}
                  <span
                    class="text-info"
                  >
                    {{ $t('tickets.attachments.sent.text[2]') }}
                  </span>
                </p>
              </div>
            </label>
          </div>
        </b-col>
      </b-row>

      <!--this div contains the two action buttons cancel and upload-->
      <!--It is totally possible to use the bootstrapvue footer buttons instead-->
      <div class="d-flex flex-fill justify-content-between mt-2">
        <button
          id="btn-cancel"
          class="btn-cancel-sm ms-2"
          @click="close"
        >
          {{ $t("actions.cancel") }}
        </button>
        <button
          id="btn-refund"
          class="btn-validate-sm ms-2"
          :disabled="notAccepted"
          @click="upload"
        >
          {{ $t("actions.confirm") }}
        </button>
      </div>
    </b-form>
    <Alert
      inline
      :show="message_error"
      danger
      @closed="message_error = null"
    >
      {{ message_error }}
    </Alert>
  </b-modal>

</template>

<script>
import BModal from "@/components/shared/BModal";
import Alert from "../shared/Alert";
import {cloneDeep} from "lodash-es";
import {settings} from "@/settings";

export default {
  name: "UploadFiles",
  components: {
    BModal,
    Alert
  },
  props: {},
  emits: ['close', 'upload'],
  data() {
    return {
      showModal: false,
      fileList: [],     //we use a custom array to store the files because we want the user to rename the file before upload
      oldList: [],
      notAccepted: false,
      message_error: null,
    }
  },
  computed: {
    extensionFilter() {
      return settings.checkContentType ? settings.allowedExtension : '';
    }
  },
  mounted() {
  },
  methods: {
    /*Every time a new file is selected (both by dropping or choosing from computer)
    * we push them inside our array. This way we keep the previously uploaded files
    * inside. A user can drop a file, then drop another one without losing the first one*/
    onChange(event) {
      const fileArray = [...event.target.files];  //put the files in a temporary array
      this.fillList(fileArray);
    },
    /* */
    fillList(fileArray) {
      fileArray.forEach((file) => {
        const fileName = file.name.replace(/\.[^/.]+$/, "");  //extract filename (no extension) from the file full name
        const extension = file.name.split('.').pop();         //extract extensionfrom the file fullname
        this.fileList.push({fileName, extension, file});      //store selected files and additional data in our array
      })
      this.fileList = this.removeDuplicatesByPropertyName(this.fileList)
      if (settings.checkContentType) this.checkFileType();
    },
    /*Remove the file from the list. It will not be uploaded*/
    remove(i) {
      this.fileList.splice(i, 1);
    },
    dragover(event) {
      event.preventDefault();
    },
    drop(event) {
      const fileArray = [...event.dataTransfer.files];  //put the files in a temporary array
      event.preventDefault();
      this.fillList(fileArray);
    },
    show() {
      this.oldList = cloneDeep(this.fileList);
      this.showModal = true;
    },
    hide() {
      this.showModal = false;
    },
    close() {
      this.fileList = cloneDeep(this.oldList);
      this.$emit('close', this.fileList);
    },
    upload() {
      this.$emit('upload', this.fileList);
    },
    checkFileType() {
      this.notAccepted = false;
      this.fileList.forEach((document) => {
        if (!settings.allowedContentType.includes(document.file.type))
          this.notAccepted = true;
      })
      if (this.notAccepted) {
        this.message_error = this.$t('tickets.attachments.content_type_error')
      }
    },
    removeDuplicatesByPropertyName(array) {
      const uniqueMap = new Map();

      array.forEach(obj => {
        if (!uniqueMap.has(obj.fileName)) {
          uniqueMap.set(obj.fileName, obj);
        }
      });

      const uniqueArray = Array.from(uniqueMap.values());
      return uniqueArray;
    },
  }
}
</script>

<style lang="scss">
div#add-attachments-modal {
  div.modal-dialog {
    transition: max-width 0.3s ease-out, transform 0.3s ease-out;
  }

  .col.file-drop-column {
    flex-shrink: 1;
  }

  div.file-drop-area {
    border: 2px dashed rgba(0, 0, 0, 0.25);

    input.file-input {
      position: absolute;
      height: 0;
    }
  }
}
</style>
