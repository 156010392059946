import {createApp, h} from "vue";
import VueAxios from 'vue-axios'
import App from './App.vue'
import axios from 'axios'
import LoadScript from 'vue-plugin-load-script'
import vSelect from 'vue-select'
import Sticky from 'vue-sticky-directive'
import VueCreditCardValidation from 'vue-credit-card-validation';
import Vue3Tour from 'vue3-tour'
import SimpleTypeahead from 'vue3-simple-typeahead';
import 'vue3-simple-typeahead/dist/vue3-simple-typeahead.css'; //Optional default CSS

import './registerServiceWorker'
import './services/utils/interceptor'

import './directives/focus'
import router from "./router";
import store from "./store/store";
import {i18n} from "./i18n";
import BootstrapVue3 from "bootstrap-vue-3";
import Avatar from "./components/shared/Avatar";
import './services/utils/numbers'

import {capitalize, lowercase, truncate, uppercase} from "./filters/strings";
import {localDate, localDatetime} from "./filters/date";
import {round, roundForPriceLocal} from "./filters/price";
import {osFormatter, replaceUnderscore, windowsOverride} from "./filters/os";
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'
import {EventSourcePolyfill, NativeEventSource} from 'event-source-polyfill';
import {formatStorageValue} from "@/filters/storage";
import '@vuepic/vue-datepicker/dist/main.css'
import VueGridLayout from 'vue-grid-layout'
import mitt from 'mitt'


// const EventSource = NativeEventSource || EventSourcePolyfill;
// OR: may also need to set as global property
global.EventSource = NativeEventSource || EventSourcePolyfill;

const app = createApp({
  directives: {Sticky},
  // router,
  render: () => h(App)
});

const emitter = mitt();

app.config.errorHandler = function (err, vm, info) {
  console.error(err, vm, info)
}

app.config.globalProperties.emitter = emitter;

app.config.performance = process.env.NODE_ENV !== 'production';

app.use(Vue3Tour)
app.use(SimpleTypeahead);

app.component('AvatarPerso', Avatar);
app.component('VSelect', vSelect)

// app.config.compilerOptions.isCustomElement = (tag) => tag.startsWith('avatar')
app.config.globalProperties.$filters = {
  capitalize: capitalize,
  lowercase: lowercase,
  uppercase: uppercase,
  truncate: truncate,
  localDate: localDate,
  localDatetime: localDatetime,
  roundForPriceLocal: roundForPriceLocal,
  round: round,
  osFormatter: osFormatter,
  replaceUnderscore: replaceUnderscore,
  windowsOverride: windowsOverride,
  formatStorageValue: formatStorageValue,
};

app.use(BootstrapVue3)
app.use(i18n)

app.use(store)
app.use(router)
app.use(VueAxios, axios);
app.use(LoadScript)
app.use(Sticky)
app.use(VueCreditCardValidation);
app.use(VueGridLayout);


app.mount("#app");
