<template>
  <!--      v-model="opened"-->
  <b-modal
    :id="id"
    v-model="opened"
    centered
    hide-header-close
    text-center
    size="md"
    @close=" reset(); hide(); close();"
    @ok="confirmationEntered()"
  >
    <ModalHeader/>
    <div class="modal-confirm">
      <h2 class="mb-3">
        <i
          v-if="danger"
          class="text-danger fas fa-radiation-alt"
        />
        <i
          v-if="warning"
          class="text-warning fas fa-exclamation-triangle"
        />
        <i
          v-if="success"
          class="text-success fas fa-check-circle"
        />
        <i
          v-if="info"
          class="text-info fas fa-info-circle"
        />

        {{ title }}
      </h2>
      <div
        v-if="danger"
        class="alert alert-danger"
      >
        {{ danger }}
      </div>

      <div
        v-if="warning"
        class="alert alert-warning"
      >
        {{ warning }}
      </div>

      <div
        v-if="success"
        class="alert alert-success"
      >
        {{ success }}
      </div>

      <div
        v-if="info"
        class="alert alert-info"
      >
        {{ info }}
      </div>

      <div class="my-4">
        <p>{{ message }}</p>
        <p v-if="extra">
          <strong>{{ extra }}</strong>
        </p>
      </div>

      <div v-if="message">
        <p>{{ $t("modal.confirm-text") }}</p>
        <div class="mb-3">
          <input
            v-model="confirmation"
            v-focus
            :class="{ danger: danger }"
            :placeholder="$t('modal.confirm-placeholder')"
            class="form-control input-dialog-confirm"
            type="text"
          >
        </div>
      </div>
    </div>

    <template #footer>
      <div class="modal-buttons mb-3">
        <button
          class="btn-dialog-cancel btn-cancel"
          @click="
            reset();
            hide();
            close();
          // cancel();
          "
        >
          {{ $t("actions.cancel") }}
        </button>
        <button
          :class="computeButtonClass()"
          :disabled="!isConfirmed"
          class="btn-dialog-confirm"
          @click="
            reset();
            confirm();
          "
        >
          {{ buttonValue }}
        </button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import ModalHeader from "./ModalHeader";
import BModal from "@/components/shared/BModal";

export default {
  directives: {
    "focus": {
      inserted: function (el) {
        // Focus the element
        el.focus()
      },
      update: function (el, binding) {
        var value = binding.value;
        if (value) {
          this.nextTick(function () {
            el.focus();
          });
        }
      }
    }
  },
  components: {BModal, ModalHeader},
  props: {
    id: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    message: {
      type: String,
      default: "",
    },
    extra: {
      type: String,
      default: "",
    },
    button: {
      type: String,
      default: "",
    },
    info: {
      type: String,
      default: "",
    },
    warning: {
      type: String,
      default: "",
    },
    danger: {
      type: String,
      default: "",
    },
    success: {
      type: String,
      default: "",
    },
    close: {
      type: Function,
      default: function () {
      },
    },
    confirm: {
      type: Function,
      default: function () {
      },
    },
  },
  data() {
    return {
      opened: false,
      confirmation: "",
      confirmationWord: this.$t("modal.confirmation-word"),
    };
  },
  computed: {
    buttonValue() {

      if (!this.button || this.button === "") {
        return this.$t("modal.confirm");
      }
      return this.button;
    },
    isConfirmed() {
      return (this.confirmation === this.confirmationWord && this.message.length) ||
        this.message.length === 0;
    },
  },
  methods: {
    // hideModal() {
    //   this.$emit("close");
    // },
    hide() {
      this.opened = false;
    },
    show() {
      this.opened = true;
    },
    reset() {
      this.confirmation = "";
    },
    computeButtonClass() {
      if (this.success) {
        return {"btn-validate": true};
      }

      if (this.danger) {
        return {"btn-validate": true, "btn-danger": true};
      }

      if (this.warning) {
        return {"btn-validate": true, "btn-warning": true};
      }

      if (this.info) {
        return {"btn-validate": true, "btn-info": true};
      }

      return {"btn-validate": true, "btn-warning": true};
    },
    confirmationEntered() {
      if (this.isConfirmed) {
        this.reset();
        this.confirm();
      }
    },
  },
};
</script>

<style lang="sass">
.danger
  &:focus
    border-color: #f47b86 !important
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25) !important
</style>
