import {isArray, isEmpty, isNull, isObject, isString} from "lodash-es";

export function getSearchParams(filters) {
  if (filters.dateRange && filters.dateRange.length > 0) {
    filters.starting_at = filters.dateRange[0].setHours(0, 0, 0, 0);
    if (filters.dateRange.length > 1) {
      filters.ending_at = filters.dateRange[1].setHours(23, 59, 59, 999);
    }
  }
  delete filters.dateRange
  return buildSearchParams(filters);
}

function buildSearchParams(filters) {
  let params = new URLSearchParams();
  for (let [key, value] of Object.entries(filters)) {
    //console.log(key, value,  isNull(value), (isEmpty(value) && (isObject(value) || isArray(value) || isString(value))))
    if (isNull(value) || (isEmpty(value) && (isObject(value) || isArray(value) || isString(value)))) {
      continue
    }

    if (value instanceof Date && value.getTime()) {
      params.append(key, value.getTime());
    } else if (Array.isArray(value)) {
      value.forEach(v => {// Check if array element is an object with "key" and "value" properties
        // eslint-disable-next-line no-prototype-builtins
        if (isObject(v) && v.hasOwnProperty('key') && v.hasOwnProperty('value')) {
          params.append(`${key}[${v.key}]`, v.value);
        } else {
          params.append(key + "[]", v);
        }
      });
    } else if (typeof value != "object") {
      params.append(key, value);
    }
  }

  return params;
}
