import router from "@/router";
import {createToast} from "mosha-vue-toastify";
import {i18n} from "@/i18n";

export function routerError(value) {
  // console.log("router error", value);
  createToast(
    i18n.global.t(value.response.statusText), {
      type: "warning",
      position: 'bottom-right',
      showIcon: true,
      timeout: 5000
    });
  setTimeout(() => {router.push("/")},5000);
}
