<template>
  <VueMarkdown
    v-bind="$props"
  />
</template>

<script>
import VueMarkdown from '@uivjs/vue-markdown-preview';
import '@uivjs/vue-markdown-preview/markdown.css';

export default {
  components: {
    VueMarkdown,
  },

  props: {
    ...VueMarkdown.props,
    emoji: {
      default: false,
      type: Boolean,
    },
    html: {
      default: false,
      type: Boolean,
    },
  },

  mounted() {
  },
};
</script>
