<template>
  <div
    v-if="notification"
    class="card"
    @click.prevent="goDetail(notification.servers[0].oid)"
  >
    <div class="card-body">
      <div class="notification mb-2">
        <div class="col-severity">
          <div
            :class="getSeverityBadgeClass(notification.severity)"
            class="badge"
          >
            {{ $t("severity." + notification.severity) }}
          </div>
        </div>
        <div class="col-title">
          {{ notification.title }}
        </div>
      </div>

      <div class="meta">
        <Dot :status="notification.servers[0].state" />
        <div class="col-name">
          {{ notification.servers[0].name }}
        </div>
        <div
          v-b-popover.hover.html="computeOsName(notification.servers[0].items.os.template)"
          class="col-template d-inline-flex align-items-center justify-content-end"
        >
          {{ notification.servers[0].items.os.template.os }}
          <OsLogo
            :name="notification.servers[0].items.os.template.os"
            style="width: 30px; height: 30px"
            class="ms-1"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {computeOsName} from "@/services/utils/server.utils";
import OsLogo from "@/components/shared/OsLogo";
import Dot from "@/components/shared/Dot";
import {getSeverityBadgeClass} from "@/services/utils/notifications";
import {vBPopover} from "bootstrap-vue-3";

export default {
  directives: {
    "b-popover": vBPopover
  },
  components: {Dot, OsLogo},
  props: {
    notification: {
      type: Object,
      default: () => ({})
    }
  },

  methods: {
    computeOsName,
    goDetail(oid) {
      this.$router.push("/servers/" + oid);
    },

    computeStatusName(status) {
      return this.$t("server.states." + status);
    },
    getSeverityBadgeClass,
  }
};
</script>

<style lang="sass" scoped>
.card
  cursor: pointer

.card-body
  padding: 10px

  .tag
    padding: 2px 5px
    font-size: 10px

.meta
  display: flex
  gap: 10px
  align-items: center
  font-size: 12px
  padding: 0 10px
  border-radius: 5px
  //background: #fafafa

  .col-name
    flex: 1 1

  .col-template
    flex: 0 0 20%
    text-align: right

.notification
  display: flex
  gap: 10px
  align-items: center

  .col-severity
    flex: 0 0 75px

  .badge
    padding: 5px 10px
    text-align: center
    min-width: 100%

.dark
  .meta
    background: black

  .tag
    color: white
</style>
