import {common} from '@/settings'
import axios from "axios";

const API_URL = common.api.site_specific_url
const API_URL_IP = API_URL + '/ip'
const API_URL_TOOLS = "https://apollo-213225239-108.titandc.io/api"

class miscellaneous {
  async getOldPrimary() {
    return await axios.get(API_URL_IP + '/old_primary')
  }

  async updatePrimary(ip) {
    return await axios.put(API_URL_IP + '/update_primary/' + ip)
  }

  async ping(ip){
    return await axios.get(API_URL_TOOLS + "/ping?ip_address=" + ip )
  }
  async traceroute(ip){
    return await axios.get(API_URL_TOOLS + "/traceroute?ip_address=" + ip )
  }
  async nmap(ip){
    return await axios.get(API_URL_TOOLS + "/nmap?ip_address=" + ip )
  }
  async abuse(ip){
    return await axios.get(API_URL_TOOLS + "/abuse?ip_address=" + ip )
  }
  async scanAll(ip){
    return await axios.get(API_URL_TOOLS + "/scan_all?ip_address=" + ip )
  }
  async goAbuse(searchParams = undefined){
    return await axios.get(API_URL_IP + "/abuse" + (searchParams ? '?' + searchParams.toString() : ''))
  }

  deleteIp(ip, payload = {}) {
    return axios.delete(API_URL_IP + "/" + ip + '/terminate', {data: payload})
  }

  cancelIpDeletion(ip_oid) {
    return axios.patch(API_URL_IP + '/' + ip_oid)
  }
}

export default new miscellaneous()
