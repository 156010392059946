import {common} from '@/settings'
import axios from "axios";

const API_URL = common.api.url + '/notification'

class NotificationService {
  listNotifications(searchParams = undefined) {
    return axios.get(API_URL + (searchParams ? '?' + searchParams.toString() : ''))
  }

  createNotification(payload) {
    return axios.post(API_URL + '/', payload)
  }

  updateNotification(id, payload) {
    return axios.put(API_URL + '/' + id, payload)
  }

  deleteNotification(id, payload = null) {
    return axios.delete(API_URL + '/' + id, {data: payload})
  }

  removeServerFromNotification(id, payload = null) {
    return axios.delete(API_URL + '/' + id + '/target', {data: payload})
  }

  listDiffusions() {
    return axios.get(API_URL + '/diffusions')
  }

  async clearNotification(uuid) {
    return await axios.put(API_URL + '/' + uuid, {
      clear_notification: true
    })
  }

}

export default new NotificationService()
