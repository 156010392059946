export const themeProps = {
  primary: Boolean,
  secondary: Boolean,
  tertiary: Boolean,
  danger: Boolean,
  success: Boolean,
  info: Boolean,
  warning: Boolean,
  dark: Boolean,
  light: Boolean,
  link: Boolean
}

export function computeThemeClass(type, props) {
  const classes = {}

  for (let [key, value] of Object.entries(props)) {
    classes[type + '-' +  key] = value
  }

  return classes
}

export function computeClass(props) {
  let type = "default"
  
  for (let [key, value] of Object.entries(props)) {
    if (key !== "inline") {
      if (value === true) type = key;
    }
  }

  return type;
}
