import userService from "@/services/user.service";
import {tryCatch} from "@/components/shared/util";
import {settings} from "@/settings";
import {isDefaultCompanyAdmin, isDefaultCompanyManager, isTitanAdmin} from "@/services/utils/company";

export async function fixItemsPerPage() {
  let result = await tryCatch(() =>
    userService.getPreferences()
  );
  if (result.isSuccess && result.response.sorting_preferences.default) {
    // console.log("user limit", result.response.sorting_preferences.default.limit)
    return result.response.sorting_preferences.default.limit;
  }
  // console.log("default limit", settings.defaultItemsPerPage)
  return settings.defaultItemsPerPage;
}

export function isAuthorizedTo(user, value) {
  if (settings.apiTools.includes(value.toLowerCase())) {
    if (isTitanAdmin(user) || isDefaultCompanyAdmin(user) || isDefaultCompanyManager(user)) {
      return true;
    }
  }
  return false;
}

export function getSortFields(user, filters, type) {
  if (user.preference &&
    user.preference.sorting_preferences &&
    user.preference.sorting_preferences[type]) {
    filters.sort_field = user.preference.sorting_preferences[type].sort_field;
    filters.sort = user.preference.sorting_preferences[type].sort;
    filters.limit = user.preference.sorting_preferences[type].limit;
  }
  return filters;
}

export async function setSortFields(user, value, type) {
  user.preference.sorting_preferences[type] =
    {sort_field: value.sort_field, sort: value.sort, limit: value.limit};
  await userService.updateUserPreferences(
    user.preference
  );
}
