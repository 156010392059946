<template>
  <div>
    <div class="scope-row">
      <input
        :id="id + '-input'"
        ref="input"
        v-model.trim="search"
        :disabled="disabled"
        :placeholder="placeholder"
        class="form-control"
        type="search"
        @blur="close()"
        @click="open()"
        @focus="open()"
        @keydown="onInput($event)"
      >
      <div
        v-if="opened"
        class="scope-list"
      >
        <div class="list-group">
          <a
            v-for="(option, i) in filteredOptions"
            :id="id + '-list-index-' + option[i]"
            :key="option[i]"
            :class="{ active: i === hovered }"
            class="list-group-item list-group-item-action"
            href="#"
            @mouseover="onHover(i)"
            @click.prevent="onSelect(option)"
          >
            <span v-html="boldify(option[label])" />
          </a>
        </div>
      </div>

      <ul
        v-if="selected.length"
        class="scope-selected list-group mt-2"
      >
        <li
          v-for="item in selected"
          :key="item[index]"
          class="list-group-item"
        >
          <button
            v-if="!disabled"
            :id="id + '-btn-remove-' + item[index]"
            class="btn-link"
            @click="onRemove(item[index])"
          >
            <i class="scope-remove fas fa-times" />
          </button>
          {{ item[label] }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Array,
      default() {
        return [];
      },
    },
    options: {
      type: Array,
      default() {
        return [];
      },
    },
    placeholder: {
      type: String,
      default: "",
      value: "",
    },
    index: {
      type: String,
      default: "",
      value: "oid",
    },
    id: {
      type: String,
      default: "",
      value: "select-multi",
    },
    label: {
      type: String,
      default: "",
      value: "name",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits:['update:modelValue','change'],
  data() {
    return {
      hovered: 0,
      opened: false,
      search: "",
      selected: this.modelValue || [],
    };
  },

  computed: {
    filteredOptions() {
      const search = this.search.toLowerCase();
      const selectedIds = [...this.selected.map((item) => item[this.index])];

      return this.options
        .filter((item) => {
          return !selectedIds.includes(item[this.index]);
        })
        .filter((item) => {
          return item[this.label].toLowerCase().includes(search);
        });
    },
  },

  watch: {
    modelValue: {
      handler(value) {
        this.selected = value;
      },
      immediate: true,
    },

    selected() {
      this.$emit("update:modelValue", this.selected);
      this.$emit("change", this.selected);
    },
  },

  methods: {
    open() {
      this.hovered = 0;

      if (this.filteredOptions.length === 0) {
        return;
      }

      this.opened = true;
    },

    close() {
      setTimeout(() => {
        this.opened = false;
      }, 250);
    },

    boldify(name) {
      return (name || "").replace(
        new RegExp(this.search, "g"),
        (found) => "<b>" + found + "</b>"
      );
    },

    onSelect(item) {
      this.search = "";
      this.selected.push(item);
      this.$refs.input.focus();
      this.hovered = 0;
      this.opened = false;
    },

    onRemove(item) {
      this.selected = this.selected.filter((items) => {
        return items[this.index] !== item;
      });
    },

    onHover(index) {
      this.hovered = index;
    },

    onInput(e) {
      const selected = this.filteredOptions[this.hovered];

      if (e.key !== "Enter" && e.key !== "Escape") {
        this.opened = true;
      }

      switch (e.key) {
        case "Escape":
          this.close();
          break;

        case "ArrowDown":
          if (this.hovered < this.filteredOptions.length - 1) {
            this.hovered++;
          } else {
            this.hovered = 0;
          }
          break;

        case "ArrowUp":
          if (this.hovered > 0) {
            this.hovered--;
          } else {
            this.hovered = this.filteredOptions.length - 1;
          }
          break;

        case "Enter":
          if (selected && this.opened === true) {
            this.onSelect(this.filteredOptions[this.hovered]);
            this.close();
          }
          break;
      }
    },
  },
};
</script>

<style lang="sass" scoped>

.is-invalid input
  border-color: #f8075b

.scope-row
  position: relative

.scope-list
  position: absolute
  width: 100%
  top: 2.75em
  z-index: 10
  max-height: 250px
  overflow: hidden
  overflow-y: auto
  width: 100%
  box-shadow: 0 5px 10px rgba(black, .1)
  border: 1px solid #ccc
  background: #fff

  .active
    margin: 0

.scope-remove
  cursor: pointer

.scope-selected
  width: 100%
  border: 1px solid #ddd

  li
    display: flex
    align-items: center

  i
    margin-right: 10px

.dark
  .scope-list
    background: #333
    border: 1px solid #444
    box-shadow: 0 5px 10px rgba(black, 1)

  .scope-selected
    border: 1px solid #444
</style>
