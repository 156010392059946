<template>
  <SelectMulti
    :id="id"
    v-model="selected"
    :options="servers"
    :placeholder="'- ' + $t('actions.search-servers') + ' -'"
    :disabled="disabled"
    index="oid"
    label="name"
  />
</template>

<script>
import { mapState } from "vuex";
import SelectMulti from "./SelectMulti";
import serverService from "@/services/server.service";
import {tryCatch} from "./util";

export default {
  components: {
    SelectMulti,
  },

  props: {
    value: {
      type: Array,
      default() {
        return [];
      },
    },
    id: {
      type: String,
      default: "",
      value: "select-multi-servers",
    },
    selectedCompany: {
      type: String,
      default: "",
      value: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
emits: ["update:modelValue","change"],
  data() {
    return {
      selected: this.modelValue || [],
      servers: [],
    };
  },
  computed: {
    ...mapState("auth", ["user"]),
  },
  watch: {
    modelValue: {
      handler(value) {
        this.selected = value;
      },
      immediate: true,
    },

    selected() {
      this.$emit("update:modelValue", this.selected);
      this.$emit("change", this.selected);
    },
  },
  async mounted() {
    let company = this.selectedCompany ? this.selectedCompany : this.user.company_oid;
    let result = await tryCatch(() => serverService.list(company));
    const servers = result.response;
    this.servers = servers.filter(
      (server) => server.state !== "deleted" && server.state !== "unpaid"
    );
  },
};
</script>

<style lang="sass">
.dark
  .list-group-item.active
    color: black !important
</style>
