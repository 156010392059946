<template>
  <div>
    <input
      :id="id"
      v-model="inputValue"
      :class="computeClass"
      :maxlength="maxLength"
      :minlength="minLength"
      :max="max"
      :min="min"
      :spellcheck="false"
      :type="type"
      :style="computeStyle"
      :step="step"
      :placeholder="placeHolder"
      :disabled="disabled"
      @input="checkInput()"
    >
  </div>
</template>
<script>
import {checkInputMinMax} from "@/services/utils/validator";
// import EditInPlace from "@/components/shared/EditInPlace.vue";

export default {
  // components: {EditInPlace},
  props: {
    modelValue: {
      type: [String, Number],
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    customClass: {
      type: String,
      default: "",
    },
    class: {
      type: String,
      default: ""
    },
    id: {
      type: String,
      default: "input-limited",
    },
    minLength: {
      type: [String, Number],
      default: null,
    },
    maxLength: {
      type: [String, Number],
      default: null,
    },
    min: {
      type: [String, Number],
      default: null,
    },
    max: {
      type: [String, Number],
      default: null,
    },
    style: {
      type: Object,
      default: null,
    },
    step: {
      type: [String, Number],
      default: 1,
    },
    placeHolder: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    onlyValidChar: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      inputValue: this.modelValue,
      returnedValue: {value: "", valid: true}
    };
  },
  computed: {
    computeClass() {
      return this.customClass + ' ' + this.class;
    },
    computeStyle() {
      let addStyle = {'border-color': 'red'}
      if (this.style && this.style !== '') {
        return Object.assign(this.style, (this.returnedValue.valid ? null : addStyle));
      }
      return this.returnedValue.valid ? null : ' border-color: red';
    }
  },

  mounted() {
    this.checkInput();
  },
  
  methods: {
  
    checkInput() {
      this.returnedValue = checkInputMinMax(this.type, this.inputValue, this.min, this.max, this.minLength, this.maxLength);
      this.inputValue = this.returnedValue.value;
      if (this.onlyValidChar) {
        this.inputValue = this.returnedValue.value.replace(/[^a-zA-Z0-9-.]/g, '');
      }
      this.$emit("update:modelValue", this.inputValue);
    },
  },
};
</script>

<style lang="sass" scoped>
.form-control-mini
  padding: 0
  font-size: inherit
  border: none
</style>

<style>
/* Chrome, Safari, Edge, Opera */
.no-step::-webkit-inner-spin-button, .no-step::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

.no-step {
  -moz-appearance:textfield !important;
}
</style>
