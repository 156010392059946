<template>
  <teleport to="body">
    <Transition :name="transition">
      <div
        v-if="show || modelValue"
        :id="id"
        class="wrapper"
        :class="computeWrapperClass"
        @click="close()"
      >
        <div
          class="box"
          :class="computeBoxClass"
          @click.stop=""
        >
          <div
            v-if="!hideHeaderClose"
            class="header-close"
          >
            <button
              class="btn btn-sm header-close"
              @click="close()"
            >
              <i class="fas fa-times" />
            </button>
          </div>
          <div
            v-if="!hideHeader"
            class="modal-header"
          >
            <slot name="header" />
            <div class="modal-title">
              <slot name="title" />
            </div>
          </div>
          <div
            :class="computeBodyClass"
          >
            <slot />
          </div>
          <div
            v-if="!hideFooter"
            class="modal-footer"
          >
            <slot name="footer">
              <div class="mb-3 d-flex flex-fill justify-content-between">
                <button
                  class="btn-cancel"
                  @click="close()"
                >
                  {{ $t("actions.cancel") }}
                </button>
                <button
                  class="btn-validate"
                  @click="ok()"
                >
                  {{ $t("actions.validate") }}
                </button>
              </div>
            </slot>
          </div>
        </div>
      </div>
    </Transition>
  </teleport>
</template>

<script>
export default {
  name: "BModal",
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: ""
    },
    class: {
      type: String,
      default: ""
    },
    show: Boolean,
    centered: Boolean,
    hideHeaderClose: Boolean,
    noCloseOnBackdrop: Boolean,
    noCloseOnEsc: Boolean,
    textCenter: Boolean,
    hideHeader: Boolean,
    hideFooter: Boolean,
    scrollable: Boolean,
    bodyHeight: {
      type: String,
      default: "md"
    },
    size: {
      type: String,
      default: "md"
    },
    transition: {
      type: String,
      default: "dialog"
    }
  },
  emits: ["close", "cancel", "hide", "hidden", "ok"],
  computed: {
    theSize() {
      if (["sm", "md", "lg", "xl"].includes(this.size))
        return this.size;
      return "md";
    },
    theHeight() {
      if (["sm", "md", "lg", "xl", "ticket"].includes(this.bodyHeight))
        return this.bodyHeight;
      return "md";
    },
    computeWrapperClass() {
      return ' ' + this.class;
    },
    computeBoxClass() {
      return this.theSize + (this.centered ? ' centered' : '');
    },
    computeBodyClass() {
      return 'modal-body' + ' height-' + this.theHeight + (this.scrollable ? ' scrollable' : '');
    }
  },
  mounted() {
    document.addEventListener('keyup', this.onKeyUp);
  },
  beforeUnmount() {
    document.removeEventListener('keyup', this.onKeyUp);
  },
  methods: {
    close() {
      this.$emit('cancel')
      this.$emit('close')
      this.$emit('hide')
      this.$emit('hidden')
    },
    ok() {
      this.$emit('ok')
    },
    onKeyUp(event) {
      // Check if Escape key have been pressed
      if (event.key === 'Escape') {
        this.close();
      }
      // Check if Enter key have been pressed
      // if (event.key === 'Enter') {
      //   this.ok();
      // }
    },
  }
}
</script>

<style scoped lang="scss">

// Animation
$dialog-t-duration: 550ms;
$dialog-t-delay: 100ms;
.dialog-enter-active,
.dialog-leave-active {
  transition-property: opacity;
  transition-duration: $dialog-t-duration;
  transition-timing-function: ease-in-out;

  .box {
    transition-property: transform;
    transition-duration: $dialog-t-duration - $dialog-t-delay;
    transition-delay: $dialog-t-delay;
    transition-timing-function: ease-in-out;
  }
}

.dialog-enter-from,
.dialog-leave-to {
  opacity: 0;

  .box {
    transform: translate3d(-50%, -50%, 0) scale(0);
  }
}

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.66);
  z-index: 1060;
  width: 100vw;
  height: 100vh;
}

.dark .wrapper {
  background-color: rgba(0, 0, 0, 0.45);
}

.box {
  position: absolute;
  background-color: white;
  border-radius: 10px;
  transform: translate3d(-50%, -50%, 0);
  min-height: auto;
  height: auto;
  overflow-y: auto;
  overflow-x: hidden;
}

.center-text {
  text-align: center;
}

.sm {
  min-width: 300px;
  max-width: 499px;
}

.md {
  min-width: 500px;
  max-width: 799px;
}

.lg {
  min-width: 800px;
  max-width: 1139px;
}

.xl {
  min-width: 1140px;
}

.height-md {
  max-height: 90vh;
}

.height-lg {
  min-height: 78vh;
}

.height-xl {
  min-height: 85vh;
}

.height-ticket {
  min-height: 60vh;
}

.centered {
  top: 50%;
  left: 50%;
}

.scrollable {
  max-height: 50vh;
  overflow: auto;
}

.header-close {
  text-align: end;
}

.header-close:active,
.header-close:focus {
  border: none;
}

.dark .box {
  background-color: #6d6d6d !important;
}

.dark .modal-header {
  background: none;
}

.dark .modal-body {
  background: none;
}

.dark .modal-footer {
  background: none;
}

.dark .header-close {
  color: white;
}

</style>
