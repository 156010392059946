<template>
  <div />
</template>

<script>
import { computeClass, themeProps } from "./theme";
import {createToast, withProps } from "mosha-vue-toastify";
import eventItem from "@/components/all/servers/EventItem";

export default {
  props: {
    ...themeProps,
    inline: {
      type: Boolean,
      default: false
    },
    show: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: ""
    },
    delay: {
      type: Number,
      default: 0,
    },
  },
  emits:['closed'],
  data() {
    return {
      inViewport: true,
      observer: null,
      props: Object.assign({}, this.$props),
    };
  },

  watch: {
    show() {
      if (this.show && this.show.length) {
        let data = { status: computeClass(this.props), type: "alert"}
        createToast(
          withProps(eventItem, {event: data, ctx: this, withIcon: false, message: this.show}),
          {
            type: computeClass(this.props),
            position: 'bottom-right',
            showIcon: false,
            timeout: this.delay,
            transition: 'slide',
          });
        this.close();
      }
    },
  },

  mounted() {
    if (this.type) {
      this.props[this.type] = true;
    }
  },

  unmounted() {
  },

  methods: {
    close() {
      this.$emit("closed");
    },
  },
};
</script>

<style lang="sass">
.sticky-alert
  height: 45px

.alert p:last-child
  margin: 0
</style>
