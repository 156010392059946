<template>
  <div
    v-if="text"
    class="loading"
    style="color: #8ba2a2;  font-size: 20px;"
  >
    {{ text }}
  </div>
  <div
    :class="{ 'loading-space': space, 'loading-inline': inline }"
    class="loading"
  >
    <svg
      :style="{ width: size }"
      class="loading-spinner"
      viewBox="0 0 66 66"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        class="path"
        cx="33"
        cy="33"
        fill="none"
        r="30"
        stroke-linecap="round"
        stroke-width="6"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    inline: {
      type: Boolean,
      default: false
    },
    space: {
      type: Boolean,
      default: true
    },
    size: {
      type: String,
      default: null
    },
    text: {
      type: String,
      default: null
    },
  }
};
</script>
