import {common} from '@/settings';
import axios from "axios";

const API_URL = common.api.url + '/avatar'

class AvatarService {
  get(id) {
      return axios.get(API_URL +'/'+ id)
  }
}

export default new AvatarService()
