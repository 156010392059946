import {common} from '@/settings'
import axios from "axios";

const API_URL = common.api.url + '/ticket'
const API_URL_TRASH = common.api.url + '/ticket/trash'

// New
const WAITING_STATES = [
  "pending"
]
// Pending
const PENDING_STATES = [
  "in_progress",
  "assigned",
]
// Closed
const CLOSED_STATES = [
  "closed",
]

class TicketService {

  getPendingTickets() {
    return axios.get(API_URL + '/pending')
  }

  list(searchParams = undefined) {
    return axios.get(API_URL + (searchParams ? '?' + searchParams.toString() : ''))
  }

  detail(ticket_oid) {
    return axios.get(API_URL + "/" + ticket_oid)
  }

  getTicket(ticket_oid) {
    return axios.get(API_URL + "/" + ticket_oid + "/ticket")
  }

  setTicketRead(ticket_oid) {
    return axios.put(API_URL + "/" + ticket_oid + "/ticket_read")
  }

  readUnread(ticket_oid) {
    return axios.put(API_URL + "/" + ticket_oid + "/read_unread")
  }

  getAttachment(attachmentNumber, payload) {
    return axios.get(API_URL + "/attachment/"+attachmentNumber, {responseType: 'blob', params: payload})
  }

  sendMessage(ticket_oid, payload) {
    return axios.post(API_URL + "/" + ticket_oid + '/message', payload)
  }

  lockTicket(ticket_oid) {
    return axios.put(API_URL + "/" + ticket_oid + '/lock', {})
  }

  unlockTicket(ticket_oid) {
    return axios.put(API_URL + "/" + ticket_oid + '/unlock')
  }

  closeTicket(ticket_oid, payload) {
    return axios.put(API_URL + "/" + ticket_oid + '/close', payload)
  }

  updateTicketLvl(oid, level) {
    return axios.put(API_URL + "/" + oid + '/level', {
      new_level: level
    })
  }

  create(payload) {
    //console.log(payload)
    return axios.post(API_URL, payload)
  }

  createForAClient(payload) {
    return axios.post(API_URL + "?company_oid=" + payload.company, payload)
  }

  getStates() {
    return axios.get(API_URL + '/state')
  }

  startIndexer(payload) {
    return axios.post(API_URL + '/start_indexer', payload)
  }

  getGlobalStatusFromStatus(status) {
    // New / waiting
    if (WAITING_STATES.includes(status)) {
      return 'new'
    }
    // Pending
    if (PENDING_STATES.includes(status)) {
      return 'pending'
    }
    // Closed
    if (CLOSED_STATES.includes(status)) {
      return 'closed'
    }
    // We should not go there (unhandled status)
    console.err("UNHANDLED TICKET STATUS", status)
    return status
  }

  getAllStatusesMatchingGlobalStatus(status) {
    // New / waiting
    if (status === 'new') {
      return WAITING_STATES
    }
    // Pending
    if (status === 'pending') {
      return PENDING_STATES
    }
    // Closed
    if (status === 'closed') {
      return CLOSED_STATES
    }
    // We should not go there (unhandled status)
    console.err("UNHANDLED TICKET STATUS", status)
    return [status]
  }

  listTrash(searchParams = undefined) {
    return axios.get(API_URL_TRASH + (searchParams ? '?' + searchParams.toString() : ''))
  }

  getTicketTrash(ticket_oid) {
    return axios.get(API_URL_TRASH + "/" + ticket_oid)
  }

  deleteTrash(ticket_oid) {
    return axios.delete(API_URL_TRASH + "/" + ticket_oid)
  }

  deleteTrashMany(ticketList) {
    return axios.delete(API_URL_TRASH + "/many", {data: {ticket_trash_oid: ticketList} })
  }
}

export default new TicketService()
