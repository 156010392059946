<template>
  <div class="logo-os-container">
    <svg
      v-if="os.isDebian()"
      class="logo-os logo-os-debian"
    >
      <use
        v-if="active"
        xlink:href="../../assets/img/os/logos.svg#logo-os-debian-on"
      />
      <use
        v-else
        xlink:href="../../assets/img/os/logos.svg#logo-os-debian-off"
      />
    </svg>
    <svg
      v-else-if="os.isPlesk()"
      class="logo-os logo-os-plesk"
    >
      <use
        v-if="active && colorMode === 'DARK'"
        xlink:href="../../assets/img/os/logos.svg#logo-os-plesk-on-dark"
      />
      <use
        v-else-if="active && colorMode === 'LIGHT'"
        xlink:href="../../assets/img/os/logos.svg#logo-os-plesk-on"
      />
      <use
        v-else
        xlink:href="../../assets/img/os/logos.svg#logo-os-plesk-off"
      />
    </svg>
    <svg
      v-else-if="os.isCentos()"
      class="logo-os logo-os-centos"
    >
      <use
        v-if="active"
        xlink:href="../../assets/img/os/logos.svg#logo-os-centos-on"
      />
      <use
        v-else
        xlink:href="../../assets/img/os/logos.svg#logo-os-centos-off"
      />
    </svg>
    <svg
      v-else-if="os.isRedhat()"
      class="logo-os logo-os-readhat"
    >
      <use
        v-if="active"
        xlink:href="../../assets/img/os/logos.svg#logo-os-redhat-on"
      />
      <use
        v-else
        xlink:href="../../assets/img/os/logos.svg#logo-os-redhat-off"
      />
    </svg>
    <svg
      v-else-if="os.isUbuntu()"
      class="logo-os logo-os-ubuntu"
    >
      <use
        v-if="active"
        xlink:href="../../assets/img/os/logos.svg#logo-os-ubuntu-on"
      />
      <use
        v-else
        xlink:href="../../assets/img/os/logos.svg#logo-os-ubuntu-off"
      />
    </svg>
    <svg
      v-else-if="os.isWindows()"
      class="logo-os logo-os-windows"
    >
      <use
        v-if="active"
        xlink:href="../../assets/img/os/logos.svg#logo-os-windows-on"
      />
      <use
        v-else
        xlink:href="../../assets/img/os/logos.svg#logo-os-windows-off"
      />
    </svg>
    <svg
      v-else-if="os.isPfsense()"
      class="logo-os logo-os-pfsense"
    >
      <use
        v-if="active"
        xlink:href="../../assets/img/os/logos.svg#logo-os-pfsense-on"
      />
      <use
        v-else
        xlink:href="../../assets/img/os/logos.svg#logo-os-pfsense-off"
      />
    </svg>
    <svg
      v-else-if="os.is3cx()"
      class="logo-os logo-os-3cx"
    >
      <use
        v-if="active"
        xlink:href="../../assets/img/os/logos.svg#logo-os-3cx-on"
      />
      <use
        v-else
        xlink:href="../../assets/img/os/logos.svg#logo-os-3cx-off"
      />
    </svg>
    <svg
      v-else-if="os.isFedora()"
      class="logo-os logo-os-fedora"
    >
      <use
        v-if="active"
        xlink:href="../../assets/img/os/logos.svg#logo-os-fedora-on"
      />
      <use
        v-else
        xlink:href="../../assets/img/os/logos.svg#logo-os-fedora-off"
      />
    </svg>
    <svg
      v-else-if="os.isTrueNas()"
      class="logo-os logo-os-truenas"
    >
      <use
        v-if="active"
        xlink:href="../../assets/img/os/logos.svg#logo-os-truenas-on"
      />
      <use
        v-else
        xlink:href="../../assets/img/os/logos.svg#logo-os-truenas-off"
      />
    </svg>
    <svg
      v-else-if="os.isESX()"
      class="logo-os logo-os-esx"
    >
      <use
        v-if="active"
        xlink:href="../../assets/img/os/logos.svg#logo-os-esx-on"
      />
      <use
        v-else
        xlink:href="../../assets/img/os/logos.svg#logo-os-esx-off"
      />
    </svg>
    <svg
      v-else-if="os.isFreePBX()"
      class="logo-os logo-os-freepbx"
    >
      <use
        v-if="active"
        xlink:href="../../assets/img/os/logos.svg#logo-os-freepbx-on"
      />
      <use
        v-else
        xlink:href="../../assets/img/os/logos.svg#logo-os-freepbx-off"
      />
    </svg>
    <svg
      v-else-if="os.isRocky()"
      class="logo-os logo-os-rocky"
    >
      <use
        v-if="active"
        xlink:href="../../assets/img/os/logos.svg#logo-os-rocky-on"
      />
      <use
        v-else
        xlink:href="../../assets/img/os/logos.svg#logo-os-rocky-off"
      />
    </svg>
    <svg
      v-else-if="os.isOpnsense()"
      class="logo-os logo-os-opnsense"
    >
      <use
        v-if="active"
        xlink:href="../../assets/img/os/logos.svg#logo-os-opnsense-on"
      />
      <use
        v-else
        xlink:href="../../assets/img/os/logos.svg#logo-os-opnsense-off"
      />
    </svg>
    <svg
      v-else-if="os.isYeastar()"
      class="logo-os logo-os-yeastar"
    >
      <use
        v-if="active"
        xlink:href="../../assets/img/os/logos.svg#logo-os-yeastar-on"
      />
      <use
        v-else
        xlink:href="../../assets/img/os/logos.svg#logo-os-yeastar-off"
      />
    </svg>
    <svg
      v-else-if="os.isIspConfig()"
      class="logo-os logo-os-ispconfig"
    >
      <use
        v-if="active"
        xlink:href="../../assets/img/os/logos.svg#logo-os-ispconfig-on"
      />
      <use
        v-else
        xlink:href="../../assets/img/os/logos.svg#logo-os-ispconfig-off"
      />
    </svg>
    <svg
      v-else-if="os.isSophos()"
      class="logo-os logo-os-sophos"
    >
      <use
        v-if="active"
        xlink:href="../../assets/img/os/logos.svg#logo-os-sophos-on"
      />
      <use
        v-else
        xlink:href="../../assets/img/os/logos.svg#logo-os-sophos-off"
      />
    </svg>
    <svg
      v-else-if="os.isPacketFence()"
      class="logo-os logo-os-packetfence"
    >
      <use
        v-if="active"
        xlink:href="../../assets/img/os/logos.svg#logo-os-packetfence-on"
      />
      <use
        v-else
        xlink:href="../../assets/img/os/logos.svg#logo-os-packetfence-off"
      />
    </svg>
    <svg
      v-else-if="os.isClearOS()"
      class="logo-os logo-os-clearos"
    >
      <use
        v-if="active"
        xlink:href="../../assets/img/os/logos.svg#logo-os-clearos-on"
      />
      <use
        v-else
        xlink:href="../../assets/img/os/logos.svg#logo-os-clearos-off"
      />
    </svg>
    <svg
      v-else-if="os.isPBXware()"
      class="logo-os logo-os-pbxware"
    >
      <use
        v-if="active"
        xlink:href="../../assets/img/os/logos.svg#logo-os-pbxware-on"
      />
      <use
        v-else
        xlink:href="../../assets/img/os/logos.svg#logo-os-pbxware-off"
      />
    </svg>
    <svg
      v-else-if="os.isCloudLinux()"
      class="logo-os logo-os-cloudlinux"
    >
      <use
        v-if="active"
        xlink:href="../../assets/img/os/logos.svg#logo-os-cloudlinux-on"
      />
      <use
        v-else
        xlink:href="../../assets/img/os/logos.svg#logo-os-cloudlinux-off"
      />
    </svg>

    <div
      v-else-if="os.isImage()"
      class="logo-os logo-os-img justify-content-center"
    >
      <i
        v-if="active"
        class="fas fa-compact-disc fa-7x"
        style="color: #00dc77"
      />
      <i
        v-else
        class="fas fa-compact-disc fa-7x"
        style="color: #a7b9b9"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import OsInfos from "../../services/utils/OsInfos";

export default {
  props: {
    name: { type: String, default: "" },
    active: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      os: new OsInfos(this.name),
    };
  },

  computed: {
    ...mapState("auth", ["user"]),
    colorMode() {
      return this.user.preference.color_mode.toUpperCase();
    },
  },
};
</script>

<style lang="sass" scoped>
svg
  width: 100%
  height: auto

.logo-os
  max-width: 100px
  height: 100%

.logo-os-img
  height: 150px
  width: auto
  align-items: center
  display: inline-flex

  img
    width: 100%
    padding-left: 13px
    height: auto
</style>
