import {tryCatch} from "@/components/shared/util";
import authService from "@/services/auth.service";
import store from "@/store/store";
import {isNumber} from "lodash-es";

export function emailValidate(email) {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)
}

export async function hasSignedCGV(user, cgv) {
  if(!user)
    return false;
  
  if (user.automation) return true;
  
  if (cgv == null || !cgv.oid) {
    let rCGV = await tryCatch(() => authService.getCurrentCGV());
    cgv = rCGV.response;
    if (cgv === null) cgv = {
      oid: 0,
    } 
    store.state.auth.currentCGV = cgv
  }

  return cgv !== null && user.latest_signed_cgv && user.latest_signed_cgv.oid === cgv.oid
}

export function checkInputMinMax(type, value, min, max, minLength, maxLength)  {
  let returnedValue = {value: "", valid: true}
  if (type.toLowerCase() === "number") {
    returnedValue = checkInputNumber(value, min, max);
  } else {
    returnedValue = checkInputText(value, minLength, maxLength);
  }
  return returnedValue;
}

function checkInputNumber(value, min, max) {
  //console.log("value", value, "min", min, "max", max, "check", value > max)
  let valid = true;
  if (isNumber(value)) {
    max = max ? max.toString().replace(",",".") : value + 1;
    min = min ? min.toLocaleString().replace(",",".") : min;
    if (value) {
      if (value > max) {
        value = max;
      }
      if (value < min) {
        value = min;
        valid = false;
      }
    }
  } else {
    valid = min === null;
  }
  return {value : value, valid: valid};
}

function checkInputText(value, minLength, maxLength) {
  let valid = true;
  if (value) {
    if (maxLength && value.length > maxLength) {
      value = value.substr(1, maxLength)
    }
    if (minLength && value.length < minLength) {
      valid = false;
    }
  }
  return {value : value, valid: valid};
}

