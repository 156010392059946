<template>
  <div class="container-fluid">
    <div class="row align-items-center">
      <div class="col-lg-8 layout-fullheight">
        <div class="login">
          <div class="login-header">
            <img
              alt="logo"
              class="login-logo"
              src="../../assets/img/logo.svg"
            >

            <h1>{{ $t("pages.login2fa.title") }}</h1>

            <Alert
              :show="message_success"
              success
            >
              {{ message_success }}
            </Alert>
            <Alert
              :delay="5000"
              :show="message_error"
              danger
              @closed="message_error = null"
            >
              {{ message_error }}
            </Alert>
          </div>

          <form @submit.prevent="onSubmit">
            <div class="mb-3 form-tfa form-required">
              <label>{{ $t("pages.login2fa.tfa-label") }}</label>
              <div>
                <input
                  ref="code"
                  v-model.trim="code"
                  class="form-control"
                  maxlength="6"
                  placeholder="••••••"
                  required
                  type="text"
                >
              </div>
            </div>

            <div>
              <button class="btn-validate d-block w-100 btn-shadow">
                <span v-if="processing">
                  <i class="fas fa-spinner fa-spin" />
                </span>
                <span v-if="!processing">
                  {{ $t("pages.login.login-action") }}
                </span>
              </button>
            </div>

            <div class="text-center">
              <div class="my-3">
                <router-link :to="'/login'">
                  {{ $t("actions.back") }}
                </router-link>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="d-none d-lg-block col-lg-4 login-bar" />
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import Alert from "../../components/shared/Alert";
//import { translateError, tryCatch } from "../../components/shared/util";
import {tryCatch} from "../../components/shared/util";
//import authService from "../../services/auth.service";

export default {
  name: "Login2fa",
  components: {
    Alert,
  },
  data() {
    return {
      message_error: null,
      message_success: null,
      code: "",
      processing: false,
    };
  },
  computed: {
    ...mapState("auth", ["user"]),
  },
  watch: {
    code() {
      this.code.length === 6 && this.onSubmit();
    },
  },
  mounted() {
    this.$refs.code.focus();
  },
  methods: {
    ...mapActions("auth", ["login"]),
    async onSubmit() {
      this.processing = true;
      let result = await tryCatch(() => this.login({code: this.code}));
      this.message_error = result.errorMsg;

      if (this.$route.query.redirect) {
        return this.$router.push(this.$route.query.redirect);
      } else {
        this.$router.push("/dashboard");
      }
      this.processing = false;
    },
  },
};
</script>

<style lang="sass" scoped>
@import '../../sass/vendors/bootstrap.vars'
</style>
