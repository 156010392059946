import {tryCatch} from "@/components/shared/util";
import paramService from "@/services/param.service";

export function computeStatusColor(status) {
  switch (status) {
    case "closed":
      return "counter-dark";

    case "in_progress":
      return "counter-info";

    case "processing":
      return "counter-success";

    case "assigned":
      return "counter-warning";
      
    case "pending":
      return "counter-success";

    case "pending_client":
      return "counter-success";

    case "new_pending_client":
      return "counter-info";
      
    case "pending_admin":
      return "counter-warning";

    case "new_pending_admin":
      return "counter-success";

    case "new":
      return "counter-warning";
  }
}

export function computeStatusColorClient(status) {
  switch (status) {
    case "closed":
      return "counter-dark";

    case "pending_client":
      return "counter-warning";

    case "pending_admin":
      return "counter-success";

  }
}

const StatesMapping = {
  "waiting": ["unpaid", "suspended", "disabled"],
  "pending": [
    "suspending",
    "starting",
    "stopping",
    "resuming",
    "upgrading",
    "disabling",
    "enabling",
    "creating",
    "deleting",
    "reseting",
    "restoring",
    "rebooting",
    "creating",
    "updating"
  ],
  "started": ["started"],
  "stopped": ["stopped"],
  "deleted": ["deleted"],
}

export function FilterStatetoFullState(states) {
  let newState = []
  for (const state in states) {
    newState = newState.concat(StatesMapping[states[state]])
  }
  return newState
}

export function computeLevelColor(status) {
  switch (status) {
    case "low":
      return "";
    case "medium":
      return "text-info";
    case "high":
      return "text-danger";
  }
}

export function fileUpload(fileList) {
  // console.log("fileList", fileList)
  let attachments = [];
  if (fileList.length > 0) {
    fileList.forEach((document) => {
      let reader = new FileReader()
      reader.readAsArrayBuffer(document.file)
      reader.onloadend = async () => {
        
        let attachment = {
          file_name: document.file.name,
          content_type: document.file.type,
          body: [].slice.call(new Uint8Array(reader.result))
          }
        attachments.push(attachment);
        }
      })
  } else {
    attachments = [];
  }
  return attachments
}

export async function getLastUpdate() {
  let result = await tryCatch(() =>
    paramService.get("ticket_update_control")
  );
  if (result.isSuccess && result.response) {
    return result.response.num_value
  }
  return null
}

export function notRead(ticket, admin) {
  let value = "";
  if (admin) {
    if (!ticket.read_by_admin) {
      value = "not-read";
    }
  } else {
    if (!ticket.read_by_company) {
      value = "not-read";
    }
  }
  return value;
}

export function getReadStatus(ticket, admin) {
  if (admin) {
    return ticket.read_by_admin;
  } else {
    return ticket.read_by_company;
  }
}

export function initSortFields(admin) {
  return admin ?
    [ {"key": "state_date", "value": -1}, {"key": "read_by_admin", "value": 1} ] :
    [ {"key": "state_date", "value": -1}, {"key": "read_by_company", "value": 1} ];
}

export function initSortFieldsDashboard(admin) {
  return admin ?
    [ {"key": "read_by_admin", "value": 1}, {"key": "state_date", "value": -1} ] :
    [ {"key": "read_by_company", "value": 1}, {"key": "state_date", "value": -1} ];
}

export function getTicketWinElement() {
  const elements = document.querySelectorAll('[id^="vuewinbox-"]');
  let foundElement = null;
  if (elements) {
    elements.forEach((element) => {
      const ticketBoxElement = element.querySelector('[id^="ticketBox"]');
      if (ticketBoxElement) {
        foundElement = element;
      }
    });
  }
  if (foundElement) {
    return foundElement.getElementsByClassName("wb-header")[0].lastElementChild.textContent;
  }
  return null;
}

export const priorities = ["low", "medium", "high"]
export const categories = ["technical", "billing", "sales"]
