import {capitalize} from "vue";

function replaceUnderscore(value) {
  if (!value) return ''
  return value.toLowerCase().replaceAll('_', ' ');
}

function windowsOverride(value) {
  if (!value) return ''
  return value.replaceAll("trial", "iso trial");
}

function osFormatter(os, version) {
  return capitalize(os) + " " + capitalize(replaceUnderscore(version))
}

export {replaceUnderscore, windowsOverride, osFormatter}
