<template>
  <div
    :id="customId"
    class="overflow-hidden h-100 w-100 d-flex flex-column"
  >
    <div
      v-if="resizable"
      class="d-inline-flex p-2 justify-content-between"
      style="background-color: rgb(40, 40, 40)"
    >
      <button
        id="kvm-ctr-alt-suppr"
        class="btn btn-light"
        @click="sendCtrlAltDel"
      >
        <i class="me-2 fas fa-keyboard" />
        {{ $t("server.ctrl-alt-del") }}
      </button>

      <button
        id="kvm-go-to-server-detail"
        class="btn btn-primary"
        @click="goToServerDetail"
      >
        {{ $t("server.server-page") }}
        <i class="ms-2 fas fa-server" />
      </button>
    </div>
    <div
      v-if="resizable"
      v-show="connected"
      id="view"
      ref="panel"
      class="full-window overflow-hidden flex-fill"
      style="overflow: auto"
    />
    <div
      v-else
      v-show="connected"
      id="view"
      style="overflow: auto"
    />
  </div>
</template>

<script>
import RFB from "@novnc/novnc/core/rfb";
import router from "../../router";

export default {
  props: {
    serverOid: {
      type: String,
      default: "",
    },
    customId: {
      type: String,
      default: "kvm",
    },
    websockify: {
      type: String,
      default: "",
    },
    resizable: {
      type: Boolean,
      default: false,
    },
    // password: String
  },
  emits:["rfbconnect"],
  data() {
    return {
      rfb: null,
      connected: false,
    };
  },
  mounted() {
    this.connect();
  },
  beforeUnmount() {
    //window.removeEventListener("resize", this.resizeKvmWindow);
    this.disconnect();
  },
  methods: {
    goToServerDetail() {
      router.push({
        name: "server-detail",
        params: { server_oid: this.serverOid },
      });
    },
    resize() {
      this.rfb.scaleViewport = true;
    },
    connect() {
      if (this.resizable) this.rfb = new RFB(this.$refs.panel, this.websockify);
      else this.rfb = new RFB(this.$el.lastChild, this.websockify);

      if (this.resizable) {
        this.rfb.scaleViewport = true;
        this.rfb.clipViewport = true;
      }
      this.rfb.addEventListener("connect", () => {
        // console.log('RFB connect', arguments)
        this.$emit("rfbconnect");
        this.connected = true;
      });

      this.rfb.addEventListener("disconnect", () => {
        // console.log('RFB disconnect', arguments)
        this.connected = false;
      });

      this.rfb.addEventListener("credentialsrequired", () => {
        this.rfb.sendCredentials({ password: "titan" });
        // console.log('RFB credentialsrequired', arguments)
      });
      this.rfb.addEventListener("desktopname", () =>
        console.log("RFB desktopname", arguments)
      );
      this.rfb.addEventListener("securityfailure", () =>
        console.log("RFB securityfailure", arguments)
      );
    },
    disconnect() {
      this.rfb.disconnect();
    },
    sendCtrlAltDel() {
      this.rfb.sendCtrlAltDel();
    },
  },
};
</script>
<style lang="scss" scoped>
#terminal-kvm {
  display: flex;
  div:nth-child(1) {
    background: transparent !important;
    display: block !important;
    overflow: hidden !important;
  }
}
</style>
