import axios from 'axios';
import Router from '../../router';
import authService from '../auth.service';
import {common} from '@/settings'
// console.log('setting', settings)
// const CancelToken = new axios.CancelToken(cancel => cancel('cancel beceause of inactivity '))
// const source = CancelToken.source();

let refreshing = false;

axios.interceptors.request.use(config => {
  // console.log('config.url', config.url)
  /*console.log("has focus: " , document.hasFocus())
  if(!document.hasFocus()){
    throw new axios.Cancel('WAITING_UNFOCUS');
  }*/

  const token = authService.getToken()
  if (token && (config.url.includes(common.api.url) || config.url.includes(common.api.site_specific_url) || config.url.includes("8081"))) {
    config.headers['Authorization'] = 'Bearer ' + token
  }
  return config
})

axios.interceptors.response.use(response => {
  if (response.config.url.includes('/avatar') && (response.config.method === "GET" || response.config.method === "get"))
    return response.data

  if (response.headers &&
    response.headers['total-object']) {
    response.totalItems = response.headers['total-object']
  }
  
  // If CGV latest CGV have not been signed go to CGV sign page.
  if (response.headers ) {
      const cgvSigned = response.headers['x-cgv-signed']
    if (cgvSigned === "false" && !response.config.url.includes('cgv')) {
      // console.log(response.config.url)
      Router.push({ name: 'cgv' })
    }
  }
  
  return response
}, async (error) => {
  // console.error('error axios response', error)

  if (error.response) {
    if (error.response.config) {
      if (!error.response.config.url.includes('/auth/login') &&
        !error.response.config.url.includes('/auth/2fa') &&
        !error.response.config.url.includes('/auth/refresh') &&
        error.response.status === 401 &&
        // error.response.data.message === "Token"
        !refreshing
      ) {
        console.log(error.response)
        console.log("need to refresh")

        refreshing = true
        let response = await authService.refreshToken()

        if (response && response.token) {
          refreshing = false;

          return axios.request(error.config)
        }

        // authService.logout();
        // authService.clearLocalStorage()
        // refreshing = false;

      } else if (//!error.response.config.url.includes('/auth/refresh') && !error.response.config.url.includes('/auth/login') &&
        error.response.status === 401 &&
        refreshing) {
        refreshing = false
        authService.logout()
      }
    }
    // if (error.response.status === 404) {
    //   Router.push('/404');
    // }

  }
  return Promise.reject(error)
});
