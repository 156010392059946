import {UserLang} from "@/i18n";

//| date
function localDate(value) {
  if (!value) return '-'

  const date = new Date(value);
  const options = { year: "numeric", month: 'numeric', day: 'numeric' };

  return date.toLocaleDateString(UserLang, options)
}
function localDatetime(value) {
  if (!value) return '-'
  
  const date = new Date(value);
  return date.toLocaleString(UserLang)
}

export {localDate, localDatetime}
