import {tryCatch} from "@/components/shared/util";
import itemService from "@/services/item.service";
import {settings} from "@/settings";
import {getSearchParams} from "@/services/utils/searchBuilder";

export async function creditPackExists() {
  let packExists = false;
  let result = await getPackList(settings.type_of_subscription.credit);
  if (result.isSuccess && result.response && result.response.length > 0) {
    packExists = true;
  }
  return packExists;
}

export async function getPackList(type) {
  return await tryCatch(() => itemService.listPacks(type));
}

export async function getItem(itemType, itemSubType = null) {
  let result = await tryCatch(() =>
    itemService.getItemList(getSearchParams(
      {
        type: itemType,
        enabled: true,
        sub_type: itemSubType
      }
    ))
  );
  if (result.isSuccess) {
    return result.response[0];
  }
  return null;
}

export async function getItems(itemType, itemSubType = null) {
  let result = await tryCatch(() =>
    itemService.getItemList(getSearchParams(
      {
        type: itemType,
        enabled: true,
        sub_type: itemSubType
      }
    ))
  );
  if (result.isSuccess) {
    return result.response;
  }
  return null;
}
