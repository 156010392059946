function capitalize(value) {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
}

function lowercase(value) {
  if (!value) return ''
  return value.toLowerCase();
}

function uppercase(value) {
  if (!value) return ''
  return value.toUpperCase();
}

function truncate(value, limit = 25) {
  if (value.length > limit) {
    value = value.substring(0, limit - 1) + '…';
  }
  return value
}

function translateSeverity(value) {
  switch (value) {
    case 0:
      return "success"
    case 1:
      return "info"
    case 2:
      return "warning"
    case 3:
      return "danger"
  }
  return value
}

export {capitalize, lowercase, uppercase, truncate, translateSeverity}
