<template>
  <div>
    <b-modal
      id="adminInvoiceDetails"
      v-model="companyCreationOpened"
      hide-header
      centered
      size="md"
      @close="close()"
    >
      <div
        class="container-fluid"
      >
        <div class="mt-3 mb-3">
          <h2>{{ $t("pages.company.title-info") }}</h2>
        </div>
        
        <div class="mb-3 panel-modal-input">
          <label class="titleInput">
            {{ $t("pages.account.name") }}
            <span class="text-danger">*</span>
          </label>
          <input
            v-model.trim="tempCompany.company_name"
            class="form-control"
            type="text"
          >
        </div>

        <div class="mb-3 panel-modal-input">
          <label
            class="titleInput"
          >
            {{ $t("pages.account.vat") }}
          </label>
          <input
            v-model.trim="tempCompany.company_tva_number"
            class="form-control"
            type="text"
          >
        </div>

        <div class="mb-3 panel-modal-input">
          <label class="titleInput">
            {{ $t("pages.account.phone") }}
            <span class="text-danger">*</span>
          </label>
          <phone-input
            id="input-company-phone"
            ref="inputPhone"
            v-model="tempCompany.company_phone"
            :required="true"
          />
        </div>

        <div class="mb-3 panel-modal-input">
          <label class="titleInput">
            {{ $t("pages.account.address-street") }}
            <span class="text-danger">*</span>
          </label>
          <input
            v-model.trim="tempCompany.company_address_street"
            class="form-control form-required"
            type="text"
          >
        </div>

        <div class="mb-3 panel-modal-input">
          <label class="titleInput">
            {{ $t("pages.account.address-street") }} 2
          </label>
          <input
            v-model.trim="tempCompany.company_address_street2"
            class="form-control form-required"
            type="text"
          >
        </div>

        <div class="mb-3 panel-modal-input">
          <label class="titleInput">
            {{ $t("pages.account.address-postal-code") }}
            <span class="text-danger">*</span>
          </label>
          <input
            v-model.trim="tempCompany.company_address_postal_code"
            class="form-control form-required"
            type="text"
          >
        </div>

        <div class="mb-3 panel-modal-input">
          <label class="titleInput">
            {{ $t("pages.account.address-city") }}
            <span class="text-danger">*</span>
          </label>
          <input
            v-model.trim="tempCompany.company_address_city"
            class="form-control form-required"
            type="text"
          >
        </div>

        <div class="mb-3 panel-modal-input">
          <label class="titleInput">
            {{ $t("pages.account.address-country") }}
            <span class="text-danger">*</span>
          </label>
          <b-form-select
            id="select-company-billing-country"
            v-model.trim="tempCompany.company_address_country_code"
            class="form-select-sm"
          >
            <b-form-select-option
              v-for="(country, iso) in countries"
              :key="iso"
              :value="iso"
            >
              {{ country }}
            </b-form-select-option>
          </b-form-select>
        </div>
        <div>
          <span class="text-danger">*</span>
          {{ $t('form.required-field') }}
        </div>
      </div>
      <template #footer>
        <div class="modal-buttons">
          <button
            id="btn-close-modal"
            class="btn-cancel-sm mt-3 mb-3"
            @click="close()"
          >
            {{ $t("actions.cancel") }}
          </button>

          <button
            id="btn-confirm-modal"
            class="btn-validate-sm mt-3 mb-3"
            :disabled="!canConfirm"
            @click="confirmCreation()"
          >
            {{ $t("modal.confirm") }}
          </button>
        </div>
      </template>
    </b-modal>
    
    <Alert
      :delay="5000"
      :show="message_company_success"
      success
      @closed="message_company_success = null"
    >
      {{ message_company_success }}
    </Alert>
    <Alert
      :delay="5000"
      :show="message_company_error"
      danger
      @closed="message_company_error = null"
    >
      {{ message_company_error }}
    </Alert>
  </div>
</template>

<script>
import {mapState} from "vuex";
import Alert from "../../shared/Alert";
import PhoneInput from "../../shared/PhoneInput";
import countries from "i18n-iso-countries";
import userService from "@/services/user.service";
import {tryCatch} from "@/components/shared/util";

countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
export default {
  name: "CompanyCreation",
  components: {
    PhoneInput,
    Alert,
  },
  emits: ["close"],
  data() {
    return {
      companyCreationOpened: false,
      countries: [],
      message_company_success: null,
      message_company_error: null,
      tempCompany: this.newCompany(),
    };
  },
  computed: {
    ...mapState("auth", ["user"]),
    canConfirm() {
      return this.tempCompany.company_name !== "" &&
        this.tempCompany.company_phone !== "" &&
        this.tempCompany.company_address_street !== "" &&
        this.tempCompany.company_address_postal_code !== "" &&
        this.tempCompany.company_address_city !== "" &&
        this.tempCompany.company_address_country_code !== ""
    }
  },
  mounted() {
    let countriesList = countries.getNames(this.$root.$i18n.locale);
    let sortable = [];
    for (let isoCode in countriesList) {
      sortable.push([isoCode, countriesList[isoCode]]);
    }

    sortable.sort(function (a, b) {
      if (a[1] < b[1]) {
        return -1;
      }
      if (a[1] > b[1]) {
        return 1;
      }
      return 0;
    });

    let objSorted = {}
    sortable.forEach(function (item) {
      objSorted[item[0]] = item[1]
    })

    this.countries = objSorted;
  },
  methods: {
    newCompany() {
      return {
        email: "",
        company_phone: "",
        company_name: "",
        company_address_street: "",
        company_address_street2: "",
        company_address_postal_code: "",
        company_address_city: "",
        company_address_country: "France",
        company_address_country_code: "FR",
        company_tva_number: "",
      };
    }, 
    show() {
      this.tempCompany = this.newCompany();
      this.$refs.inputPhone.initPhone();
      this.companyCreationOpened = true;
    },
    close() {
      this.companyCreationOpened = false;
    },
    async confirmCreation() {
      this.tempCompany.email = this.user.email;
      this.tempCompany.company_address_country = this.countries[this.tempCompany.company_address_country_code];
      let result = await tryCatch(() => userService.addNewCompanyToUser(this.tempCompany));
      if (result.isSuccess) {
        this.message_company_success = this.$t("errors.COMPANY_CREATE_SUCCESS");
        this.$emit('close');
      } else {
        if (result.errorMsg) {
          this.message_company_error = result.errorMsg;
        } else {
          this.message_company_error = this.$t("errors.USER_CREATE_FAIL_COMPANY");
        }
      }
    },
  },
};
</script>

<style lang="sass" scoped>
</style>
