import {common} from '@/settings'
import axios from "axios";

const API_URL = common.api.url + '/version'

class VersionService {
  get() {
    return axios.get(API_URL + "/current")
  }

  getAppVersion() {
    return axios.get('/version.json?' + Math.round(Math.random() * 10000))
  }
}

export default new VersionService()
